<template>
  <div class="dashboard-state d-flex" :class="`dashboard-state__width-${width}`">
    <div
      ref="dashboardState"
      :class="
      `dashboard-state-item__${currentState.color}
      dashboard-state-item__${currentState.color}--background`"
      class="d-flex align-center dashboard-state-item"
    >
      <v-icon
        :class="`dashboard-state-item__${currentState.color}`"
        class="dashboard-state-item__icon align-self-start"
        small
      >
        {{currentState.icon}}
      </v-icon>

      <span> {{ currentState.title }} </span>
    </div>
  </div>
</template>

<script>
import { i18n } from "@/plugins/i18n";

export default {
  name: "DocumentState",
  props: {
    stateType: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: "fit",
    },
  },
  computed: {
    states() {
      return {
        draft: {
          icon: 'mdi-alert',
          color: "grey",
          title: i18n.t('docs.drafts'),
        },
        'review': {
          icon: 'mdi-comment',
          color: "blue",
          title: i18n.t('review.pending_generic'),
        },
        'review.me': {
          icon: 'mdi-comment-account',
          color: "blue",
          title: i18n.t('review.pending_me'),
        },
        pending: {
          icon: 'mdi-fountain-pen-tip',
          color: "blue",
          title: i18n.t("docs.pending.generic"),
        },
        "pending.approve": {
          icon: 'mdi-account-clock',
          color: "blue",
          title: i18n.t("docs.pending.approve"),
        },
        "pending.me": {
          icon: 'mdi-fountain-pen-tip',
          color: "blue",
          title: i18n.t("docs.pending.me"),
        },
        "pending.expiring": {
          icon: 'mdi-clock-alert',
          color: "yellow",
          title: i18n.t("docs.pending.expiring"),
        },
        rejected: {
          icon: 'mdi-close-box',
          color: "red",
          title: i18n.t("docs.rejected"),
        },
        expired: {
          icon: 'mdi-calendar-remove',
          color: "red",
          title: i18n.t("docs.expired"),
        },
        completed: {
          icon: 'mdi-check-circle',
          color: "green",
          title: i18n.t("docs.completed"),
        },
        "completed.expiring": {
          icon: 'mdi-clipboard-text-clock',
          color: "yellow",
          title: i18n.t("docs.completed.expiring"),
        },
        "done.approve": {
          icon: 'mdi-check-circle',
          color: "green",
          title: i18n.t("general.approved"),
        },
        "done.sign": {
          icon: 'mdi-check-circle',
          color: "green",
          title: i18n.t("signing.signed"),
        },
      };
    },
    currentState() {
      return this.states[this.stateType];
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.emitCssColor();
    })
  },
  methods: {
    emitCssColor() {
      const currentElement = this.$refs.dashboardState;
      const style = getComputedStyle(currentElement);
      const color = style.getPropertyValue('color');
      this.$emit('stateColor', color);
    }
  }
};
</script>

<style lang="scss" scoped>
.dashboard-state__width {
  &-fit {
    width: fit-content;
  }
  &-full {
    width: 100%;
  }
}

.dashboard-state {
  padding-bottom: 10px;
  font-size: 0.75rem;
  line-height: 15px;
  font-weight: bold;
  text-transform: uppercase;

  .dashboard-state-item {
    padding: 4px;
    border-radius: 6px;

    &__icon {
      padding-right: 4px;
    }

    &__yellow {
      color: #db9f10 !important;

      &--background {
        background-color: #fff7d0;
        border: 1px solid #ffe173;
    }
  }

    &__red {
      color: #db465d !important;

      &--background {
        background-color: #ffe8df;
        border: 1px solid #ffaa9f;
      }
    }

    &__blue {
      color: #1367d4 !important;

      &--background {
        background-color: #d1effe;
        border: 1px solid #75c3fc;
      }
    }

    &__green {
      color: #24831d !important;

      &--background {
        background-color: #eafbd9;
        border: 1px solid #ace989;
      }
    }

    &__grey {
        color: #6c87bb !important;

      &--background {
        background-color: #edf5fd;
        border: 1px solid #c5daF3;
      }
    }
  }
}
</style>
