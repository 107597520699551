import _ from "lodash";
import FeatureFlags from '@/common/reusable/featureFlagsChecker';
import { hasSignIdentitySignAction } from '@/features/contracts/stores/contractSignTypes';

function arePartiesFilled(contract) {
  let proposersCount = 0;
  const parties = new Set();
  for (let index in contract.sign_identities) {
    const signIdentity = contract.sign_identities[index];
    if (!signIdentity.email && isFillableSignIdentity(signIdentity, contract)) {
      return false;
    }
    if (signIdentity.is_proposer) {
      proposersCount++;
    } else {
      parties.add(signIdentity.party_order)
    }
  }
  // dynamic version of this code is in Parties.analyzeParties that returns isIncomplete + backend PrepareDocsBeforeSend.validateContract
  return proposersCount > 0
    && (contract.type != 'form' || parties.size >= contract.template.analysis.minCounterpartiesCount);
}

function isFillableSignIdentity(signIdentity, contract) {
  // copy-pasted isIgnoredSignIdentity from backend
  return !(!signIdentity.is_proposer && FeatureFlags.isUnilateralContract(contract));
}

function getNextUnsigned(contract) {
  const attachments = _.get(contract, 'attachments');
  if (attachments && attachments.length > 0) {
    return _
      .chain(attachments)
      .sortBy('id')
      .find((attachment) => isUnsignedOrUnapproved(attachment))
      .value();
  }
  return null;
}

function getNextUnviewed(contract) {
  const attachments = _.get(contract, 'attachments');
  if (attachments && attachments.length > 0) {
    return _
      .chain(attachments)
      .sortBy('id')
      .find((attachment) => {
        const signIdentity = getCurrentSignIdentity(attachment);
        if (signIdentity && signIdentity.is_viewed) {
          return false;
        }
        return isUnsignedOrUnapproved(attachment);
      })
      .value();
  }
  return null;
}

function getNextDraft(contract, shit = false) {
  if (shit === true) {
    return _.chain(contract)
      .get('attachments')
      .find((attachment) => {
        return (
          attachment.state === 'draft' &&
          contract.id !== attachment.id &&
          attachment?.is_send_auths === false
        );
      })
      .value()
  }

  return _.chain(contract)
    .get('attachments')
    .find((attachment) => attachment.state == 'draft')
    .value();
}

function isUnsignedOrUnapproved(contract) {
  return isUnsigned(contract) || isUnapproved(contract);
}

function isUnsigned(contract) {
  return isSignatureRequiredForCurrentUser(contract)
    && isNotSignedOrStamped(getCurrentSignIdentity(contract));
}

function isUnapproved(contract) {
  return isApprovalRequiredForCurrentUser(contract)
    && isNotReallyApproved(getCurrentSignIdentity(contract));
}

function isSignatureRequiredForCurrentUser(contract) {
  const signIdentity = getCurrentSignIdentity(contract);
  return signIdentity && !signIdentity.dont_sign_by_identity;
}

function isApprovalRequiredForCurrentUser(contract) {
  const signIdentity = getCurrentSignIdentity(contract);
  return signIdentity && signIdentity.dont_sign_by_identity;
}

// Code that modifies sign_identity.contract_role cannot use functions that are using dont_sign_by_identity
function isInconsistentContractRole(signIdentity, areSignaturesPlaced) {
  return isSigningContractRole(signIdentity) ? !areSignaturesPlaced : areSignaturesPlaced;
}

function hasIncompleteSigningContractRole(contract, signIdentity) {
  return isFillableSignIdentity(signIdentity, contract)
    && isSigningContractRole(signIdentity)
    && isContractRoleNotSignedOrApproved(signIdentity);
}

function isContractRoleNotSignedOrApproved(signIdentity) {
  // copy-pasted isReallySignedOrApproved from backend (isFillableSignIdentity must be called before this method)
  return isSigningContractRole(signIdentity) ? isNotSignedOrStamped(signIdentity) : isNotReallyApproved(signIdentity);
}

function isNotSignedOrStamped(signIdentity) {
  return signIdentity.is_signed === false;
}

function isNotReallyApproved(signIdentity) {
  return signIdentity.is_approved === false;
}

function isSigningContractRole(signIdentity) {
  return hasSignIdentitySignAction(signIdentity);
}

function editContractRole(signIdentity, contractRole) {
  signIdentity.contract_role = contractRole;
}

function isSignedByMe(contract) {
  const signIdentity = getCurrentSignIdentity(contract);
  // copy-pasted AnalyzeContract.isSigned, ApiSignIdentity.isSigned is tricky
  // (https://gitlab.com/digitalfactorycz/ismlouva/-/commit/e87fee5)
  return signIdentity && (signIdentity.dont_sign_by_identity || signIdentity.is_signed);
}

function getCurrentSignIdentity(contract) {
  // fallback for ApiInvite and SignIdentity without contract
  // -> beware using !, it might be true, because field is not defined in contract
  return contract?.current_sign_identity || contract?.sign_identity || contract;
}

function getLegacyCurrentSignIdentity(contract) {
  if (!contract?.sign_identities?.length) {
    return;
  }

  if (!contract.one_device) {
    return contract?.current_sign_identity || contract.attachments?.find((doc) => {
      return doc?.current_sign_identity;
    })?.current_sign_identity;
  }

  // one-device is tricky - current user is same, sign identities are signing/approving in order
  for (let i in contract.sign_identities) {
    const signIdentity = contract.sign_identities[i];

    if (isUnsignedOrUnapproved(signIdentity) && signIdentity?.is_signable_by_current_user) {
      return signIdentity;
    }
  }
  return false; // null could cause errors (check workspaceById in store.js)
}

export const ContractHelper = {
  arePartiesFilled,
  isFillableSignIdentity,
  isUnsigned,
  isUnsignedOrUnapproved,
  isSignedByMe,
  isSignatureRequiredForCurrentUser,
  isApprovalRequiredForCurrentUser,
  isInconsistentContractRole,
  hasIncompleteSigningContractRole,
  isSigningContractRole,
  editContractRole,
  getNextUnsigned,
  getNextUnviewed,
  getNextDraft,
  getCurrentSignIdentity,
  getLegacyCurrentSignIdentity,
};
