import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import Vuelidate from 'vuelidate';
import vuetify from '@/plugins/vuetify';
import { i18n } from '@/plugins/i18n';
import { createPinia, PiniaVuePlugin } from 'pinia';
import { environment } from '@/config/environment';

import '@/plugins/base';
import 'nprogress/nprogress.css';
import '@/directives/fitTextDirective.js';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

import shadow from 'vue-shadow-dom';
import VuejsDialog from 'vuejs-dialog';
import lineClamp from 'vue-line-clamp';

import { ExternalDriveService } from '@/services/ExternalDriveService.js';

import VueSimpleMarkdown from 'vue-simple-markdown';
import VueCookies from 'vue-cookies';
import VueSignaturePad from 'vue-signature-pad';
import VueNotification from '@kugatsu/vuenotification';
import 'vue-simple-markdown/dist/vue-simple-markdown.css';
import { gsap } from 'gsap';

Vue.config.productionTip = false;

Vue.prototype.$env = environment;

// TODO: external drive - is frontend redirect necessary? it looks weird when /account page waits on processing code in backend
const queryString = window.location.search;
if ('/account' === window.location.pathname && 0 === queryString.indexOf('?code')) {
  ExternalDriveService.onedriveAuthFinish(queryString);
}

Vue.use(PiniaVuePlugin);
Vue.use(shadow);
Vue.use(Vuelidate);
Vue.use(VueCookies);
Vue.use(VueSignaturePad);
Vue.use(require('vue-moment'));
Vue.use(VueSimpleMarkdown, 'vue-simple-markdown');
Vue.use(VuejsDialog, {
  okText: 'OK',
  cancelText: 'Cancel',
});
Vue.use(VueNotification, {
  timer: 5,
  position: 'topRight',
  showCloseIcn: true,
  showLeftIcn: false,
  error: {
    background: '#FFEFEF',
    color: '#cd0062',
    textColor: '#cd0062',
  },
  success: {
    background: '#E9FBF4',
    color: '#064e2b',
    textColor: '#064e2b',
  },
  warning: {
    background: '#FFFAD1',
    color: '#8b572a',
    textColor: '#8b572a',
  },
  animateIn: function () {
    // eslint-disable-next-line no-undef
    let tl = gsap.timeline();
    tl.from(this.notificationEl, {
      opacity: 0,
      duration: 0.4,
    }).to(this.notificationEl, {
      height: 'fit-content',
    });
    return tl;
  },
  animateOut: function () {
    let tl = gsap.timeline();
    tl.from(this.notificationEl, {
      height: 'fit-content',
    }).to(this.notificationEl, {
      opacity: 0,
      duration: 0.4,
    });
    return tl;
  },
});
Vue.use(lineClamp, {
  importCss: true,
});

Vue.nextTick(() => {
  window.addEventListener('resize', () => {
    store.dispatch('window/updateWindowWidth');
  });
});

let application;

if (!application) {
  application = new Vue({
    i18n,
    router,
    store,
    vuetify,
    pinia: createPinia(),
    render: (signi) => signi(App),
  }).$mount('#app');
}
