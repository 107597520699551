<template>
  <div class="py-10 text-center">
    <h2 class="mb-0 subtitle-1 font-weight-regular text-center black--text">
      {{ $t('contract.fill.help') }}
    </h2>

    <div class="content-legend__items">
      <div class="content-legend__item">
        <div class="content-legend__ico content-legend__ico--green"></div>
        <div class="content-legend__content">{{ $t('contract.fill.done') }}</div>
      </div>

      <div class="content-legend__item">
        <div class="content-legend__ico content-legend__ico--yellow"></div>
        <div class="content-legend__content">{{ $t('contract.fill.wip') }}</div>
      </div>

      <div class="content-legend__item">
        <div class="content-legend__ico content-legend__ico--violet"></div>
        <div class="content-legend__content">{{ $t('contract.fill.remaining') }}</div>
      </div>

      <div class="content-legend__item">
        <div class="content-legend__ico content-legend__ico--red"></div>
        <div class="content-legend__content">{{ $t('contract.fill.error') }}</div>
      </div>

      <div v-if="isProposer" class="content-legend__item">
        <div class="content-legend__ico content-legend__ico--blue"></div>
        <div class="content-legend__content">{{ $t('contract.fill.counterparty') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentLegend',
  props: {
    isProposer: {
      type: Boolean,
    }
  }
}
</script>

<style lang="scss" scoped>
.content-legend {
  &__items {
    display: inline-block;
    max-width: 380px;
    margin-top: 6px;
    @include clearfix;
  }

  &__item {
    margin-top: 12px;
    width: 50%;
    float: left;
    @include clearfix;

    @include xs {
      width: 100%;
    }
  }

  &__ico {
    width: 21px;
    height: 21px;
    border-radius: 50%;
    border: 1px solid black;
    float: left;

    &--green {
      background-color: #E9FBF4;
      border-color: #B1F0D7;
    }

    &--yellow {
      background-color: #FFFAD1;
      border-color: #EFE8AA;
    }

    &--violet {
      background-color: #FBE6FB;
      border-color: #f5c2f5;
    }

    &--red {
      background-color: #FFE2E2;
      border-color: #F5C0C0;
    }

    &--blue {
      background-color: #C9EAFF;
      border-color: #9AA9D1;
    }
  }

  &__content {
    box-sizing: border-box;
    font-weight: 300;
    font-size: 15px;
    text-align: left;
    color: #000000;
    letter-spacing: -0.03px;
    padding-left: 30px;
    padding-top: 2px;
  }
}
</style>
