<template>
  <v-container>
    <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
      <h3>{{ $t('account.drop_image') }}</h3>
    </div>

    <v-row class="mb-2 pa-0" style="background: white; border: 1px solid #808080; border-radius: 8px;">
      <v-col cols="12">
        <v-row align="start" justify="center">
          <v-col class="avatar__text">
            <v-row align="start" justify="start">
              <v-col cols="12" class="font-weight-regular subtitle-1">
                {{ label }}
              </v-col>

              <v-col cols="12" class="pt-0">
                <v-btn
                  color="primary"
                  block
                  x-small
                  @click="$refs.upload.$el.querySelector('input[type=file]').click()"
                >
                  {{ src === '' ? $t('general.add') : $t('general.edit') }}
                </v-btn>
              </v-col>

              <v-col cols="12" class="pt-0">
                <v-btn
                  color="error"
                  :disabled="src === ''"
                  :loading="isDeletingImage"
                  x-small
                  block
                  @click="deleteBrandingImage()"
                >
                  {{ $t('general.delete') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="auto" class="avatar__image">
            <v-avatar
              size="108"
              color="#f1f6fd"
              :rounded="rounded"
              style="cursor: pointer;"
              @click="$refs.upload.$el.querySelector('input[type=file]').click()"
            >
              <img v-if="src" :src="src" :alt="label" style="width: auto !important" />
            </v-avatar>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="d-none">
        <file-upload
          extensions="gif,jpg,jpeg,png,webp"
          accept="image/png,image/gif,image/jpeg,image/webp"
          :name="name"
          class="btn btn-primary"
          :post-action="url"
          :drop="!edit"
          :headers="headers"
          v-model="files"
          @input-filter="inputFilter"
          @input-file="inputFile"
          ref="upload"
        />
      </v-col>
    </v-row>

    <SigniModal v-model="edit" v-show="files.length && edit">
      <v-container class="pb-0 mb-0" fluid>
        <v-row no-gutters>
          <v-col cols="12" class="pb-5">
            <div class="avatar-edit-image mx-auto" v-if="files.length">
              <img ref="editImage" :src="files[0].url" :alt="name" />
            </div>
          </v-col>
        </v-row>

        <v-row align="center" justify="space-between" class="mt-5" no-gutters>
          <v-col cols="4">
            <v-btn @click.prevent="edit = false" block outlined>
              {{ $t('general.cancel') }}
            </v-btn>
          </v-col>

          <v-col cols="4">
            <v-btn type="submit" @click.prevent="editSave" block color="primary">
              {{ $t('general.save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </SigniModal>

    <div v-if="tip">
      <div class="tip" v-html="tip"></div>
    </div>
  </v-container>
</template>

<script>
import { deleteBrandingImage } from '@workspace/services/workspaceService'
import Cropper from 'cropperjs';
import FileUpload from 'vue-upload-component';
import SigniModal from "@/components/SigniModal";

export default {
  name: 'ImageUpload',
  components: {
    FileUpload,
    SigniModal
  },
  data() {
    return {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      files: [],
      edit: false,
      cropper: false,
      isDeletingImage: false
    };
  },
  props: {
    attribute: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true,
    },
    src: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    ratio: {
      type: Number,
      required: false,
      default: NaN,
    },
    name: {
      type: String,
      required: true,
    },
    tip: {
      type: String,
      required: false,
      default: '',
    },
    minCropBoxWidth: {
      type: Number,
      required: false,
    },
    minCropBoxHeight: {
      type: Number,
      required: false,
    },
    crop: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    edit(value) {
      if (value) {
        this.$nextTick(function () {
          if (!this.$refs.editImage) {
            return
          }
          this.cropper = new Cropper(this.$refs.editImage, {
            aspectRatio: this.ratio || NaN,
            viewMode: 1,
            minCropBoxWidth: this.minCropBoxWidth || NaN,
            autoCropArea: 1,
            minCropBoxHeight: this.minCropBoxHeight || NaN,
            ready: () => {
              if (!this.crop) {
                return this.editSave();
              }
            }
          });
        })
      } else {
        if (this.cropper) {
          this.cropper.destroy();
          this.cropper = false;
        }
      }
    },
    files: function() {
      if( this.files[0].success ) {
        this.$emit('input', this.files);
      }
    }
  },
  methods: {
    deleteBrandingImage () {
      this.isDeletingImage = true

      deleteBrandingImage(this.$route.params.workspace_id, this.attribute)
        .then(
          () => {
            this.$emit('updateWorkspace')
            this.isDeletingImage = false
          },
          () => {
            this.$notification.error(this.$t('general.error'));
            this.isDeletingImage = false
          }
        )
    },
    editSave() {
      this.edit = false;
      let oldFile = this.files[0];
      let binStr = atob(this.cropper.getCroppedCanvas().toDataURL(oldFile.type).split(',')[1]);
      let arr = new Uint8Array(binStr.length);
      for (let i = 0; i < binStr.length; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      let file = new File([arr], oldFile.name, { type: oldFile.type });
      this.$refs.upload.update(oldFile.id, {
        file,
        type: file.type,
        size: file.size,
        active: true,
      });
    },

    inputFile(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        this.$nextTick(function () {
          this.edit = true
        })
      }

      if (!newFile && oldFile) {
        this.edit = false
      }
    },

    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.alert('Your choice is not a picture');
          return prevent();
        }
      }

      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = '';
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file);
        }
      }
    },
  }
}
</script>

<style lang="scss">
  .drop-active {
    position: absolute;
    display: flex;
    width: 20rem;
    height: 7rem;
    border-radius: 8px;
    margin: 0 -0.2rem;
    background: transparent;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    backdrop-filter: blur(15px);
    color: #5d259e;
  }

  .profile-avatar {
    height: 108px;
    border-radius: 0;
  }

  .avatar-edit-image {
    width: fit-content;
    max-height: 340px !important;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .cropper-container {
    direction: ltr;
    font-size: 0;
    line-height: 0;
    position: relative;
    -ms-touch-action: none;
    touch-action: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .cropper-container img {
    display: block;
    height: 100%;
    image-orientation: 0deg;
    max-height: none !important;
    max-width: none !important;
    min-height: 0 !important;
    min-width: 0 !important;
    width: 100%;
  }

  .cropper-wrap-box,
  .cropper-canvas,
  .cropper-drag-box,
  .cropper-crop-box,
  .cropper-modal {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .cropper-wrap-box,
  .cropper-canvas {
    overflow: hidden;
  }

  .cropper-drag-box {
    background-color: #fff;
    opacity: 0;
  }

  .cropper-modal {
    background-color: #000;
    opacity: 0.5;
  }

  .cropper-view-box {
    display: block;
    height: 100%;
    outline: 1px solid #39f;
    outline-color: rgba(51, 153, 255, 0.75);
    overflow: hidden;
    width: 100%;
  }

  .cropper-dashed {
    border: 0 dashed #eee;
    display: block;
    opacity: 0.5;
    position: absolute;
  }

  .cropper-dashed.dashed-h {
    border-bottom-width: 1px;
    border-top-width: 1px;
    height: calc(100% / 3);
    left: 0;
    top: calc(100% / 3);
    width: 100%;
  }

  .cropper-dashed.dashed-v {
    border-left-width: 1px;
    border-right-width: 1px;
    height: 100%;
    left: calc(100% / 3);
    top: 0;
    width: calc(100% / 3);
  }

  .cropper-center {
    display: block;
    height: 0;
    left: 50%;
    opacity: 0.75;
    position: absolute;
    top: 50%;
    width: 0;
  }

  .cropper-center::before,
  .cropper-center::after {
    background-color: #eee;
    content: ' ';
    display: block;
    position: absolute;
  }

  .cropper-center::before {
    height: 1px;
    left: -3px;
    top: 0;
    width: 7px;
  }

  .cropper-center::after {
    height: 7px;
    left: 0;
    top: -3px;
    width: 1px;
  }

  .cropper-face,
  .cropper-line,
  .cropper-point {
    display: block;
    height: 100%;
    opacity: 0.1;
    position: absolute;
    width: 100%;
  }

  .cropper-face {
    background-color: #fff;
    left: 0;
    top: 0;
  }

  .cropper-line {
    background-color: #39f;
  }

  .cropper-line.line-e {
    cursor: ew-resize;
    right: -3px;
    top: 0;
    width: 5px;
  }

  .cropper-line.line-n {
    cursor: ns-resize;
    height: 5px;
    left: 0;
    top: -3px;
  }

  .cropper-line.line-w {
    cursor: ew-resize;
    left: -3px;
    top: 0;
    width: 5px;
  }

  .cropper-line.line-s {
    bottom: -3px;
    cursor: ns-resize;
    height: 5px;
    left: 0;
  }

  .cropper-point {
    background-color: #39f;
    height: 5px;
    opacity: 0.75;
    width: 5px;
  }

  .cropper-point.point-e {
    cursor: ew-resize;
    margin-top: -3px;
    right: -3px;
    top: 50%;
  }

  .cropper-point.point-n {
    cursor: ns-resize;
    left: 50%;
    margin-left: -3px;
    top: -3px;
  }

  .cropper-point.point-w {
    cursor: ew-resize;
    left: -3px;
    margin-top: -3px;
    top: 50%;
  }

  .cropper-point.point-s {
    bottom: -3px;
    cursor: s-resize;
    left: 50%;
    margin-left: -3px;
  }

  .cropper-point.point-ne {
    cursor: nesw-resize;
    right: -3px;
    top: -3px;
  }

  .cropper-point.point-nw {
    cursor: nwse-resize;
    left: -3px;
    top: -3px;
  }

  .cropper-point.point-sw {
    bottom: -3px;
    cursor: nesw-resize;
    left: -3px;
  }

  .cropper-point.point-se {
    bottom: -3px;
    cursor: nwse-resize;
    height: 20px;
    opacity: 1;
    right: -3px;
    width: 20px;
  }

  @media (min-width: 768px) {
    .cropper-point.point-se {
      height: 15px;
      width: 15px;
    }
  }

  @media (min-width: 992px) {
    .cropper-point.point-se {
      height: 10px;
      width: 10px;
    }
  }

  @media (min-width: 1200px) {
    .cropper-point.point-se {
      height: 5px;
      opacity: 0.75;
      width: 5px;
    }
  }

  .cropper-point.point-se::before {
    background-color: #39f;
    bottom: -50%;
    content: ' ';
    display: block;
    height: 200%;
    opacity: 0;
    position: absolute;
    right: -50%;
    width: 200%;
  }

  .cropper-invisible {
    opacity: 0;
  }

  .cropper-bg {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC');
  }

  .cropper-hide {
    display: block;
    height: 0;
    position: absolute;
    width: 0;
  }

  .cropper-hidden {
    display: none !important;
  }

  .cropper-move {
    cursor: move;
  }

  .cropper-crop {
    cursor: crosshair;
  }

  .cropper-disabled .cropper-drag-box,
  .cropper-disabled .cropper-face,
  .cropper-disabled .cropper-line,
  .cropper-disabled .cropper-point {
    cursor: not-allowed;
  }

  .tip {
    border-radius: 6px;
    border: 1px solid #F1EAAF;
    background: #FFFAD1;
    padding: 10px 30px;
    font-size: 15px;
    color: #73560B;
    text-align: left;
    line-height: 21px;
    font-weight: 300;
    margin-top: 15px;
    position:relative;
    margin-bottom: 25px;
    box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.15);

    &:before{
      content: "";
      width: 8px;
      height: 8px;
      border: 1px solid #F1EAAF;
      background: #FFFAD1;
      position: absolute;
      top: -6px;
      transform: rotate(45deg);
      border-bottom: none;
      border-right: 0;
      left: 25px;

      @include md{
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
      }
    }

    @include md{
      padding: 10px 15px;
    }
  }

  .clearfix:before, .clearfix:after {
    content: " ";
    display: table;
  }

  .clearfix:after {
    clear: both;
  }
</style>
