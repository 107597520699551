'use strict';

import moment from 'moment';
import { getNormalizeLocaleCode } from '@/plugins/i18n/Locales';
import { i18n } from '@/plugins/i18n';

const defaultDateFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

/**
 * @param date
 * @param format
 * @returns {string|string}
 */
export const getFormattedDate = ({ date, format = 'DD.MM.YYYY hh:mm' }) => {
  const formattedDate = moment(new Date(String(date)));

  return formattedDate.isValid() ? formattedDate?.format(format) : '';
};

/**
 * @param date
 * @param options
 * @param locale
 * @returns {string|string}
 */
export const getFormattedDateByLocale = ({ date, options = undefined, locale = i18n.locale }) => {
  let currentDate;

  if ('string' === typeof date && date.split(' ').length > 1) {
    currentDate = date?.replace(' ', 'T') || 'now';
  } else {
    currentDate = date;
  }

  currentDate = new Date(currentDate);

  if (
    Object.prototype.toString.call(currentDate) !== '[object Date]' ||
    isNaN(currentDate.getTime())
  ) {
    return '';
  }

  return currentDate.toLocaleDateString(
    getNormalizeLocaleCode(locale),
    options ?? defaultDateFormatOptions,
  );
};
