import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css';

import cs from 'vuetify/lib/locale/cs';
import en from 'vuetify/lib/locale/en';
import de from 'vuetify/lib/locale/de';
import sk from 'vuetify/lib/locale/sk';
import pl from 'vuetify/lib/locale/pl';
import hu from 'vuetify/lib/locale/hu';
import nl from 'vuetify/lib/locale/nl';
import uk from 'vuetify/lib/locale/uk';
import bg from 'vuetify/lib/locale/bg';
import ru from 'vuetify/lib/locale/ru';
import vi from 'vuetify/lib/locale/vi';

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: 'sm', // This is equivalent to a value of 960
  },
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#5f23a0',
        success: '#51CF66',
        accept: '#41e68c',
      },
    },
  },
  lang: {
    locales: {
      cs,
      en,
      de,
      sk,
      pl,
      hu,
      nl,
      uk,
      bg,
      ru,
      vi,
    },
  },
});
