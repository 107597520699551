<template>
  <div class="input input-text" v-bind:class="{'invalid': invalid, 'disabled': disabled}">
    <label>
      <span v-if="label" class="input__label">{{ label }}</span>

      <textarea
        v-model="content"
        class="textarea__data"
        :placeholder="placeholder"
        :maxlength="maxLength"
        :disabled="disabled"
      >
        <slot></slot>
      </textarea>
    </label>

    <div v-if="maxLength" class="v-text-field__details">
      <div class="v-messages theme--light">
        <div class="v-messages__wrapper"></div>
      </div>
      <div class="v-counter theme--light">{{ content.length }} / {{ maxLength }}</div>
    </div>

    <p v-if="invalid" class="input__error">{{error}}</p>

    <div class="tip" v-if="tip">{{ $t('general.tip') }}: {{tip}}</div>

    <div class="tip" v-if="hint" v-html="hint"></div>
  </div>
</template>


<script>
export default {
  name: 'Input',
  props: {
    value: {
      type: String,
    },
    label: String,
    placeholder: String,
    disabled: Boolean,
    error: String,
    tip: String,
    hint: String,
    validation: {
      type: Boolean,
      default: true
    },
    invalid: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default: null,
    },
    useVuetify: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    content: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', null !== this.maxLength
          ? value.slice(0, this.maxLength)
          : value
        );
      },
    },
  },
};
</script>

<style lang="scss">
.textarea__data{
  outline: none;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(177, 187, 203);
  border-radius: 6px;
  height: 216px;
  padding: 15px 20px;
  font-size: 17px;
  font-weight: 300;
  color: black;
  letter-spacing: -0.03px;
  max-width: 100%;
  margin: 0;
  min-width: 100%;
  max-height: 200px;
  min-height: 150px;
}
.input-text.input{
  width: 100%;
}
.content-box{
  .textarea__data{
    font-size: 15px;
  }
}

.tip{
  border-radius: 6px;
  background: #FFFAD1;
  padding: 10px 30px;
  font-size: 15px;
  color: #73560B;
  text-align: left;
  line-height: 21px;
  font-weight: 300;
  margin-top: 15px;
  position:relative;
  margin-bottom: 25px;
  box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.15);

  &:before{
    content: "";
    width: 8px;
    height: 8px;
    background: #FFFAD1;
    position: absolute;
    top: -6px;
    transform: rotate(45deg);
    border-bottom: none;
    border-right: 0;
    left: 25px;

    @include md {
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
    }
  }

  @include md {
    padding: 10px 15px;
  }
}
</style>
