<template>
  <v-card flat class="mt-10" ref="signatureText">
    <v-row align="center" justify="start">
      <v-col cols="3" class="d-flex justify-end">{{ $t('signature.font_family') }}:</v-col>

      <v-col cols="6">
        <v-select
          :items="availableFonts"
          v-model="selectedFont"
          :hint="selectedFont"
          outlined
          dense
          hide-details
          hide-selected
        />
      </v-col>
    </v-row>

    <v-row align="center" justify="start">
      <v-col cols="3" class="d-flex justify-end">{{ `${$t('general.name')}:` }}</v-col>

      <v-col cols="6">
        <v-text-field
          v-model="signatureInput"
          :rules="signatureInputLength"
          ref="signatureInputRef"
          hide-details="auto"
          outlined
          dense
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col align="center" justify="center">
        <v-card outlined flat width="260px" height="150px" class="d-flex flex-column align-center">
          <v-card-subtitle class="py-1 signature-preview__header">
            {{ $t('signature.text_preview') }}
          </v-card-subtitle>

          <TextSignatureCanvas :fontFamily="selectedFont" :textSignature="signatureInput" />
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { defineComponent } from 'vue';
import { useSignatureStore } from '@/features/signature/stores/signature';
import TextSignatureCanvas from '@/features/signature/components/TextSignatureCanvas';

export default defineComponent({
  name: 'SignatureText',
  components: {
    TextSignatureCanvas,
  },
  setup() {
    const signatureStore = useSignatureStore();

    return {
      signatureStore,
    };
  },
  data() {
    return {
      availableFonts: [
        'Italianno',
        'Pacifico',
        'Caveat',
        'Sacramento',
        'Inspiration',
        'Dancing Script',
        'Lobster',
        'Parisienne',
        'Smooch',
      ],
      selectedFont: 'Italianno' || '',
      signatureInput: '',
      signatureInputLength: [
        (v) => !!v || this.$t('general.required_field'),
        (v) => (v && v.length >= 2 && v.length < 50) || this.$t('signature.text.required_lenght'),
      ],
    };
  },
  computed: {
    getTextSignatureInput() {
      return this.signatureStore.getTextSignatureInput;
    },
  },
  watch: {
    signatureInput(string) {
      this.signatureStore.setTextSignatureInput(string);

      if (string.length >= 50) {
        this.signatureInput = string.slice(0, 50);
      }
    },
  },
  mounted() {
    this.setTextSignatureCanvasDimensions();
  },
  methods: {
    setTextSignatureCanvasDimensions() {
      if (!this.$refs.signatureText) {
        return;
      }

      const dimensions = Object.assign(
        {},
        {
          width: this.$refs.signatureText.$el.offsetWidth,
          height: this.$refs.signatureText.$el.offsetHeight,
        },
      );

      return this.signatureStore.setTextSignatureCanvasDims(dimensions);
    },
  },
});
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Italianno|Pacifico|Caveat|Sacramento|Inspiration|Dancing+Script|Lobster|Parisienne|Smooch');

.signature-preview {
  &__header {
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
  }

  &__text {
    font-size: 2rem;
    line-height: 2rem;
    text-align: left;
  }
}
</style>
