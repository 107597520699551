import { render, staticRenderFns } from "./ArchiveNotification.vue?vue&type=template&id=703d1518&scoped=true&"
import script from "./ArchiveNotification.vue?vue&type=script&lang=js&"
export * from "./ArchiveNotification.vue?vue&type=script&lang=js&"
import style0 from "./ArchiveNotification.vue?vue&type=style&index=0&id=703d1518&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "703d1518",
  null
  
)

export default component.exports