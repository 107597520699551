<template>
  <v-dialog v-model="showPreviewDialog" fullscreen>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" class="mb-5" outlined block dark v-bind="attrs" v-on="on">
        <v-icon left>mdi-file-find</v-icon>
        {{ $t('workspace.appearance.preview_title') }}
      </v-btn>
    </template>

    <v-card class="previewBackground" :style="`background-image: url(${brandingBackground})`">
      <v-toolbar dense flat>
        <v-spacer></v-spacer>
        <v-btn class="mt-md-2 mr-md-2" icon @click="showPreviewDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-container class="fill-height" style="min-height: 90vh" fluid>
        <v-card
          class="intro__wrapper mx-auto pt-2 pb-5 px-2 px-md-10 text-center"
          elevation="5"
          width="100%"
          max-width="40rem"
          rounded="lg"
          :style="`background-color: ${brandingLayerColor}`"
        >
          <v-card-title>
            <Logo class="mx-auto" :logo="brandingLogo" :white="false" larger />
          </v-card-title>

          <v-card-subtitle v-if="userAvatar" class="mt-md-5">
            <v-avatar size="140">
              <img :src="userAvatar" alt="Proposer logo" />
            </v-avatar>
          </v-card-subtitle>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <h2
                  class="headline font-weight-medium text-center"
                  v-html="
                    `${activeWorkspace.title}<br />${$tc('signing.counterparty_intro_message')}`
                  "
                  :style="`color: ${brandingPrimaryTextColor}`"
                ></h2>
              </v-col>

              <v-col cols="12" class="py-0">
                <div class="contract__data-download py-5 px-4">
                  <v-btn
                    text
                    block
                    :color="brandingPrimaryColor"
                    class="contract__data-download-button"
                  >
                    <v-icon left>mdi-file-eye-outline</v-icon>
                    <span
                      class="text-decoration-underline font-weight-medium"
                      style="
                        max-width: 155px;
                        overflow: hidden !important;
                        white-space: nowrap !important;
                        text-overflow: ellipsis !important;
                      "
                    >
                      {{ 'Lorem Ipsun' }}
                    </span>
                  </v-btn>
                </div>
              </v-col>

              <v-col cols="12">
                <h2
                  class="headline font-weight-medium text-center"
                  :style="`color: ${brandingPrimaryTextColor}`"
                >
                  {{ $t('signing.counterparty_intro_message_continuation') }}
                </h2>
              </v-col>

              <v-col cols="auto" class="mx-auto text-center">
                <p class="mb-5">
                  <small
                    v-html="agreeWithTosText"
                    class="text-with-link font-weight-regular"
                    :style="`color: ${brandingPrimaryTextColor} !important; a > color: ${brandingPrimaryTextColor} !important`"
                  ></small>
                </p>
              </v-col>

              <v-col cols="12">
                <v-btn :color="brandingPrimaryColor" min-width="15rem" x-large dark>
                  {{ $t('contract.show_contract') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>

          <v-row align="center" justify="center" class="mt-15" no-gutters>
            <v-col cols="auto" class="pb-0">
              <v-btn class="text-decoration-underline subtitle-1 font-weight-medium" text>
                {{ $t('general.decline') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { useBrandingStore } from '@/stores/branding';
import { hexToRgbaStyle } from '@/common/reusable/colorFunctions';

export default defineComponent({
  name: 'AppearancePreview',
  props: {
    value: {
      type: Boolean,
    },
    appearance: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const brandingStore = useBrandingStore();

    return { brandingStore };
  },
  computed: {
    ...mapGetters({
      workspaceById: 'workspaceById',
    }),
    showPreviewDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    activeWorkspace() {
      return this.workspaceById(this.$route.params.workspace_id);
    },
    agreeWithTosText() {
      return this.$t('login.agree_with_tos_auto.app_links', {
        terms: this.$t('links.terms_and_conditions.unregistered'),
        privacy: this.$t('links.privacy_policy'),
      });
    },
    userAvatar() {
      return this.appearance?.logo_pdf;
    },
    brandingLogo() {
      return this.appearance?.logo || this.brandingStore.brandingLogo;
    },
    brandingLayerColor() {
      return this.appearance?.layerColor
        ? hexToRgbaStyle(this.appearance?.layerColor, 0.9)
        : this.brandingStore.brandingLayerColor;
    },
    brandingBackground() {
      return this.appearance?.background || this.brandingStore.brandingBackground;
    },
    brandingPrimaryColor() {
      return this.appearance?.primary_color || this.brandingStore.brandingPrimaryColor;
    },
    brandingPrimaryTextColor() {
      return this.appearance?.textColor || this.brandingStore.brandingPrimaryTextColor;
    },
  },
});
</script>

<style lang="scss" scoped>
.previewBackground {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.contract__data-download {
  background-color: #f2f6fc;
  width: 100%;
  max-width: 240px;
  border: 1px solid #e0e3e8;
  border-radius: 8px;
  margin: 0 auto;

  .v-btn__content {
    width: 100%;
    white-space: normal;
  }

  &-button {
    color: #5e239e;
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
  }
}

:deep(.text-with-link a) {
  color: inherit !important;
  font-weight: inherit;

  &:hover {
    color: var(--v-primary-base) !important;
  }
}
</style>
