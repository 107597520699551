<template>
  <div class="language-flag">
    <img :src="flagSource" :alt="localeTitle" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LanguageFlag',
  props: {
    localeData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    flagSource() {
      return require(`@digitalfactorycz/core/dist/assets/flags/${this.localeData.localeCode}.svg`);
    },
    localeTitle() {
      if (this.localeData?.translationKey) {
        return this.$t(this.localeData?.translationKey);
      }

      return this.localeData.localeCode?.toUpperCase();
    },
  },
});
</script>

<style lang="scss" scoped>
.language-flag {
  display: flex;
  border-radius: 6px !important;

  img {
    width: 30px;
    height: 100%;
    max-height: 40px;
    object-fit: scale-down !important;
  }
}
</style>
