<template>
  <v-container class="verification-container pa-0 ma-0" fluid>
    <template v-if="isLoading">
      <Loader />
    </template>

    <template v-else>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-card class="verification__box pa-0 ma-0" elevation="0" rounded="lg">
            <v-card-title>
              <h2 class="title font-weight-medium mb-0">
                {{ $t('verification.settings.aml_questionnaire') }}
              </h2>
            </v-card-title>

            <v-card-text>
              <v-form class="mx-auto">
                <v-row align="center" justify="center" no-gutters>
                  <v-col class="col-12">
                    <v-select
                      v-model="currentLocale"
                      :label="$t('workspace.messages.language_select')"
                      :items="locales"
                      item-value="locale"
                      outlined
                    >
                      <template v-slot:selection="{ item }">
                        <div class="language-toggle__choice--flag">
                          <LanguageFlag :locale-data="item" />
                        </div>
                        {{ item.locale.toUpperCase() }}
                      </template>

                      <template v-slot:item="{ item }">
                        <div class="language-toggle__choice--flag">
                          <LanguageFlag :locale-data="item" />
                        </div>
                        {{ item.locale.toUpperCase() }}
                      </template>
                    </v-select>
                  </v-col>

                  <v-col class="col-12">
                    <v-textarea
                      v-model="authsPoliticalText"
                      :label="$t('workspace.verification.political_text')"
                      :placeholder="$t('aml.questionnaire.form_info')"
                      outlined
                    ></v-textarea>
                  </v-col>

                  <v-col class="col-12 text-center">
                    <v-btn
                      color="primary"
                      :loading="isSending"
                      x-large
                      min-width="14rem"
                      @click="saveAuthsPoliticalText"
                    >
                      {{ $t('general.save') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <BankIdSettings />
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { i18n } from '@/plugins/i18n';
import { getLocales } from '@/plugins/i18n/Locales';
import { getErrorMessage } from '@/common/reusable/errorResponse.js';
import {
  fetchAuthsPoliticalTexts,
  updateAuthsPoliticalTexts,
} from '@workspace/services/verificationService';
import Loader from '@/components/Loader';
import BankIdSettings from '@workspace/components/BankIdSettings';
import LanguageFlag from '@/common/components/LanguageFlag';

export default {
  name: 'WorkspaceSettingsVerification',
  components: {
    LanguageFlag,
    BankIdSettings,
    Loader,
  },
  data() {
    return {
      loaded: false,
      currentLocale: this.$i18n.locale,
      authsPoliticalText: '',
      authsPoliticalTexts: {},
      submitted: false,
    };
  },
  computed: {
    ...mapGetters({
      workspaceById: 'workspaceById',
    }),
    isLoading() {
      return !this.loaded;
    },
    isSending() {
      return this.submitted;
    },
    locales() {
      return getLocales(false);
    },
  },
  watch: {
    currentLocale: {
      handler: function (locale) {
        this.authsPoliticalText = this.authsPoliticalTexts[locale] || '';
      },
    },
  },
  async mounted() {
    await fetchAuthsPoliticalTexts({
      workspaceId: this.$route.params.workspace_id,
    })
      .then((resp) => {
        this.authsPoliticalText = resp[i18n.locale];
        this.authsPoliticalTexts = resp;
      })
      .catch((err) => {
        this.$notification.error(
          getErrorMessage({
            errorCode: err.errorCode,
            errorValues: err.errorValues,
          }),
        );
      })
      .finally(() => {
        this.loaded = true;
      });
  },
  methods: {
    saveAuthsPoliticalText() {
      const data = {};
      data[this.currentLocale] = this.authsPoliticalText;
      this.submitted = true;

      updateAuthsPoliticalTexts({
        workspaceId: this.$route.params.workspace_id,
        data,
      })
        .then((resp) => {
          this.authsPoliticalTexts = resp;
          this.$notification.success(this.$t('account.settings.saved'));
        })
        .catch((err) => {
          this.$notification.error(
            getErrorMessage({
              errorCode: err.errorCode,
              errorValues: err.errorValues,
            }),
          );
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.verification-container {
  .loader-wrap {
    position: relative;
    transform: unset;
    padding: 0;
    left: unset;
  }

  :deep(.v-input__slot::before) {
    border-image: none !important;
  }
}

.verification__box {
  border: 1px solid #d4dff0;
}
</style>
