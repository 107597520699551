import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { defaultLocale, fallbackLocale, isLocaleSupported } from './Locales';
import { loadTranslationsMessages } from '@digitalfactorycz/core/dist/lib/config/applicationTranslations';
import { environment } from '@/config/environment';

Vue.use(VueI18n);

const LOCAL_STORAGE_KEY = 'locale';

function loadLocaleForUnregistered(component, contract) {
  let locale = localStorage.getItem('sign_app_locale');

  if (!locale) {
    locale = contract?.current_user?.locale || contract?.locale || guessCurrentLocale();
  }

  changeLocaleWithoutSave(component, locale);
}

function getCurrentLocale(component) {
  return component && component.$i18n ? component.$i18n.locale : guessCurrentLocale();
}

function guessCurrentLocale() {
  return localStorage.getItem(LOCAL_STORAGE_KEY) || detectBrowserLocale() || defaultLocale;
}

function changeAndSaveLocale(component, locale) {
  const isChanged = changeLocaleWithoutSave(component, locale);

  component.$vuetify.lang.current = locale;

  if (isChanged) {
    localStorage.setItem(LOCAL_STORAGE_KEY, locale);
  }
}

function detectBrowserLocale() {
  const lang =
    navigator.language ||
    navigator.browserLanguage ||
    navigator.systemLanguage ||
    navigator.userLanguage;
  const browserLocale = lang && 'string' === typeof lang && lang.split('-')[0];
  return browserLocale && isLocaleSupported(browserLocale) ? browserLocale : null;
}

function changeLocaleWithoutSave(component, locale) {
  if (
    !component ||
    !component.$i18n ||
    String(locale) === component.$i18n.locale ||
    !isLocaleSupported(locale)
  ) {
    return false;
  }
  component.$i18n.locale = locale;
  component.$root.$i18n.locale = locale;
  return true;
}

const i18n = new VueI18n({
  locale: guessCurrentLocale(),
  fallbackLocale,
  messages: loadTranslationsMessages({
    path: 'exports/base',
    translationsPrefix: `${environment.getAppTranslationsPrefix()}`,
  }),
  pluralizationRules: {
    cs: (count) => (1 === count ? 0 : count < 5 ? 1 : 2),
  },
  escapeParameterHtml: true,
});

export {
  i18n,
  getCurrentLocale,
  loadLocaleForUnregistered,
  changeAndSaveLocale,
  changeLocaleWithoutSave,
};
