<template>
  <v-container class="ma-0 pa-0 text-center" fluid>
    <LandscapeSigning
      v-if="landscapeOrientation"
      :preloaded-signature-image="preloadedSignatureImage"
    />

    <v-card
      v-else
      ref="signatureContent"
      class="mx-auto"
      elevation="0"
      rounded="lg"
      max-width="749px"
      width="100%"
    >
      <v-container class="signature__header ma-0 pa-5" fluid>
        <v-row align="center" justify="center" no-gutters>
          <v-col v-if="!isAccountSignatureEditor" class="col-12 pb-3">
            <h2 class="subtitle-2 font-weight-regular text-center mb-0">
              {{ `${$t('contract.action_required.sign')} ${contractTitle || contract.title}` }}
            </h2>
          </v-col>

          <v-col class="col-12">
            <h2
              class="title font-weight-bold text-center mb-0"
              v-html="isAccountSignatureEditor ? contractTitle : signIdentityTitle"
            ></h2>
          </v-col>
        </v-row>

        <v-row v-if="canShowHeader && isHeaderEditable" align="center" justify="space-between">
          <v-col class="col-12 col-md-6 px-5 px-md-1">
            <v-row
              align="center"
              :justify="$vuetify.breakpoint.mobile ? 'space-between' : 'center'"
              class="pr-md-2"
            >
              <v-col cols="auto" class="px-0">
                <v-subheader class="font-weight-medium px-1 text-center text-md-right">
                  <span :style="`min-width: ${$vuetify.breakpoint.mobile ? '3' : '1.5'}rem`">
                    {{ $t('signature.header.in') }}
                  </span>
                </v-subheader>
              </v-col>

              <v-col>
                <v-text-field
                  ref="signaturePlaceField"
                  v-model="signaturePlace"
                  type="text"
                  name="signature-place"
                  :label="`${$t('signing.name_of_municipality')} *`"
                  hide-details
                  outlined
                  :error="signaturePlaceError"
                  background-color="white"
                  :disabled="isSignaturePlaceDisabled"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="col-12 col-md-6 px-5 px-md-1">
            <v-row
              align="center"
              :justify="$vuetify.breakpoint.mobile ? 'space-between' : 'center'"
              class="pl-md-2"
            >
              <v-col cols="auto" class="px-0">
                <v-subheader class="font-weight-medium px-1 text-center text-md-right">
                  <span style="min-width: 3rem">
                    {{ $t('signature.header.on') }}
                  </span>
                </v-subheader>
              </v-col>

              <v-col>
                <SDatePicker
                  ref="signatureDateField"
                  v-model="signatureDate"
                  :changes-disabled="cantChangeSignatureDate"
                  background-color="white"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="signature__body ma-0" fluid>
        <SignaturePad
          v-if="hasPreloadedSignatureImage"
          ref="signaturePad"
          :preloaded-signature-image="preloadedSignatureImage"
        />

        <template v-else>
          <v-tabs
            v-model="tab"
            show-arrows
            center-active
            fixed-tabs
            @change="resetInputsOnTabChange"
          >
            <v-tab
              v-for="signatureTab in availableSignatureTabs"
              :key="signatureTab.order"
              @change="activeTab(signatureTab.identifier)"
            >
              {{ setTabPlaceholder(signatureTab.identifier) }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" touchless>
            <v-tab-item
              v-for="signatureTab in availableSignatureTabs"
              :key="signatureTab.identifier"
            >
              <component
                v-show="signatureTab.order === tab"
                :is="signatureTab.component"
                :ref="signatureTab.ref"
              />
            </v-tab-item>
          </v-tabs-items>
        </template>
      </v-container>

      <v-container class="signature__footer ma-0 px-md-5 py-md-5" fluid>
        <v-row
          align="center"
          :justify="canSendSMSRequestToSignOnPhone ? 'space-between' : 'end'"
          no-gutters
        >
          <v-col v-if="canSendSMSRequestToSignOnPhone" class="col-auto pb-0">
            <SignOnPhoneDialog
              :custom-color="customColor"
              :sign-identity="signIdentity"
              @contract-signed-on-remote-device="$emit('contractSignedOnRemoteDevice', $event)"
            />
          </v-col>

          <v-col v-if="canSendSMSRequestToSignOnPhone" class="pl-2 text-left">
            <s-help :message="`${$t('info.document.sign', { faqLink: $t('links.faq') })}`"></s-help>
          </v-col>

          <v-col class="col-12 col-md-auto pb-3 pb-md-0">
            <v-btn
              :color="customColor"
              min-width="15rem"
              :loading="isSignatureSubmitted"
              :disabled="!canSaveSignature"
              :block="$vuetify.breakpoint.mobile"
              x-large
              style="color: #ffffff"
              @click="save"
            >
              {{ $t('account.insert_signature') }}
            </v-btn>
          </v-col>
        </v-row>

        <v-card v-if="isMobileLandscapeTipVisible" class="sign-tip" color="#fffccc">
          <v-btn
            icon
            style="position: absolute; top: 0; right: 0"
            @click="mobileLandscapeTip = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <span class="title font-weight-regular" v-html="$t('signing.tipHtml')"></span>
        </v-card>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { defineComponent, computed, ref } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { mapActions as mapPiniaActions } from 'pinia';
import { environment } from '@/config/environment';
import { useBrandingStore } from '@/stores/branding';
import { useSignatureStore } from '@/features/signature/stores/signature';
import { AccountService } from '@/services/AccountService.js';
import { ContractService } from '@/services/ContractService.js';
import { encryptData } from '@signature/services/encryptService';
import { convertImgToBase64 } from '@/common/reusable/imageFunctions';
import { getUserSignature } from '@/features/account/services/accountServices';
import { defaultSignTypes } from '@/common/variables/signature';
import FeatureFlags from '@/common/reusable/featureFlagsChecker';
import LandscapeSigning from '@signature/components/LandscapeSigning';
import SDatePicker from '@/common/components/SDatePicker';
import SignaturePad from '@signature/components/SignaturePad';
import SignOnPhoneDialog from '@signature/components/SignOnPhoneDialog';
import SignatureImage from '@/features/signature/components/SignatureImage';
import SignatureText from '@/features/signature/components/SignatureText';

export default defineComponent({
  name: 'Signature',
  components: {
    SignatureText,
    SignatureImage,
    LandscapeSigning,
    SDatePicker,
    SignaturePad,
    SignOnPhoneDialog,
  },
  props: {
    cantChangeSignatureDate: {
      type: Boolean,
      default: false,
    },
    contract: {
      type: Object,
      default: () => {},
    },
    isAccountSignatureEditor: {
      type: Boolean,
      default: false,
    },
    signIdentity: {
      type: Object,
      required: true,
    },
    orientation: {
      type: String,
      default: 'portrait',
    },
    useDefaultSignTypes: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const canEditHeader = ref(true);
    const brandingStore = useBrandingStore();
    const signatureStore = useSignatureStore();

    return {
      canShowHeader: computed(() => !FeatureFlags.isSignHeaderIgnored(props.signIdentity)),
      isHeaderEditable: computed(() => canEditHeader.value),
      customColor: computed(() => brandingStore.brandingPrimaryColor),
      contractTitle: computed(() => props.contract?.title || props.contract?.contract_title || ''),
      isTextSignatureInputValid: computed(() => signatureStore.isTextSignatureInputValid),
      getSignatureDataUrl: computed(() => signatureStore.getSignatureDataUrl),
      getTextSignatureInput: computed(() => signatureStore.getTextSignatureInput),
      brandingPrimaryColor: computed(() => brandingStore.brandingPrimaryColor),
      brandingPrimaryTextColor: computed(() => brandingStore.brandingPrimaryTextColor),
      brandingLayerColor: computed(() => brandingStore.brandingLayerColor),
      brandingLogo: computed(() => brandingStore.brandingLogo),
    };
  },
  data() {
    return {
      canEditHeader: true,
      disabledSignaturePlace: false,
      mobileLandscapeTip: true,
      signatureSubmitted: false,
      preloadedSignatureImage: null,
      signaturePlaceError: false,
      isFormValid: false,
      tab: null,
      selectedTab: '',
      textSignatureInput: '',
    };
  },
  computed: {
    ...mapGetters({
      isSignatureDateFilled: 'signature/isSignatureDateFilled',
      isSignaturePlaceFilled: 'signature/isSignaturePlaceFilled',
      isSignatureProgressFilled: 'signature/isSignatureProgressFilled',
      profile: 'profile',
      previousDisplayWidth: 'signature/previousDisplayWidth',
      signatureProgress: 'signature/signatureProgress',
      storedSignatureDate: 'signature/signatureDate',
      storedSignatureDateFormatted: 'signature/signatureDateFormatted',
      storedSignaturePlace: 'signature/signaturePlace',
    }),
    signatureDate: {
      get() {
        return this.storedSignatureDate;
      },
      set(value) {
        this.setSignatureDate(value);
      },
    },
    signaturePlace: {
      get() {
        return this.storedSignaturePlace;
      },
      set(value) {
        this.signaturePlaceError = false;
        this.setSignaturePlace(value);
      },
    },
    canLoadUserPresetSignature() {
      return this.signIdentity?.preset_user_signature && this.profile?.has_signature;
    },
    canSendSMSRequestToSignOnPhone() {
      if (this.isAccountSignatureEditor || this.hasPreloadedSignatureImage) {
        return false;
      }

      return (
        environment.checkFeatureFlagState('signOnPhone') &&
        !this.$vuetify.breakpoint.mobile &&
        !this.isAccessFromSMS
      );
    },
    hasPreloadedSignatureImage() {
      return !!this.preloadedSignatureImage;
    },
    isAccessFromSMS() {
      return !!this.$route.query.sms;
    },
    isMobileLandscapeTipVisible() {
      return this.$vuetify.breakpoint.mobile && this.mobileLandscapeTip;
    },
    isSignaturePlaceDisabled() {
      return this.disabledSignaturePlace;
    },
    isSignatureSubmitted() {
      return this.signatureSubmitted;
    },
    isSigningContractOnlyOnce() {
      return FeatureFlags.isSigningContractOnlyOnce(this.contract);
    },
    landscapeOrientation() {
      const signaturePlaceFieldFocused = this.$refs.signaturePlaceField?.isFocused || false;

      return (
        !signaturePlaceFieldFocused &&
        'landscape' === this.orientation &&
        this.$vuetify.breakpoint.mobile &&
        this.$vuetify.breakpoint.width > this.$vuetify.breakpoint.height
      );
    },
    signIdentityTitle() {
      let signIdentityFullName = this.signIdentity?.variable_position
        ? `<span>${this.signIdentity.variable_position}</span>`
        : `<span>${this.signIdentity?.firstname || ''} ${this.signIdentity?.lastname || ''}</span>`;

      if (this.signIdentity?.organization_name) {
        signIdentityFullName += ` / <span class="d-inline-block">${this.signIdentity.organization_name}</span>`;
      }

      return signIdentityFullName;
    },
    isTextSignatureValid() {
      return this.isTextSignatureInputValid;
    },
    availableSignatureTabs() {
      if (this.useDefaultSignTypes) {
        return defaultSignTypes;
      }

      return (
        this.contract?.availableSignTypes
          ?.map((availableSignType) => {
            return Object.assign(
              {},
              {
                ...defaultSignTypes?.find(
                  (defaultSignType) => defaultSignType.identifier === availableSignType.identifier,
                ),
                ...availableSignType,
              },
            );
          })
          ?.sort((a, b) => a?.order - b?.order) || defaultSignTypes
      );
    },
    canSaveSignature() {
      if (this.hasPreloadedSignatureImage) {
        return true;
      }

      switch (this.selectedTab) {
        case 'text':
          return this.isTextSignatureValid;
        case 'signature':
          return this.isSignatureProgressFilled;
        case 'image':
          return this.getSignatureDataUrl?.length > 0; // zajistit reakci pouze na obrázek

        default:
          return true;
      }
    },
  },
  mounted() {
    getUserSignature();
    this.activeTab(this.availableSignatureTabs.find((tab) => 0 === tab.order)?.identifier);

    this.setSignatureColor(this.contract?.signature_color || '#000000');
    this.$vuetify.goTo(this.$refs.signatureContent, {
      duration: 800,
      easing: 'easeInOutCubic',
    });

    if (this.isAccountSignatureEditor) {
      return (() => this.loadSignIdentitySignatureImage())();
    }

    if (this.canLoadUserPresetSignature) {
      this.loadSignIdentitySignatureImage();
    }

    ContractService.signaturefieldshown(this.contract.id, this.signIdentity.id);

    if (
      this.signIdentity.auto_sign_place &&
      this.signIdentity.user_id === this.profile.id &&
      this.signIdentity.is_proposer
    ) {
      this.signaturePlace = this.signIdentity.auto_sign_place;
    }

    if (this.contract.feature_flags?.disableSignaturePlace && this.signIdentity?.signature_place) {
      this.disabledSignaturePlace = true;
      this.signaturePlace = this.signIdentity?.signature_place;
    }

    const preloadedSignature = FeatureFlags.preloadedSignature(this.contract);

    this.canEditHeader = !preloadedSignature || !preloadedSignature?.isSignHeaderDisabled;

    if (!this.canEditHeader) {
      return (() => {
        this.signaturePlace = this.signIdentity?.auto_sign_place || preloadedSignature.place;
        this.signatureDate = preloadedSignature?.date
          ? new Date(preloadedSignature.date)
          : new Date();
      })();
    }

    if (this.signIdentity?.auto_signing_enabled && this.canLoadUserPresetSignature) {
      return (() => {
        this.signatureDate = new Date();
        this.signaturePlace = this.signIdentity.auto_sign_place
          ? this.signIdentity.auto_sign_place
          : '';
      })();
    }

    if (preloadedSignature) {
      this.signatureDate = new Date(preloadedSignature.date) || new Date();

      if (this.canShowHeader) {
        this.signaturePlace = preloadedSignature.place;
      }

      if (preloadedSignature.image) {
        convertImgToBase64(
          preloadedSignature.image,
          (base64Img) => (this.preloadedSignatureImage = base64Img),
        );
      }
    }
  },
  beforeDestroy() {
    this.resetSignature();
    this.resetTextSignature();
  },
  methods: {
    ...mapActions({
      setSignatureColor: 'signature/setSignatureColor',
      setSignatureDate: 'signature/setSignatureDate',
      setSignaturePlace: 'signature/setSignaturePlace',
      resetSignature: 'signature/resetSignature',
      resetSignatureProgress: 'signature/resetSignatureProgress',
    }),
    ...mapPiniaActions(useSignatureStore, {
      resetTextSignature: 'resetTextSignature',
    }),
    loadSignIdentitySignatureImage() {
      AccountService.getSignatureSignIdentity(
        this.signIdentity.email !== this.profile.email ? this.signIdentity.id : null,
      )
        .then((resp) => {
          convertImgToBase64(
            resp?.file_url,
            (base64Img) => (this.preloadedSignatureImage = base64Img),
          );
        })
        .catch((err) => {
          if (
            err?.startsWith('Signature not found for user') ||
            err?.startsWith('User signature not found')
          ) {
            return;
          }

          this.$notification.error(this.$t('contract.errors.signature_not_loaded'));
        });
    },
    async save() {
      this.signatureSubmitted = true;

      if (this.canShowHeader && !this.isSignaturePlaceFilled) {
        return (() => {
          this.signatureSubmitted = false;
          this.signaturePlaceError = true;
          this.$refs.signaturePlaceField.focus();
          this.$notification.error(this.$t('contract.errors.signature_incomplete_place'));
        })();
      }

      if (
        !this.hasPreloadedSignatureImage &&
        !this.isSignatureProgressFilled &&
        'signature' === this.selectedTab
      ) {
        return (() => {
          this.signatureSubmitted = false;
          this.$notification.error(this.$t('contract.errors.signature_incomplete_sign'));
        })();
      }

      let signaturePadData = {
        isEmpty: true,
        data: '',
      };

      if ('signature' !== this.selectedTab) {
        signaturePadData = {
          isEmpty: false,
          data: this.getSignatureDataUrl,
        };
      } else {
        signaturePadData = this.$refs.signaturePad[0]?.saveSignature();
      }

      if (this.hasPreloadedSignatureImage) {
        signaturePadData = {
          isEmpty: false,
          data: this.preloadedSignatureImage,
        };
      }

      if (signaturePadData?.isEmpty && !this.canLoadUserPresetSignature) {
        return (() => {
          this.signatureSubmitted = false;
          this.$notification.error(this.$t('contract.errors.signature_empty'));
        })();
      }

      encryptData({
        data: this.signatureProgress || this.getTextSignatureInput,
        workspaceId: this.$route.params?.workspace_id,
        authToken: this.$route.params?.hash || null,
      })
        .then((encryptedProgressData) => {
          this.$emit('save', {
            signature: {
              signature_date: this.storedSignatureDateFormatted,
              signature_place: this.canShowHeader ? this.signaturePlace : ' ',
            },
            data: signaturePadData.data,
            contract_id: this.contract.id,
            sign_identity_id: this.signIdentity.id,
            progress: encryptedProgressData,
            fromSMS: this.isAccessFromSMS,
          });
        })
        .catch(() => {
          this.$notification.error(this.$t('general.error'));
          this.signatureSubmitted = false;
        });
    },
    resetInputsOnTabChange() {
      if (this.$refs?.signaturePad) {
        this.$refs?.signaturePad?.$refs?.signaturePad?.clearSignature();
      }
      this.resetSignatureProgress();
    },
    setTabPlaceholder(type) {
      const signatureTabsTranslations = {
        text: this.$t('signature.text'),
        signature: this.$t('signature.stylus'),
        image: this.$t('signature.image.add'),
      };

      return signatureTabsTranslations[type];
    },
    activeTab(tab) {
      this.selectedTab = tab;
    },
  },
});
</script>

<style lang="scss" scoped>
.signature {
  &__progress-bar {
    max-width: 35rem;
    width: 100%;
  }

  &__header {
    border: 1px solid #b1bbcb;
    background-color: #f2f6fc;

    @media only screen and (max-width: 960px) {
      border-top: 1px solid #d4dff0;
      border-left: none;
      border-right: none;
      border-radius: 0 !important;
    }
  }

  &__body {
    border-left: 1px solid #b1bbcb;
    border-right: 1px solid #b1bbcb;
    border-bottom: 1px solid #b1bbcb;

    @media only screen and (max-width: 960px) {
      border-left: none;
      border-right: none;
      border-bottom: 1px solid #d4dff0;
    }
  }

  &__footer {
    background-color: #f2f6fc;
    border-left: 1px solid #b1bbcb;
    border-right: 1px solid #b1bbcb;
    border-bottom: 1px solid #b1bbcb;

    @media only screen and (max-width: 960px) {
      border-left: none;
      border-right: none;
      border-bottom: none;
    }
  }
}

.sign-tip {
  background: #fffccc url('~@signature/assets/switch-landscape.png') no-repeat center 1.25rem;
  background-size: 3rem;
  margin: 1rem 0;
  padding: 70px 20px 20px 20px;

  span {
    color: #af824b;
  }
}
</style>
