<template>
  <WorkspaceMemberForm
    v-if="showEditWorkspaceMemberForm"
    v-model="memberSettings"
    :default-config="member"
    :is-submitted="submitted"
    @update-settings="onUpdateMemberSettings"
  />

  <v-card v-else elevation="0">
    <v-row
      v-if="$vuetify.breakpoint.mobile"
      class="pl-5 pr-3 workspace-member__table--row"
      align="center"
      justify="start"
      no-gutters
    >
      <SAvatar
        class="mr-2"
        :size="35"
        :profile-image="member.avatar_url"
        :firstname="member.firstname"
        :lastname="member.lastname"
        background-color="#6DECB0"
      />
      <span class="workspace-member-email__overflow">{{ getMemberTitle(member) }}</span>

      <v-row align="center" justify="end" no-gutters>
        <v-col class="col-auto">
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="canResendInvitation && invitationId && isOwner"
                color="primary"
                :loading="resendingInvitation"
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click="onResendInvitation"
              >
                <v-icon small>mdi-email-send-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('workspace.invitation.resend') }}</span>
          </v-tooltip>
        </v-col>

        <v-col class="col-auto">
          <v-btn color="primary" icon small @click="showEditWorkspaceMemberForm = true">
            <v-icon small>mdi-pencil-outline</v-icon>
          </v-btn>
        </v-col>

        <v-col class="col-auto">
          <v-btn
            v-if="isOwner"
            color="error"
            :disabled="!canDeleteUser"
            icon
            small
            @click="showDeleteContactPreviewItemDialog = true"
          >
            <v-icon small>mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-row>

    <v-row
      v-else
      class="pl-5 pr-3 workspace-member__table--row"
      align="center"
      justify="start"
      no-gutters
    >
      <v-col class="col-3">
        <SAvatar
          class="mr-2"
          :size="35"
          :profile-image="member.avatar_url"
          :firstname="member.firstname"
          :lastname="member.lastname"
          background-color="#6DECB0"
        />
        <span v-if="member.firstname && member.lastname" class="text-break">{{
          `${member.firstname} ${member.lastname}`
        }}</span>

        <span v-else class="text-break">{{ $t('workspace.members.noname_user') }}</span>
      </v-col>

      <v-col class="col-3">
        <span class="text-break">{{ member.position }}</span>
      </v-col>

      <v-col>
        <span class="text-break">{{ member.email }}</span>
      </v-col>

      <v-col class="col-2">
        <v-row
          align="center"
          :justify="$vuetify.breakpoint.mobile ? 'space-between' : 'end'"
          no-gutters
        >
          <v-col class="col-auto">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="canResendInvitation && invitationId && isOwner"
                  color="primary"
                  :loading="resendingInvitation"
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="onResendInvitation"
                >
                  <v-icon small>mdi-email-send-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('workspace.invitation.resend') }}</span>
            </v-tooltip>
          </v-col>

          <v-col class="col-auto">
            <v-btn color="primary" icon small @click="showEditWorkspaceMemberForm = true">
              <v-icon small>mdi-pencil-outline</v-icon>
            </v-btn>
          </v-col>

          <v-col class="col-auto">
            <v-btn
              v-if="isOwner"
              color="error"
              :disabled="!canDeleteUser"
              icon
              small
              @click="showDeleteContactPreviewItemDialog = true"
            >
              <v-icon small>mdi-trash-can-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <SConfirmDialog
      v-model="showDeleteContactPreviewItemDialog"
      :message="`${$t('workspace.team.delete.confirm')}`"
      @confirmation-callback="onDeleteMemberFromWorkspace"
    />
  </v-card>
</template>

<script>
import {
  deleteWorkspaceMemberInvitation,
  deleteWorkspaceMember,
  resendMemberInvitation,
  updateWorkspaceMemberInvitationSettings,
  updateWorkspaceMemberSettings,
} from '@workspace/services/workspaceMembersService';
import SAvatar from '@/common/components/SAvatar';
import SConfirmDialog from '@/common/components/SConfirmDialog';
import WorkspaceMemberForm from '@workspace/components/WorkspaceMemberForm.vue';

export default {
  name: 'WorkspaceMember',
  components: {
    SAvatar,
    SConfirmDialog,
    WorkspaceMemberForm,
  },
  props: {
    member: {
      type: Object,
      required: true,
    },
    workspace: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      deleteMode: false,
      editMode: false,
      memberSettings: {},
      submitted: false,
      resendingInvitation: false,
      showDeleteContactPreviewItemDialog: false,
      showEditWorkspaceMemberForm: false,
    };
  },
  computed: {
    canDeleteUser() {
      return 'owner' !== this.member.rights;
    },
    invitationId() {
      return this.member?.invitation_id || false;
    },
    canResendInvitation() {
      return this.invitationId && this.isOwner;
    },
    memberName() {
      if (this.invitationId) {
        return `${this.member.email}`;
      }

      const memberName = `${this.member.firstname} ${this.member.lastname}`;

      return this.member?.position ? `${memberName} / ${this.member.position}` : `${memberName}`;
    },
    memberDescription() {
      return this.invitationId
        ? this.$t('workspace.invitation.not_confirmed')
        : `${this.member.email}`;
    },
    isOwner() {
      return this.workspace?.is_owner;
    },
  },
  watch: {
    member: {
      deep: true,
      handler: function (val) {
        this.memberSettings = Object.assign({}, val);
      },
    },
  },
  mounted() {
    this.memberSettings = Object.assign({}, this.member);
  },
  methods: {
    getMemberTitle(member) {
      return member?.firstname && member?.lastname
        ? `${member.firstname} ${member.lastname}`
        : `${member.email}`;
    },
    onResendInvitation() {
      this.resendingInvitation = true;

      resendMemberInvitation(this.invitationId)
        .then(() => {
          this.$notification.success(this.$t('workspace.invitation.resend.ok'));
        })
        .catch(() => {
          this.$notification.error(this.$t('workspace.invitation.resend.failed'));
        })
        .finally(() => {
          this.resendingInvitation = false;
        });
    },
    onUpdateMemberSettings() {
      this.submitted = true;

      if (this.invitationId) {
        updateWorkspaceMemberInvitationSettings(
          this.invitationId,
          Object.assign({}, this.memberSettings, {
            all_contracts: this.memberSettings.all_contracts ? 1 : 0,
            all_contacts: this.memberSettings.all_contacts ? 1 : 0,
          }),
        )
          .then(() => {
            this.$notification.success(this.$t('workspace.team.role.saved'));
          })
          .catch(() => {
            this.$notification.error(this.$t('workspace.team.role.error'));
          })
          .finally(() => {
            this.$emit('reload-workspace');
            this.submitted = false;
            this.showEditWorkspaceMemberForm = false;
          });
      } else {
        updateWorkspaceMemberSettings(
          this.$route.params.workspace_id,
          this.memberSettings?.id,
          Object.assign({}, this.memberSettings, {
            all_contracts: this.memberSettings.all_contracts ? 1 : 0,
            all_contacts: this.memberSettings.all_contacts ? 1 : 0,
          }),
        )
          .then(() => {
            this.$notification.success(this.$t('workspace.team.role.saved'));
          })
          .catch(() => {
            this.$notification.error(this.$t('workspace.team.role.error'));
          })
          .finally(() => {
            this.$emit('reload-workspace');
            this.submitted = false;
            this.showEditWorkspaceMemberForm = false;
          });
      }
    },
    onDeleteMemberFromWorkspace() {
      this.submitted = true;

      if (this.invitationId) {
        deleteWorkspaceMemberInvitation(this.invitationId)
          .then(() => {
            this.$notification.success(this.$t('workspace.invitation.deleted'));
          })
          .catch(() => {
            this.$notification.error(this.$t('workspace.team.delete.error'));
          })
          .finally(() => {
            this.$emit('reload-workspace');
            this.submitted = false;
          });
      } else {
        deleteWorkspaceMember(this.$route.params.workspace_id, this.memberSettings?.id)
          .then(() => {
            this.$notification.success(this.$t('workspace.team.delete.ok'));
          })
          .catch(() => {
            this.$notification.error(this.$t('workspace.team.delete.error'));
          })
          .finally(() => {
            this.$emit('reload-workspace');
            this.submitted = false;
          });
      }
    },
    onReloadWorkspace() {
      this.$emit('reload-workspace');
    },
  },
};
</script>
<style lang="scss" scoped>
.workspace-member__table--row {
  height: 5rem;
  border: 1px solid #ced4da;
  border-left: none;
  border-top: none;
  border-right: none;
}

.workspace-member-email__overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50%;
}
</style>
