<template>
  <Data
    :showMenu="false"
    :shadow="false"
    :panelLoad="false"
    :contractName="true"
    :showBackNavigation="false"
    :disableMenu="true"
    :closeAndSave="false"
  >
    <template v-slot:title>
      <div class="template-title">
        {{ isReplace ? $t('contract.document_replacement') : $t('contract.document_insertion') }}
      </div>
    </template>

    <div class="create-template__content">
      <div class="template-wrap">
        <DocumentUploader
          :workspaceId="$route.params.workspace_id"
          :contractId="$route.params.contract_id"
        />
      </div>
    </div>
  </Data>
</template>

<script>
import { defineComponent } from 'vue';
import DocumentUploader from '@contract/components/DocumentUploader';

export default defineComponent({
  name: 'createUpload',
  components: {
    DocumentUploader,
  },
  computed: {
    isReplace() {
      return 'true' === `${this.$route.query?.replace}` || false;
    },
  },
});
</script>

<style lang="scss">
    .storage-upload__item > button{
        border: none;
        background: transparent;
        width: 100%;
        display: block;
        cursor: pointer;
        height: 90px;
        line-height: 90px;
    }

    .file-upload{
        padding: 0 45px 20px 45px;
        width: 100%;
        display: block;
        @include clearfix;
    }

    .file-upload__btn{
        border: none;
        background: transparent;
        font-weight: 700;
        font-size: 16px;
        margin: 0;
        padding: 0 8px;
        cursor: pointer;
    }

    .file-upload__content{
        padding-top: 20px;
        border-top: 1px solid #D4DFF0;
    }

    .file-upload__name{
        float: left;
        vertical-align: middle;
        font-size: 16px;

        @include md{
            float: none;
            width: 100%;
            text-align: center;
        }
    }

    .file-upload__size{
        float: left;
        padding-left: 10px;
        vertical-align: middle;
        font-size: 16px;

        @include md{
            display: block;
            padding: 10px 0;
            float: none;
            width: 100%;
            text-align: center;

            span{
                display: none !important;
            }
        }
    }

    .file-upload__action{
        font-size: 16px;
        float: right;
        vertical-align: middle;

        @include md{
            float: none;
            width: 100%;
            text-align: center;
        }
    }

    .template-wrap{
        position:relative;
    }
    .template-wrap .file-uploads{
        display: block;
        text-align: center;
    }
    .create-template__content{
        position:relative;
        max-width: 770px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        @include md{
            margin-top: 0px;
            text-align: center;

            .content{
                text-align: center;
            }
        }
    }
    .template-title{
        width: 290px;
        margin-left: auto;
        margin-right: auto;
        font-size: 25px;
        text-align: center;
    }

    .upload-table{
        width: 100%;
        margin: 45px 0;
        border-collapse: collapse;
        font-size: 16px;
        text-align: left;

        th, td{
            text-align: left;
            padding: 10px;
        }
    }

    .upload-table__drop{
        border: 2px dashed #cdcdcd;
        padding: 20px;
        margin: 20px 0;
    }

    .cards{
        margin-left: -10px;
        margin-right: -10px;
    }

    .cards__item{
        width: 50%;
        float: left;
        margin-top: 20px;
        padding: 0 10px;

        @include sm{
            width: 100%;
        }
    }



    .storage-upload{
        text-align: center;
        margin-bottom: 50px;
        @include md{
            width: 100%;
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
        }
        h2{
            text-align: center;
            @include md{
                text-align: left;
            }
        }
    }

    .storage-upload__items{
        display: inline-block;
        background: #FFFFFF;
        box-shadow: 0 7px 24px 0 rgba(142,151,164,0.15);
        border-radius: 6px;
        @include clearfix;
        max-width: 640px;
        width: 100%;
        margin-top: 30px;
        position: relative;
        @include md{
            box-shadow: none;
            background: transparent;
            max-width: 500px;
            margin-top: 10px;
        }
    }

    .storage-upload__item{
        position: relative;
        box-sizing: border-box;
        width: 50%;
        float: left;
        height: 90px;
        line-height: 90px;
        @include clearfix;


        @include md{
            height: 60px;
            line-height: 60px;
        }
    }

    .storage-upload__item--auto{
        height: auto;
        line-height: 1;
    }

    .storage-upload__item--full{
        width: 100%;
    }

    .storage-upload__item.disabled:after{
        content: '';
        display: block;
        position: absolute;
        z-index: 3;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255, 0.4);
    }

    .storage-upload__item--bottom{
        border-bottom: 1px solid #D4DFF0;
        @include md{
            border:none;
        }
    }

    .storage-upload__item--left{
        border-left: 1px solid #D4DFF0;
        @include md{
            border:none;
        }
    }

    .storage-upload__item__container{
        height: 90px;
        line-height: 90px;
        @include clearfix;
    }

    .storage-upload__ico{
        float: left;
        display: inline-block;
        padding-left: 42px;
        line-height: 90px;

        svg{
            vertical-align: middle;
        }
        @include md{
            line-height: 80px;
            padding-left: 20px;
        }
    }

    .storage-upload__name{
        float: left;
        display: inline-block;
        font-size: 19px;
        padding-left: 15px;
        @include md{
            font-weight: 300;
            font-size: 15px;
        }
    }

    .storage-upload__desc{
        position: absolute;
        top: 20px;
        font-size: 12px;
    }

    .storage-upload__item--top-layout{
        @include md{
            width: 100%;
            border: 1px solid #EDF4FE;
            margin-bottom: 0;
            background: white;
            border-radius: 6px;
        }

    }
    .storage-upload__item--bottom-layout{
        @include md{
            width: 100%;
            border: 1px solid #EDF4FE;
            margin-bottom: 10px;
            background: white;
            border-radius: 6px;
            margin-top: 70px;
        }
    }

    .storage-upload__item--right-layout{
        @include md{
            width: 50%;
            margin-bottom: 10px;
            padding-left: 0;
            // position: absolute;
            right: 0px;
            top: 0;
        }
    }

    .storage-upload__item--left-layout{
        @include md{
            width: 50%;
            margin-bottom: 10px;
            padding-right: 0;
            // position: absolute;
            left: 0px;
            top: 0;
        }
    }

    .storage-upload__item-wrap{

        @include md{
            border: 1px solid #EDF4FE;
            background: white;
            height: 80px;
            line-height: 60px;
            border-radius: 6px;
        }
    }

</style>
