import { hostname } from '@/services/helpers/variables.js';

export const ExternalDriveService = {
  onedriveAuthStart,
  onedriveAuthFinish,
};

const onedriveAuth = (queryString) => {
  const token = localStorage.getItem('token');
  location.replace(`${hostname}/api/v1/onedrive/auth${queryString}&access_token=${token}`);
};

function onedriveAuthStart(workspaceId) {
  onedriveAuth(`?state=${workspaceId}`);
}

function onedriveAuthFinish(queryString) {
  onedriveAuth(queryString);
}
