<template lang="html">
    <div class="radio-wrap" v-bind:class="{'disabled': disabled}">
        <label class="radio-label">
            <input
                class="option-input radio radio__data"
                :disabled="disabled" type="radio"
                :value="labelValue"
                :name="name"
                v-model="radioButtonValue">
            <span class="radio__label">{{ label }}</span>
        </label>

    </div>
</template>


<script>
    export default {
        name: 'Radio',
        props: ['label', 'checked', 'disabled', 'value', 'labelValue', 'name', 'id'],
        data() {
            return {
              itemId: '',
            };
        },
        computed: {
            radioButtonValue: {
                get: function() {
                    return this.value
                },
                set: function() {
                    // Communicate the change to parent component so that selectedValue can be updated
                  this.$emit("change", this.labelValue);
                  this.$emit('updateId', this.id);
                }
            }
        },
    }
</script>


<style lang="scss">
    .radio__data.option-input{
        top: 4.5px;
    }

    .radio__label{
        font-size: 16px;
        color: #000000;
        font-weight: normal;
        cursor:pointer;
        transition: 0.2s;
        &:hover{
            color:#5E239E;
            transition: 0.2s;
        }
        svg {
            vertical-align: text-top;
        }
    }

    .radio-wrap{
        text-align: left;
        max-width: 273px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
    }
    .normal.radio-wrap{
        width: 100%;
        max-width: 100%;
    }

</style>
