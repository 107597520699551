import Vue from 'vue';
import Logo from '@/components/partials/Logo';
import LogoSymbol from '@/components/partials/LogoSymbol';
import Input from '@/components/Input';
import InputNumber from '@/components/InputNumber';
import Select from '@/components/Select';
import Checkbox from '@/components/Checkbox';
import Radio from '@/components/Radio';
import Textarea from '@/components/Textarea';
import Button from '@/components/Button';
import Data from '@/components/Data';
import vSelect from 'vue-select';
import SHelp from '@/common/components/SHelp';
import SBtn from '@/common/components/SBtn';

Vue.component('Data', Data);
Vue.component('Logo', Logo);
Vue.component('LogoSymbol', LogoSymbol);
Vue.component('Input', Input);
Vue.component('InputNumber', InputNumber);
Vue.component('Select', Select);
Vue.component('Checkbox', Checkbox);
Vue.component('Radio', Radio);
Vue.component('Textarea', Textarea);
Vue.component('Button', Button);
Vue.component('v-select', vSelect);
Vue.component('s-btn', SBtn);
Vue.component('s-help', SHelp);
