<template>
  <v-row align="center" justify="center" no-gutters>
    <v-col class="col-12">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="formattedDate"
            :label="label"
            :placeholder="placeholder"
            :readonly="readonly"
            :disabled="changesDisabled"
            :background-color="backgroundColor"
            :error="error"
            append-icon="mdi-calendar"
            hide-details
            outlined
            dense
            v-bind="attrs"
            v-on="on"
            style="box-sizing: border-box; border-radius: 4px; background-color: #ffffff"
          ></v-text-field>
        </template>

        <v-date-picker
          v-model="date"
          :active-picker.sync="activePicker"
          :landscape="landscape"
          :locale="locale"
          :max="
            new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10)
          "
          min="1900-01-01"
          no-title
          @input="menu = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import { i18n } from '@/plugins/i18n';
import { getFormattedDateByLocale } from '@/common/reusable/dateFunctions';

export default {
  name: 'SBirthDayPicker',
  props: {
    value: [String, Number, Date],
    label: {
      type: String,
      default: i18n.t('contract.date'),
    },
    placeholder: {
      type: String,
      default: '',
    },
    dateOptions: {
      type: Object,
      default: () => {
        return {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        };
      },
    },
    backgroundColor: {
      type: String,
      default: 'transparent',
    },
    changesDisabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activePicker: 'YEAR',
      menu: false,
    };
  },
  computed: {
    date: {
      get() {
        return this.value ? new Date(this.value).toISOString().substr(0, 10) : null;
      },
      set(value) {
        this.$emit('input', new Date(value).toISOString().substr(0, 10));
      },
    },
    formattedDate() {
      if (!this.date) {
        return null;
      }

      return getFormattedDateByLocale({
        date: this.date,
        options: this.dateOptions,
        locale: this.locale,
      });
    },
    hasAdditionalButtonsContent() {
      return !!this.$slots.additionalButtons;
    },
    landscape() {
      return (
        this.$vuetify.breakpoint.mobile &&
        this.$vuetify.breakpoint.width > this.$vuetify.breakpoint.height
      );
    },
    locale() {
      return i18n.locale;
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'));
    },
  },
  mounted() {
    if (this.value) {
      this.date = new Date(
        this.value.includes('00:00:00') ? this.value?.replace(' 00:00:00', '') : this.value,
      )
        .toISOString()
        .substr(0, 10);
    }
  },
};
</script>

<style lang="scss" scoped>
.v-menu__content {
  z-index: 10002 !important;
}
</style>
