<template lang="html">
  <Data :loader="!loaded" :showMenu="false" :closeAndSave="true" @close="closeAndSave">
    <div class="content-box-wrap">
      <h1>{{ $t('contract.contract_content')}}</h1>

      <ContractTabs
        :contracts="contract.attachments || []"
        :selectedContractId="contract.id"
        :workspace_id="contract.workspace_id"
        :mainContractId="contract.main_contract_id || contract.id"
      />

      <div class="content-box" :style="{marginTop: contract.attachments.length > 0 ? '0' : null}">
        <div class="content-box__data content-box__data--full content-box__data--padded">
          <div class="content-box__data__ico">
            <svg width="43px" height="44px" viewBox="0 0 43 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="SYN001---DSKTP---Návrh---text-editace---0" transform="translate(-717.000000, -341.000000)" fill-rule="nonzero"><g id="Group-5" transform="translate(738.500000, 363.000000) scale(-1, 1) translate(-738.500000, -363.000000) translate(717.000000, 341.000000)"><path d="M36.419794,43.9628739 C42.1814622,43.9628739 42.0513589,40.2075984 42.0513589,32.7598619 C42.0513589,27.7947042 41.491304,16.8747502 40.3711942,0 L1,41.6410184 C20.7720838,43.188922 32.5786818,43.9628739 36.419794,43.9628739 Z" id="Path-16" fill="#000000" opacity="0.08"></path><path d="M0,41 C20.9161164,41 32.3298182,41 34.2411054,41 C39.8213061,41 40,36.3837602 40,28.5826408 C40,23.3818946 40,13.8543477 40,0 L0,41 Z" id="Path-16-Copy" fill="#FFFFFF"></path></g></g></g></svg>
          </div>

          <div class="contract-template-content contract-template-content--white">
            <quill-editor
              ref="myQuillEditor"
              v-model="content"
              :options="editorOption"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)">
            </quill-editor>
          </div>

          <div class="pt-5 text-center">
            <v-btn
              color="primary"
              :loading="loading"
              :block="$vuetify.breakpoint.mobile"
              min-width="18rem"
              x-large
              @click="saveCustomContract()"
            >
              {{ $t('contract.save_text')}}
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </Data>
</template>


<script>
import { mapGetters } from 'vuex';
import { ContractService } from '@/services/ContractService.js';
import { WorkflowHelper } from '@/helpers/WorkflowHelper.js';

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import ContractTabs from '@/components/ContractTabs.vue';

export default {
  name: 'CreateOwn',
  components: {
    quillEditor,
    editorOption: {
      toolbarOptions: ['bold', 'italic', 'underline', 'strike']
    },
    ContractTabs,
  },
  data() {
    return {
      loader: false,
      loaded: false,
      loading: false,
      editorOption: {},
      content: this.$t('contract.wysiwyg.placeholder'),
    }
  },
  created() {
    this.fetchData();
  },
  computed: {
    ...mapGetters([
      'contract',
    ]),
  },
  methods: {
    fetchData() {
      ContractService.detail(this.$route.params.contract_id, this.$route.params.workspace_id).then(response => {
        this.$store.commit('setContract', response);
        if (WorkflowHelper.redirectIfContractIsNotEditableInWizard(response)) {
          return;
        }

        if( response.answers ) {
          this.data = JSON.parse(response.answers);
        }

        this.content = response.template.content;
        this.loaded = true;

      }, error => {
        this.loaded = true;
        this.$notification.error(this.$t('contract.not_loaded'));
      });
    },

    onEditorBlur(quill) {
    },

    onEditorFocus(quill) {
    },

    onEditorReady(quill) {
    },

    onEditorChange({ quill, html, text }) {
      this.content = html
    },

    saveCustomContract() {
      this.loading = true;

      const data = {
        custom_html_template: this.content,
        type: 'wysiwyg'
      };

      ContractService.update(this.$route.params.contract_id, data).then(response => {
        this.$notification.success(this.$t('contract.save.ok'));
        this.$router.push({name: 'createContent', params: { workspace_id: this.$route.params.workspace_id, contract_id: this.$route.params.contract_id }})
        this.loading = false;

      }, error => {
        this.$notification.error(this.$t('contract.save.failed'));
        this.loading = false;
      })
    },

    closeAndSave() {
      this.loading = true;

      const data = {
        custom_html_template: this.content,
        type: 'wysiwyg'
      };

      ContractService.update(this.$route.params.contract_id, data).then(response => {
        this.$notification.success(this.$t('contract.save.ok'));
        this.$router.push({
          name: 'overviewContractsActive',
          params: {
            workspace_id: this.contract.workspace_id,
          },
        });
        this.loading = false;

      }, error => {
        this.$notification.error(this.$t('contract.save.failed'));
        this.loading = false;
      })
    },
  }
}
</script>


<style lang="scss">
.quill-editor,
.quill-code {
  height: auto;
}

.ql-editor{
  min-height: 410px;

  h1, h2 {
    text-align: center;
    line-height: 120%;
    font-size: 23px;
    color: #000000;
  }

  h1 + p,
  h2 + p{
    margin-top: 10px;
  }

  p{
    padding-bottom: 15px;
  }
}

.contract-template-content--white {
  @include md{
    background-color: #fff;
  }
}
</style>
