<template>
  <section class="mt-2 mb-6">
    <div class="mb-5">{{ $t('workspace.settings.signature_types') }}</div>

    <v-list class="signature-types">
      <drop-list :items="sortedSignTypes" @reorder="$event.apply(sortedSignTypes)">
        <template v-slot:item="{ item }">
          <drag :key="item.order" :disabled="item.disabled || isLastAvailableSignType">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>
                  {{
                    item.disabled || isLastAvailableSignType ? '' : 'mdi-reorder-horizontal'
                  }}</v-icon
                >
              </v-list-item-icon>

              <v-list-item-content :class="`${item.disabled ? 'signature-types__disabled' : ''}`">
                {{ setTabPlaceholder(item.identifier) }}
              </v-list-item-content>

              <v-btn
                :color="`${item.disabled ? '#b4c1d6' : ''}`"
                icon
                @click="toggleSignType(item)"
              >
                <v-icon> {{ item.disabled ? 'mdi-eye-off-outline' : 'mdi-eye-outline' }} </v-icon>
              </v-btn>
            </v-list-item>
          </drag>
        </template>
      </drop-list>
    </v-list>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { DropList, Drag } from 'vue-easy-dnd';

export default defineComponent({
  name: 'WorkspaceSignatureTypeSetting',
  components: {
    DropList,
    Drag,
  },
  props: {
    value: {
      type: Array,
    },
  },
  data() {
    return {
      defaultSignTypes: [
        {
          identifier: 'text',
        },
        {
          identifier: 'signature',
        },
        {
          identifier: 'image',
        },
      ],
      signTypes: [],
    };
  },
  mounted() {
    this.signTypes = this.value.map((availableSignType) => ({
      ...availableSignType,
      disabled: false,
    }));

    for (let i = 0; i < this.defaultSignTypes.length; i++) {
      if (
        !this.value.find(
          (availableSignType) =>
            availableSignType.identifier === this.defaultSignTypes[i].identifier,
        )
      ) {
        this.signTypes.push(
          Object.assign(
            {},
            {
              identifier: this.defaultSignTypes[i].identifier,
              order: this.signTypes.length,
              disabled: true,
            },
          ),
        );
      }
    }
  },
  computed: {
    isLastAvailableSignType() {
      return 1 === this.signTypes.filter((signType) => !signType.disabled).length;
    },
    sortedSignTypes() {
      return this.signTypes.sort((a, b) => (a.disabled === b.disabled ? 0 : a.disabled ? 1 : -1));
    },
  },
  watch: {
    signTypes: {
      deep: true,
      handler(changedSignTypes) {
        let filteredAndReorderedSignTypes = changedSignTypes
          .filter((signType) => !signType.disabled)
          .map((signType, index) => ({ ...signType, order: index }));

        this.$emit('input', filteredAndReorderedSignTypes);
      },
    },
  },
  methods: {
    toggleSignType(signType) {
      if (this.isLastAvailableSignType && !signType.disabled) {
        return;
      }

      signType.disabled = !signType.disabled;
      this.signTypes.find((type) => type.identifier === signType.identifier).disabled =
        signType.disabled;
    },
    setTabPlaceholder(type) {
      const signatureTabsTranslations = {
        text: this.$t('signature.text'),
        signature: this.$t('signature.stylus'),
        image: this.$t('signature.image.add'),
      };

      return signatureTabsTranslations[type];
    },
  },
});
</script>

<style lang="scss" scoped>
.signature-types {
  border: 1px solid #808080;
  border-radius: 8px;

  .disabled {
    color: #b4c1d6;
  }
}
</style>
