<template>
  <v-card v-if="$vuetify.breakpoint.mobile" class="my-5 pa-5" elevation="0">
    <v-row align="center" justify="start" no-gutters>
      <v-col class="col-12 pb-3">
        <span class="invitation__search--label">
          {{ $t('workspace.invitation.send2') }}
        </span>

        <v-text-field
          type="text"
          name="email"
          v-model="invitation.email"
          :label="$t('general.email_address')"
          :error="$v.invitation.email.$error"
          prepend-inner-icon="mdi-magnify"
          class="invitation__select--input--border"
          hide-details
          clearable
          solo
          flat
        ></v-text-field>
      </v-col>

      <v-col class="col-12 pb-5">
        <span class="invitation__search--label">
          {{ $t('workspace.role') }}
        </span>

        <SPositionSearch
          :type="'position'"
          :positions="filteredPositions.length ? filteredPositions : membersJobPositions"
          :label="invitation.position || $t('workspace.role')"
          :hint="`${$t('workspace.role.help', { link: $t('links.variableRole') })}`"
          :isDynamicList="false"
          :currentId="1"
          :openSearchId="openSearchId"
          @toggleSearch="toggleOpenSearchId($event)"
          @onSignerSelect="selectPosition($event)"
          @onSearchTermChange="filterSearch($event)"
        />
      </v-col>

      <v-col class="col-12 pb-5">
        <v-btn
          color="primary"
          class="invitation__submit--btn"
          block
          :loading="isSubmitted"
          @click="onConfirmationAction"
        >
          {{ $t('workspace.invitation.add_member') }}
        </v-btn>
      </v-col>

      <v-col class="col-12">
        <v-checkbox
          v-model="invitation.all_contracts"
          color="success"
          :label="$t('workspace.team.role.every_doc')"
          hide-details
        ></v-checkbox>

        <v-checkbox
          v-model="showAllContacts"
          color="success"
          :label="$t('workspace.team.role.only_their_contacts')"
          hide-details
        ></v-checkbox>
      </v-col>

      <v-col class="col-12 mt-5">
        <v-divider></v-divider>
      </v-col>

      <v-col class="col-12 pb-3">
        <v-radio-group v-model="invitation.rights" hide-details>
          <v-radio
            :label="$t('workspace.team.role.signer')"
            value="signer"
            name="workspace-person"
            color="success"
            class="mb-5"
          ></v-radio>

          <v-radio
            :label="$t('workspace.team.role.assistant')"
            value="assistant"
            name="workspace-person"
            color="success"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
  </v-card>

  <v-card v-else class="my-5 pa-5" elevation="0">
    <v-row align="center" justify="start" no-gutters style="max-width: 80%">
      <v-col>
        <span class="invitation__search--label">
          {{ $t('workspace.invitation.send2') }}
        </span>

        <v-row class="invitation__form" align="center" no-gutters>
          <v-col class="col-6">
            <v-text-field
              type="text"
              name="email"
              v-model="invitation.email"
              :label="$t('general.email_address')"
              :error="$v.invitation.email.$error"
              prepend-inner-icon="mdi-magnify"
              class="invitation__select--input"
              hide-details
              clearable
              solo
              flat
            ></v-text-field>
          </v-col>

          <v-col class="col-6">
            <SPositionSearch
              :type="'position'"
              :positions="filteredPositions.length ? filteredPositions : membersJobPositions"
              :label="invitation.position || $t('workspace.role')"
              :hint="`${$t('workspace.role.help', { link: $t('links.variableRole') })}`"
              :isDynamicList="false"
              :currentId="1"
              :openSearchId="openSearchId"
              @toggleSearch="toggleOpenSearchId($event)"
              @onSignerSelect="selectPosition($event)"
              @onSearchTermChange="filterSearch($event)"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-btn
        color="primary"
        class="mt-7 ml-3 invitation__submit--btn"
        :loading="isSubmitted"
        @click="onConfirmationAction"
      >
        {{ $t('workspace.invitation.add_member') }}
      </v-btn>
    </v-row>

    <v-row class="mt-4" align="center" justify="start" no-gutters>
      <v-col class="col-auto">
        <v-checkbox
          v-model="invitation.all_contracts"
          color="success"
          :label="$t('workspace.team.role.every_doc')"
          hide-details
        ></v-checkbox>

        <v-checkbox
          v-model="showAllContacts"
          color="success"
          :label="$t('workspace.team.role.only_their_contacts')"
          hide-details
        ></v-checkbox>
      </v-col>

      <v-col class="col-auto pt-5 mx-12">
        <v-divider vertical style="height: 60px"></v-divider>
      </v-col>

      <v-col class="col-auto">
        <v-radio-group v-model="invitation.rights" class="pa-0 pt-1" hide-details>
          <v-radio
            :label="$t('workspace.team.role.signer')"
            value="signer"
            name="workspace-person"
            color="success"
            class="mb-5"
          ></v-radio>

          <v-radio
            :label="$t('workspace.team.role.assistant')"
            value="assistant"
            name="workspace-person"
            color="success"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { email, required } from 'vuelidate/lib/validators';
import SPositionSearch from '@/common/components/SPositionSearch';

export default {
  name: 'InvitationForm',
  components: {
    SPositionSearch,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isSubmitted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openSearchId: null,
      filteredPositions: [],
    };
  },
  validations: {
    invitation: {
      email: {
        required,
        email,
        minLength: 5,
      },
    },
  },
  computed: {
    ...mapGetters({
      membersJobPositions: 'workspace/membersJobPositions',
    }),
    invitation: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    showAllContacts: {
      get() {
        return !this.invitation.all_contacts;
      },
      set(value) {
        this.invitation.all_contacts = !value;
      },
    },
  },
  created() {
    this.fetchWorkspaceMembersPositions(this.$route.params.workspace_id);
  },
  methods: {
    ...mapActions({
      fetchWorkspaceMembersPositions: 'workspace/fetchWorkspaceMembersPositions',
    }),
    onConfirmationAction() {
      this.$v.invitation.$touch();

      if (this.$v.invitation.$error || this.isSubmitted) {
        return;
      }

      this.$emit('success-changes');
      this.$v.$reset();
    },
    toggleOpenSearchId(currentId) {
      this.openSearchId = currentId;
    },
    filterSearch(query) {
      this.filteredPositions = this.membersJobPositions.filter((position) =>
        position.name.toLowerCase().includes(query.toLowerCase()),
      );
    },
    selectPosition(position) {
      this.invitation.position = position.name ? position.name : position;
    },
  },
};
</script>

<style lang="scss" scoped>
.invitation__form {
  border: 1px solid #ced4da;
  border-radius: 5px;
  min-width: 425px;
}

.invitation {
  background: none;

  @media only screen and (min-width: 960px) {
    background: #ffffff;
    padding: 0.5rem 1rem 1.5rem 1rem;
  }

  &__search--label {
    display: inline-block;
    text-transform: uppercase;
    color: #868e96;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
    margin-bottom: 9px;
  }

  &__select--input {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 15px;

    &--border {
      border: 1px solid #ced4da;
      border-radius: 5px;
    }
  }

  &__submit--btn {
    height: 3rem !important;
  }
}
</style>
