import { environment } from '@/config/environment';

export default function GoogleDrive() {
  this.isDevelopment = environment.isDevelopment(process.env.NODE_ENV);

  this.production = {
    clientId: '837969300817-lu6ohd94c4oj22snuq7m5666lbi9g9fh.apps.googleusercontent.com',
    devKey: 'AIzaSyDWFBM9dQJgG4-q_MgHkmFRcL4O216wXrQ',
  };

  this.development = {
    clientId: '208954475859-1jh11dpod3cruh08uq4ng9o5386evrei.apps.googleusercontent.com',
    devKey: 'AIzaSyAQbiX3pOi40jyxETeTGzTaEpIycJ4S9_U',
  };

  this.getClientId = () => {
    return this.isDevelopment ? this.development.clientId : this.production.clientId;
  };

  this.getDeveloperKey = () => {
    return this.isDevelopment ? this.development.devKey : this.production.devKey;
  };
}
