<template>
  <div
    ref="outerBox"
    v-click-outside="onClickOutside"
    :data-id="signaturePlaceholder.uniqueSignId"
    :class="classes"
    :style="styles"
  >
    <vue-draggable-resizable
      class="signature-placeholder__wrapper"
      :w="'auto'"
      :h="'auto'"
      :handles="['br']"
      :draggable="false"
      :lockAspectRatio="true"
      :prevent-deactivation="false"
      :resizable="true"
      @resizestop="onStopResizing"
      @resizing="updateWidth"
    >
      <div v-if="isHeaderVisible" class="sign-placement__header" @click="focused = true">
        <span class="sign-placement__date initial-opacity-0" v-fit-text="[5, 12]">
          {{ $t('signature.header.placeholder') }}
        </span>
      </div>

      <div
        class="sign-placement__inner"
        :class="{
          'signature-placeholder__inner--movable':
            contract.type === 'doc' || contract.type === 'wysiwyg',
        }"
        @touchstart="onStart($event)"
        @mousedown="onStart($event)"
        @touchend="onStop($event)"
        @mouseup="onStop($event)"
        @click="focused = true"
      >
        <div class="sign-placement__title">
          <div class="signature-img signature-img--empty"></div>
        </div>
        <span class="sign-placement__name" v-fit-text="[10, 20]">
          {{ placeholderTitle }}
        </span>
      </div>

      <div class="sign-placement__dots"></div>

      <div v-if="isFooterVisible" class="sign-placement__footer" @click="focused = true">
        <span class="sign-placement__date initial-opacity-0" v-fit-text="[5, 12]">
          {{ $t('signature.footer.placeholder') }}
        </span>
      </div>

      <div v-if="focused" class="signature-placeholders__wrapper">
        <div class="signature-placeholders__wrapper-child">
          <div
            class="signature-placeholder__noHeader"
            :class="`js-signature-noHeader-${legacyRoleType}`"
          >
            <v-btn icon elevation="3" @click="onToggleHeader">
              <v-icon color="#8E97A4">mdi-calendar-clock</v-icon>
            </v-btn>
          </div>

          <div
            class="signature-placeholder__duplicate ml-3"
            :class="`js-signature-duplicate-${legacyRoleType}`"
          >
            <v-btn icon elevation="3" @click="onCopySignature">
              <v-icon color="#8E97A4">mdi-content-copy</v-icon>
            </v-btn>
          </div>
        </div>

        <div class="signature-placeholder__resize" :class="`js-signature-resize-${legacyRoleType}`">
          <v-menu :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon color="#ffffff">mdi-cog-outline</v-icon>
              </v-btn>
            </template>

            <v-card color="#ffffff" rounded="lg" elevation="0" max-width="300px">
              <v-list>
                <template v-if="canShowSwapButton">
                  <v-list-group v-if="hasMoreSignIdentities" :value="true" no-action append-icon="">
                    <template v-slot:activator>
                      <v-icon color="#b4c1d6" left>mdi-cached</v-icon>
                      <v-list-item-title class="text-wrap">
                        <a class="primary--text font-weight-medium">
                          {{ $t('contract.signatures.swap') }}
                        </a>
                      </v-list-item-title>
                    </template>

                    <v-list-item
                      v-for="identity in filteredSignIdentities"
                      :key="identity.id"
                      class="pl-5"
                      link
                      @click="swapIdentityPositions(identity)"
                    >
                      <v-list-item-title>
                        <v-icon>mdi-circle-small</v-icon>
                        {{ `${identity.firstname} ${identity.lastname}` }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list-group>

                  <v-list-item v-else link @click="swapIdentityPositions()">
                    <v-icon color="#b4c1d6" left>mdi-cached</v-icon>
                    <v-list-item-title class="text-wrap">
                      <a class="primary--text font-weight-medium">
                        {{ $t('contract.signatures.swap') }}
                      </a>
                    </v-list-item-title>
                  </v-list-item>
                </template>

                <v-divider
                  v-if="canShowSwapButton"
                  color="#d4dff0"
                  class="ml-4 mr-4 mt-1 mb-1"
                ></v-divider>

                <v-list-item @click="onToggleFooter" link>
                  <v-icon color="#8E97A4" left>mdi-page-layout-footer</v-icon>
                  <v-list-item-title>
                    <a class="primary--text font-weight-medium">
                      {{
                        $t(`signature.footer.${signaturePlaceholder.show_footer ? 'hide' : 'show'}`)
                      }}
                    </a>
                  </v-list-item-title>
                </v-list-item>

                <v-divider color="#d4dff0" class="ml-4 mr-4 mt-1 mb-1"></v-divider>

                <v-list-item @click="onDeleteSignature" link>
                  <v-icon color="#b4c1d6" left>mdi-trash-can-outline</v-icon>
                  <v-list-item-title>
                    <a class="text--secondary font-weight-medium">
                      {{ $t('contract.signatures.delete_signature_field') }}
                    </a>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </div>
      </div>
    </vue-draggable-resizable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VueDraggableResizable from 'vue-draggable-resizable';
import { ContractHelper } from '@/helpers/ContractHelper';

export default {
  name: 'SignaturePlaceholder',
  components: {
    VueDraggableResizable,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isDefaultDisabledHeader: {
      type: Boolean,
      default: false,
    },
    isDefaultDisabledFooter: {
      type: Boolean,
      default: true,
    },
    updatedPlaceholderWidth: {
      type: Number,
      default: null,
    },
    workspacePlaceholderWidth: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      activeMove: false,
      focused: false,
      updatedWidth: null,
    };
  },
  computed: {
    ...mapGetters({
      contract: 'contract',
      signIdentities: 'contractSignIdentities/signIdentities',
    }),
    signaturePlaceholder: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', Object.assign({}, value));
      },
    },
    signatureSignIdentity() {
      return this.signaturePlaceholder?.signIdentity || null;
    },
    isInconsistentPlaceholder() {
      return !ContractHelper.isSigningContractRole(this.signatureSignIdentity);
    },
    classes() {
      return `${
        this.isInconsistentPlaceholder ? 'editor-signature-inconsistent' : ''
      } sign-placement ${
        !this.signaturePlaceholder.show_header ? 'sign-placement__no_header' : ''
      } overflow-visible signature-${this.legacyRoleType} js-${this.legacyRoleType}-signature ${
        this.focused ? 'resizing-signature' : ''
      }`;
    },
    styles() {
      return {
        left: `${this.signaturePlaceholder.left}%`,
        top: `${this.signaturePlaceholder.top}%`,
        width: `${
          this.updatedWidth ||
          this.signaturePlaceholder.relativeWidth ||
          this.updatedPlaceholderWidth ||
          this.workspacePlaceholderWidth
        }%`,
        'z-index': `${10 * this.activeMove || this.focused ? 1000 : 1}`,
      };
    },
    isHeaderVisible() {
      return this.signaturePlaceholder.show_header;
    },
    isFooterVisible() {
      return this.signaturePlaceholder.show_footer;
    },
    legacyRoleType() {
      return `${this.signaturePlaceholder.is_proposer_sign ? 'owner' : 'counter'}`;
    },
    canShowSwapButton() {
      return this.signIdentities?.length > 1;
    },
    hasMoreSignIdentities() {
      return this.signIdentities?.length > 2;
    },
    filteredSignIdentities() {
      return this.signIdentities.filter((s) => {
        return s.id !== this.signatureSignIdentity?.id && !s?.variable_position;
      });
    },
    placeholderTitle() {
      if (this.signatureSignIdentity?.variable_position) {
        return `${this.signatureSignIdentity.variable_position}`;
      }

      return `${this.signaturePlaceholder?.firstname || ''} ${
        this.signaturePlaceholder?.lastname || ''
      }`;
    },
  },
  watch: {
    focused(value) {
      if (!value) {
        this.$emit('on-changes-done');
      }
    },
    updatedWidth(value) {
      this.$emit('on-resize-placeholder', value);
    },
  },
  created() {
    const placeholderAnchor = this.signatureSignIdentity?.positions[0]?.anchor;

    if (placeholderAnchor) {
      Object.assign(this.signaturePlaceholder, { anchor: placeholderAnchor });
    }

    if (!this.signaturePlaceholder?.relativeWidth) {
      Object.assign(this.signaturePlaceholder, { relativeWidth: this.updatedPlaceholderWidth });
    }

    this.updatedWidth = this.signaturePlaceholder.relativeWidth;
  },
  methods: {
    onStart(e) {
      this.activeMove = true;
      this.$emit('setDraggingSignature', e);
    },
    onStop(e) {
      this.activeMove = false;
      this.$emit('stopSignatureDrag', {
        event: e,
        height: this.$refs.outerBox.getBoundingClientRect().height,
      });
    },
    onStopResizing(left, top, width) {
      const imageWrapper = document.querySelector('.showImages');

      if (imageWrapper) {
        this.$emit('setNewWidth', 100 * (width / imageWrapper.clientWidth));
      }

      this.$refs.outerBox.click();
    },
    updateWidth(left, top, width) {
      const imageWrapper = document.querySelector('.showImages');

      this.updatedWidth = 100 * (width / imageWrapper.clientWidth);
    },
    onClickOutside() {
      this.focused = false;
    },
    onCopySignature() {
      this.$emit('on-resize-placeholder', this.updatedWidth);
      this.$emit(
        'placeAnotherSign',
        Object.assign(
          {},
          {
            signature: this.signaturePlaceholder,
            relative_width: this.updatedWidth,
          },
        ),
      );

      this.$nextTick(() => {
        this.focused = false;
      });
    },
    onToggleHeader() {
      if (undefined === typeof this.signaturePlaceholder?.show_header) {
        this.signaturePlaceholder.show_header = !this.isDefaultDisabledHeader;
      }

      this.signaturePlaceholder.show_header = !this.signaturePlaceholder.show_header;

      this.$emit('on-changes-done');
    },
    onToggleFooter() {
      if (undefined === typeof this.signaturePlaceholder?.show_footer) {
        this.signaturePlaceholder.show_footer = !this.isDefaultDisabledFooter;
      }

      this.signaturePlaceholder.show_footer = !this.signaturePlaceholder.show_footer;

      this.$emit('on-changes-done');
    },
    onDeleteSignature() {
      this.$emit('deleteSignature', this.signaturePlaceholder);
    },
    swapIdentityPositions(target = null) {
      if (2 === this.signIdentities?.length) {
        this.$emit('swap-identities', {
          from: this.signIdentities[0].id,
          to: this.signIdentities[1].id,
        });
      } else if (1 !== this.signIdentities?.length) {
        this.$emit('swap-identities', {
          from: this.signatureSignIdentity?.id,
          to: target?.id,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.signature-placeholder__wrapper {
  width: auto !important;
  height: auto !important;
}

.signature-placeholder {
  position: fixed;
  padding: 4px;
  border: 1px solid #b5c1d7;
  border-radius: 6px;
  color: black;
  font-size: 14px;
  background: white;

  &:before {
    content: '';
    display: inline-block;
    padding-bottom: 40%;
  }
}

.signature-placeholder__menu {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
  border: 1px solid #7d8593 !important;
}

.signature-placeholder__menu-item {
  color: #222222;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.03px;
  line-height: 23px;
  text-align: left;

  &--delete {
    color: #7d8593;
    text-align: right;

    &:hover {
      color: #222222;

      .v-icon {
        color: #222222 !important;
      }
    }
  }

  &--footer {
    color: #7d8593;
    text-align: right;

    &:hover {
      color: #222222;

      .v-icon {
        color: #222222 !important;
      }
    }
  }
}

.signature-placeholder__inner {
  display: flex;
  align-items: center;
  padding: 0 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: left;

  &--movable {
    cursor: pointer;
  }
}

.signature-placeholder__signature {
  font-weight: 300;
  font-size: 16px;

  @include sm {
    font-size: 13px;
  }
}

.signature-placeholder__name {
  font-weight: 400;
  font-size: 18px;
  font-style: italic;
  color: gray;

  @include sm {
    font-size: 14px;
  }
}
.signature-placeholders__wrapper {
  position: absolute;
  top: -44px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 1001;

  .signature-placeholder {
    &__move,
    &__resize,
    &__delete,
    &__noHeader,
    &__duplicate {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      margin-right: 6px;
    }

    &__move {
      background: #5e239e;
    }

    &__resize {
      background: #5e239e;
    }

    &__delete {
      background: #ffffff;
    }

    &__duplicate {
      background: #ffffff;
    }

    &__noHeader {
      background: #ffffff;
      color: #f2f6fc;
    }
  }
}

.signature-placeholders__wrapper-child {
  display: flex;
  flex-direction: row;
}

.signature-owner {
  display: block !important;
  position: absolute !important;
}

.signature-counter {
  display: block !important;
  position: absolute !important;
}

.js-signature {
  display: none;
}

.signature-dots {
  position: absolute;
  height: 2px;
  width: 80%;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  border-top: 2px dashed #5e239e;
  bottom: 15%;
}

.signature-placeholder__inner-text-content {
  text-align: center;
  margin: 0 auto;
  color: gray;
}

.overflow-visible {
  overflow: visible;
}

.sign-placement {
  position: absolute;
  width: auto;
  margin-bottom: 7px;

  .sign-placement__header,
  .sign-placement__footer {
    pointer-events: none;
    position: absolute;
    height: 20%;
    padding: 0;
    cursor: pointer;
    background: #f1f6fc;
    background: linear-gradient(90deg, rgba(241, 246, 252, 1) 80%, rgba(255, 255, 255, 0) 100%);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 120%;
    white-space: nowrap;
    z-index: 2;
    span {
      font-weight: 400;
      padding: 0;
      color: #1e1f1f;
    }
  }

  .sign-placement__header {
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .sign-placement__footer {
    width: 100%;
    bottom: 0;
    left: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .sign-placement__inner {
    padding-top: 10%;
  }
}
.sign-placement__no_header {
  .sign-placement__header {
    display: none;
  }
  .sign-placement__inner {
    padding-top: 0;
  }
}
.handle-br {
  position: absolute;
  bottom: -7px;
  right: -7px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  cursor: nwse-resize;
  background: #712cbd;
}
.editor-signature-placeholder.sign-placement {
  border: none;
  background: none;
  .resizable {
    border-radius: 6px;
    border: 1px solid #b5c1d7;
    background: #fffffff2;
  }
}

.resizing-signature {
  z-index: 2;
}

.editor-signature-inconsistent {
  border-color: red;
  border-width: 2px;
}

.sign-placement__link {
  color: #ffffff !important;
  height: 60%;
  width: 80%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 150px;
  max-height: 50px;
}

.show-less .sign-placement__header {
  font-size: calc(100% / 1.8);
}

.show-less .sign-placement__link {
  font-size: 70%;
}

.sign-placement__date {
  width: 100%;
  //opacity: 0;
}

.sign-placement__name {
  width: 100%;
  height: 80%;
  justify-content: center;
  align-items: center;
  display: flex;
  //opacity: 0;
  bottom: 0;
}

.sign-placement--signed {
  background: none !important;
  border: none !important;

  .sign-placement__header {
    background: none;
    border: 0;
  }
  .sign-placement__inner {
    background: none;
    border: 0;
  }
  .sign-placement__dots {
    display: none;
  }
}

.initial-opacity-0 {
  opacity: 0;
}

.placement-has-button {
  background: transparent !important;
  border: none !important;
  .sign-placement__header {
    opacity: 0;
  }
}
</style>
