import { initializeApp } from 'firebase/app';
// import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
// import { getFunctions } from 'firebase/functions';
// import { getMessaging } from 'firebase/messaging';
// import { getPerformance } from 'firebase/performance';
// const { initializeAppCheck, ReCaptchaV3Provider } = require('firebase/app-check');

const firebaseConfig = {
  apiKey: 'AIzaSyAmUG3rri9D9cV6VF8jSEDJ6EKONAZmQWc',
  authDomain: 'signi-master.firebaseapp.com',
  projectId: 'signi-master',
  storageBucket: 'signi-master.appspot.com',
  messagingSenderId: '534468036332',
  appId: '1:534468036332:web:55b33f93eadd4b6c7507c9',
  measurementId: 'G-BYPCPMHTGJ',
};

const app = initializeApp(firebaseConfig);

// /**
//  * Google Analytics
//  *
//  * @type {Analytics}
//  * @docs https://firebase.google.com/docs/analytics/get-started?platform=web
//  */
// export const analytics = getAnalytics(app);

/**
 * Firebase Authentication on Websites
 *
 * @type {Auth}
 * @docs https://firebase.google.com/docs/auth/web/start
 */
export const auth = getAuth(app);

// /**
//  * Firebase Cloud Functions
//  *
//  * @type {Functions}
//  * @docs https://firebase.google.com/docs/functions/callable
//  */
// export const functions = getFunctions(app);

// /**
//  * Firebase Cloud Messaging
//  *
//  * @type {Messaging}
//  * @docs https://firebase.google.com/docs/cloud-messaging/js/client
//  */
// export const messaging = getMessaging(app);

// /**
//  * Firebase Performance Monitoring
//  *
//  * @type {FirebasePerformance}
//  * @docs https://firebase.google.com/docs/perf-mon/get-started-web
//  */
// export const perf = getPerformance(app);

// /**
//  * Firebase App Check
//  *
//  * @type {AppCheck}
//  * @docs https://firebase.google.com/docs/app-check/web/recaptcha-provider
//  */
// export const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider('reCAPTCHA v3 site key (public key)'),
//
//   // Optional argument. If true, the SDK automatically refreshes App Check
//   // tokens as needed.
//   isTokenAutoRefreshEnabled: true,
// });
