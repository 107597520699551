<template>
  <v-container fluid>
    <v-row id="signature-pad-container" class="signature-pad">
      <v-btn v-if="!hasPreloadedImageSign" class="undo-action" icon @click="onUndoAction">
        <v-icon color="primary">mdi-undo</v-icon>
      </v-btn>

      <v-btn v-if="!hasPreloadedImageSign" class="clear-action" icon @click="onClearAction">
        <v-icon color="primary">mdi-delete</v-icon>
      </v-btn>

      <article
        id="signature-box"
        class="signature-box"
        :style="`min-height: ${minimalWrapperHeight}`"
      >
        <v-img
          v-if="hasPreloadedImageSign"
          class="preloaded-signature__image"
          :src="preloadedSignatureImage"
          :max-width="signaturePadWidth"
          :max-height="minimalWrapperHeight"
          width="100%"
          height="100%"
          contain
        >
          <template v-slot:placeholder>
            <ImageLoader />
          </template>
        </v-img>

        <VueSignaturePad
          ref="signaturePad"
          :height="`${signaturePadHeight}px`"
          :options="signaturePadOptions"
        />
      </article>

      <div v-if="!preloadedSignatureImage" class="signature-pad__sign-line"></div>

      <div v-if="canShowCanvasLabel" class="signature-pad__canvas-placeholder">
        {{ $t('signing.place_signature_here') }}
      </div>
    </v-row>

    <v-row v-if="showProgress && !hasPreloadedImageSign" class="signature-pad__min-length-bar">
      <v-progress-linear
        v-model="signatureProgressBarWidth"
        :color="signatureProgressClass"
        height="10px"
      ></v-progress-linear>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ImageLoader from '@/common/skeletons/ImageLoader';

export default {
  name: 'SignaturePad',
  components: {
    ImageLoader,
  },
  props: {
    preloadedSignatureImage: {
      type: String,
      default: null,
    },
    showProgress: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      displayCanvasLabel: true,
      resizeObserver: null,
      signaturePadWidth: 323,
      signaturePadHeight: 149,
      storedSignatureImage: null,
    };
  },
  computed: {
    ...mapGetters({
      hasSignatureProgressContainsPoints: 'signature/hasSignatureProgressContainsPoints',
      previousDisplayWidth: 'signature/previousDisplayWidth',
      previousDisplayHeight: 'signature/previousDisplayHeight',
      storedSignatureProgress: 'signature/signatureProgress',
      signatureColor: 'signature/signatureColor',
      signatureProgressLength: 'signature/signatureProgressLength',
      signatureProgressBarWidth: 'signature/signatureProgressBarWidth',
      isSignatureProgressFilled: 'signature/isSignatureProgressFilled',
    }),
    signatureProgress: {
      get() {
        return this.storedSignatureProgress;
      },
      set() {
        if (this.$refs.signaturePad.$el) {
          this.setSignatureProgress(this.$refs.signaturePad.toData());
        }
      },
    },
    signaturePadOptions() {
      return {
        backgroundColor: 'rgba(255,255,255,0)',
        penColor: this.signatureColor,
        onBegin: this.onSignBegin,
        onEnd: this.onSignEnd,
      };
    },
    signatureProgressClass() {
      if (0 === this.signatureProgressBarWidth) {
        return 'transparent';
      } else if (this.signatureProgressBarWidth >= 100) {
        return 'success';
      } else if (this.signatureProgressBarWidth >= 50) {
        return 'warning';
      }

      return 'error';
    },
    canShowCanvasLabel() {
      return (
        this.displayCanvasLabel && 0 === this.signatureProgressLength && !this.hasPreloadedImageSign
      );
    },
    hasPreloadedImageSign() {
      return !!this.preloadedSignatureImage;
    },
    minimalWrapperHeight() {
      return ((this.signaturePadWidth - 2) / 323) * 149 || 149;
    },
  },
  watch: {
    '$vuetify.breakpoint.width': {
      handler: function (value) {
        if (value) {
          this.onResize();
        }
      },
    },
    displayCanvasLabel: {
      handler: function (value) {
        if (!value && 0 === this.storedSignatureProgress?.length) {
          this.$nextTick(() => {
            this.signatureProgress = this.$refs.signaturePad.toData();
          });
        }
      },
    },
    preloadedSignatureImage: {
      handler: function (value) {
        if (value) {
          this.onResize();
        }
      },
    },
    signaturePadHeight: {
      handler: function () {
        if (this.hasPreloadedImageSign) {
          return;
        }

        const signaturePad = this.$refs.signaturePad.$el;

        this.transformSignature();
        this.setDisplaySizes({
          previousWidth: signaturePad?.offsetWidth - 2 || 0,
          previousHeight: ((signaturePad?.offsetWidth - 2) / 323) * 149 || 0,
        });
        this.$nextTick(() => {
          this.$refs.signaturePad.resizeCanvas();
        });
      },
    },
    signaturePadWidth: {
      handler: function () {
        if (this.hasPreloadedImageSign) {
          return;
        }

        const signaturePad = this.$refs.signaturePad.$el;

        this.transformSignature();
        this.setDisplaySizes({
          previousWidth: signaturePad?.offsetWidth - 2 || 0,
          previousHeight: ((signaturePad?.offsetWidth - 2) / 323) * 149 || 0,
        });
        this.$nextTick(() => {
          this.$refs.signaturePad.resizeCanvas();
        });
      },
    },
  },
  mounted() {
    const signatureBox = document.getElementById('signature-box');
    this.signaturePadWidth = signatureBox.offsetWidth - 2 || this.$vuetify.breakpoint.width;
    this.signaturePadHeight = (this.signaturePadWidth / 323) * 149 || 0;

    this.resizeObserver = new ResizeObserver(() => this.onResize());
    this.resizeObserver.observe(this.$refs.signaturePad.$el);

    if (this.hasPreloadedImageSign) {
      this.$refs.signaturePad.clearSignature();
      this.$refs.signaturePad.lockSignaturePad();

      return;
    }

    if (this.storedSignatureProgress?.length !== 0) {
      this.$refs.signaturePad.fromData(this.storedSignatureProgress);
    }

    if (!this.resizeObserver) {
      window.addEventListener('resize', () => this.onResize());
    }

    this.$nextTick(() => {
      this.$refs.signaturePad.resizeCanvas();
    });
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.$refs.signaturePad.$el);
    }

    if (!this.resizeObserver) {
      window.removeEventListener('resize', () => this.onResize());
    }
  },
  methods: {
    ...mapActions({
      resetSignatureProgress: 'signature/resetSignatureProgress',
      setDisplaySizes: 'signature/setDisplaySizes',
      setSignatureProgress: 'signature/setSignatureProgress',
    }),
    onResize() {
      const signaturePad = this.$refs.signaturePad.$el;

      this.$nextTick(() => {
        this.signaturePadWidth = signaturePad?.offsetWidth;
        this.signaturePadHeight = (signaturePad?.offsetWidth / 323) * 149;
        this.$refs.signaturePad.resizeCanvas();

        if (this.hasPreloadedImageSign) {
          this.$refs.signaturePad.clearSignature();
          this.$refs.signaturePad.lockSignaturePad();
        }
      });
    },
    onSignBegin() {
      this.displayCanvasLabel = false;
    },
    onSignEnd() {
      this.displayCanvasLabel = true;
      this.signatureProgress = this.$refs.signaturePad.toData();
    },
    onUndoAction() {
      if (this.$refs.signaturePad) {
        this.$refs.signaturePad.undoSignature();
        this.signatureProgress = this.$refs.signaturePad.toData();
      }
    },
    onClearAction() {
      if (this.$refs.signaturePad) {
        this.$refs.signaturePad.clearSignature();
        this.resetSignatureProgress();
      }
    },
    saveSignature() {
      // TODO - .saveSignature('image/svg+xml')
      return this.$refs.signaturePad.saveSignature();
    },
    transformSignature() {
      if (
        !this.$refs.signaturePad.$el ||
        !this.hasSignatureProgressContainsPoints ||
        (0 === this.previousDisplayWidth && 0 === this.previousDisplayHeight)
      ) {
        return;
      }

      const self = this;
      const signaturePad = this.$refs.signaturePad.$el;
      const width = signaturePad?.offsetWidth - 2 || 0;
      const height = ((signaturePad?.offsetWidth - 2) / 323) * 149;

      if (height === this.previousDisplayHeight || width <= 0 || height <= 0) {
        return;
      }

      return new Promise((resolve) => {
        const percentageWidth = width / self.previousDisplayWidth;
        const percentageHeight = height / self.previousDisplayHeight;

        this.signatureProgress.forEach((curve, curveIndex, curves) => {
          curve.points.forEach((point) => {
            point.x = point.x * percentageWidth;
            point.y = point.y * percentageHeight;
          });

          if (curveIndex === curves.length - 1) {
            self.setDisplaySizes({
              previousWidth: width || 0,
              previousHeight: height || 0,
            });

            resolve(self.setSignatureProgress(self.signatureProgress));
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.signature-pad {
  position: relative;

  .undo-action {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .clear-action {
    position: absolute;
    top: 0;
    right: 0;
  }

  .signature-box {
    width: 100%;
    height: auto;

    .preloaded-signature__image {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }

  &__sign-line {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 25%;
    margin: 0 auto;
    width: 80%;
    height: 2px;
    border-top: 2px dashed #5e239e;
  }

  &__canvas-placeholder {
    position: absolute;
    text-align: center;
    color: #6e747d;
    font-style: italic;
    font-size: 15px;
    font-weight: 300;
    top: 50%;
    left: 50%;
    width: 300px;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }

  &__min-length-bar {
    margin-top: 1rem;
    min-height: 10px;
    border: 1px solid #dadada;
    overflow: hidden;
  }
}
</style>
