import { defineStore } from 'pinia';
import { hasSignIdentitySignAction } from '@/features/contracts/stores/contractSignTypes';

export const useContractSignaturesPlaceholdersStore = defineStore(
  'contractSignaturesPlaceholders',
  {
    state: () => ({
      signIdentities: {},
    }),
    actions: {
      fetchAllSignatures(currentContract) {
        const newState = {};

        new Set(
          0 < currentContract?.attachments?.length
            ? currentContract?.attachments
            : [currentContract],
        )?.forEach((contract) => {
          contract?.sign_identities?.forEach((signIdentity) => {
            newState[`${signIdentity.id}`] = Object.assign(
              {},
              {
                contractId: contract.id,
                contract_role: signIdentity?.contract_role,
                positions: [...signIdentity?.positions],
                contract_type: contract.type,
              },
            );
          });
        });

        this.signIdentities = Object.assign({}, newState);
      },
      saveSignaturePosition(contractId, signIdentitiesPositions) {
        const newState = {};

        Object.keys(signIdentitiesPositions)?.forEach((signIdentityId) => {
          if (Object.keys(this.signIdentities)?.includes(`${signIdentityId}`)) {
            newState[`${signIdentityId}`] = {
              ...this.signIdentities[`${signIdentityId}`],
              positions: [...signIdentitiesPositions[`${signIdentityId}`]],
            };
          }
        });

        this.signIdentities = Object.assign(this.signIdentities, newState);
      },
    },
    getters: {
      getAllSignIdentities: (state) => {
        return state.signIdentities;
      },
      contractsIdsList: (state) => {
        return [
          ...new Set(
            Object.keys(state.signIdentities)?.map(
              (signIdentityKey) => state.signIdentities[signIdentityKey]?.contractId,
            ),
          ),
        ];
      },
      getNextUndoneContractId: (state) => {
        const undoneSignatures = Object.keys(state.signIdentities)
          ?.filter(
            (signIdentityKey) =>
              hasSignIdentitySignAction(state.signIdentities[`${signIdentityKey}`]) &&
              'form' !== state.signIdentities[`${signIdentityKey}`]?.contract_type,
          )
          ?.filter((signIdentityKey) => {
            return (
              0 === state.signIdentities[`${signIdentityKey}`]?.positions?.length ||
              state.signIdentities[`${signIdentityKey}`]?.positions?.some((position) => {
                return null === position?.offset_left && null === position?.offset_top;
              })
            );
          });

        if (0 === undoneSignatures?.length) {
          return null;
        }

        return state.signIdentities[`${undoneSignatures[0]}`]?.contractId;
      },
      hasContractPlacedAllSignIdentities: (state) => (contractId) => {
        if (0 === Object.keys(state.signIdentities)?.length) {
          return false;
        }

        return (
          0 ===
          Object.keys(state.signIdentities)?.filter((signIdentityKey) => {
            if (
              `${contractId}` === `${state.signIdentities[`${signIdentityKey}`]?.contractId}` &&
              hasSignIdentitySignAction(state.signIdentities[`${signIdentityKey}`])
            ) {
              return (
                0 === state.signIdentities[`${signIdentityKey}`]?.positions?.length ||
                state.signIdentities[`${signIdentityKey}`]?.positions?.some(
                  (position) => null === position?.offset_left && null === position?.offset_top,
                )
              );
            }

            return false;
          })?.length
        );
      },
      isAllSignaturesPlaced: (state) => {
        if (0 === Object.keys(state.signIdentities)?.length) {
          return false;
        }

        return (
          0 ===
          Object.keys(state.signIdentities)
            ?.filter(
              (signIdentityKey) =>
                hasSignIdentitySignAction(state.signIdentities[`${signIdentityKey}`]) &&
                'form' !== state.signIdentities[`${signIdentityKey}`]?.contract_type,
            )
            ?.filter(
              (signIdentityKey) =>
                0 === state.signIdentities[`${signIdentityKey}`]?.positions?.length ||
                state.signIdentities[`${signIdentityKey}`]?.positions?.some((position) => {
                  return null === position?.offset_left && null === position?.offset_top;
                }),
            )?.length
        );
      },
    },
  },
);
