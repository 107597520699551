/*
 * Find svg flag on wikipedia, e.g. https://en.wikipedia.org/wiki/Flag_of_Vietnam
 * (country flags are too big: https://www.countryflags.com/en/vietnam-flag-vector.html)
 */

import * as appTranslations from '@digitalfactorycz/core/dist/lib/config/applicationTranslations';

export const defaultLocale = 'en';
export const fallbackLocale = 'en';
export const appLocales = appTranslations.locales.map((locale) => {
  return {
    ...locale,
    locale: locale.localeCode,
    code: locale.browserCode,
  };
});

export const appLocalesFlags = appLocales?.map((l) => l.flag);

export const availableLocales = appLocales.sort((a, b) =>
  a.locale > b.locale ? 1 : b.locale > a.locale ? -1 : 0,
);

export const fullyLocalizedLocales = appLocales.filter((l) => l.isFullyLocalized || false);

export const getAlternateLocale = (lang) => {
  const currentLocale = appLocales?.find((l) => l.locale === lang);

  return currentLocale?.alternate || currentLocale?.locale || 'en';
};

export const getLocales = (isFullyLocalized = true) =>
  isFullyLocalized ? fullyLocalizedLocales : availableLocales;

export const getNormalizeLocaleCode = (lang) =>
  appLocales?.find((l) => l.locale === lang)?.code || 'en-US';

export const isFullyLocalized = (lang) =>
  appLocales.find((l) => l.locale === lang).isFullyLocalized || false;

export const isLocaleSupported = (lang) =>
  availableLocales.findIndex((l) => l.locale === lang) !== -1;
