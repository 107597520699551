import { environment } from '@/config/environment';
import { goToLinkWithTarget } from '@/common/reusable/routing';

function openLink(link) {
  if (environment.isDevelopment()) {
    goToLinkWithTarget(link?.replace('8081', '8082'), '_self');
  }

  goToLinkWithTarget(link, '_self');
}

export default [
  {
    path: '/auth/:authToken',
    redirect: (to) => {
      return openLink(`${environment.getAppUrl()}/verification/${to.params?.authToken}/welcome`);
    },
  },
  {
    path: '/contract/received/:hash',
    redirect: (to) => {
      return openLink(`${environment.getAppUrl()}/signing/${to.params?.hash}/received`);
    },
  },
];
