import { render, staticRenderFns } from "./SubjectsCustomizingItem.vue?vue&type=template&id=165c5027&scoped=true&"
import script from "./SubjectsCustomizingItem.vue?vue&type=script&lang=js&"
export * from "./SubjectsCustomizingItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "165c5027",
  null
  
)

export default component.exports