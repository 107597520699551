import { auth } from '@/plugins/firebase';
import { GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';

/**
 * Google provider sign in function
 *
 * @param {String} languageCode
 *
 * @return {Promise<UserCredential>}
 */
export const useGoogleSignInPopup = (languageCode = null) => {
  const provider = new GoogleAuthProvider();

  if (null === languageCode) {
    auth.useDeviceLanguage();
  } else {
    auth.languageCode = languageCode;
  }

  return signInWithPopup(auth, provider)
    .then((result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);

      return {
        token: credential?.idToken,
        user: result?.user,
      };
    })
    .catch((error) => {
      throw {
        errorCode: error?.code,
        errorMessage: error?.message,
        email: error?.customData.email,
        credential: GoogleAuthProvider.credentialFromError(error),
      };
    });
};

/**
 * Google provider logout function
 *
 * @return {Promise<void>}
 */
export const useGoogleLogout = () => {
  return signOut(auth);
};
