<template>
  <Data :loader="isLoadingFirstRequest" :isDashboard="true">
    <div class="dashboard-documents__header">
      <div v-if="!oneSelectedFolder">
        <h1 class="dashboard-documents__heading">
          {{ $t('docs') }}
        </h1>
      </div>
      <div v-else class="dashboard-documents__header-wrapper">
        <h1 class="dashboard-documents__header-wrapper-title dashboard-documents__heading">
          {{ oneSelectedFolder.name }}
        </h1>
        <div class="dashboard-documents__header-wrapper-actions">
          <div class="dashboard-documents__header-wrapper-action">
            <a href="#" @click.prevent="openModalWithSelectedFolder('renameFolder')">
              <IcoEdit />
            </a>
          </div>
          <div class="dashboard-documents__header-wrapper-action" v-if="!documents.length && !isLoadingDocuments">
            <a href="#" @click.prevent="openModalWithSelectedFolder('deleteFolder')">
              <IcoTrash />
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="dashboard-documents__wrapper">
      <div class="dashboard-documents__table-wrapper">
        <ContractsFiltersSettings :workspaceData="workspaceData" />

        <div
          v-if="dashboardCheckedDocuments.length > 0"
          class="dashboard-documents__panel dashboard-documents__desktop-panel"
        >
          <div>
            {{
              $tc('table.checkedItems', dashboardCheckedDocuments.length, { count: dashboardCheckedDocuments.length })
            }}:
          </div>

          <div class="dashboard-documents__panel-link">
            <v-btn class="px-3" text @click="openModalWithCheckedDocuments('move')">
              <v-icon color="#B4C1D6" left>mdi-folder-move-outline</v-icon>
              <span class="subtitle-1 filters__button--primary font-weight-regular">
                {{ $t('folders.moveToFolder') }}
              </span>
            </v-btn>

            <v-tooltip :disabled="isBulkExtendSupported" bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn
                    class="px-3"
                    :disabled="!isBulkExtendSupported"
                    text @click="openModalWithCheckedDocuments('extendExpiration')"
                  >
                    <v-icon color="#B4C1D6" left>mdi-lock-clock</v-icon>
                    <span class="subtitle-1 filters__button--primary font-weight-regular">
                      {{ $t('docs.pending_expiration.extend') }}
                    </span>
                  </v-btn>
                </div>
              </template>

              <span>{{ $t('docs.bulk_action.extend.not_supported') }}</span>
            </v-tooltip>

            <v-tooltip :disabled="isBulkDeleteSupported" bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn class="px-3" :disabled="!isBulkDeleteSupported" text @click="showBulkDocumentsDeleteDialog = true">
                    <v-icon color="#B4C1D6" left>mdi-trash-can-outline</v-icon>
                    <span class="subtitle-1 filters__button--primary font-weight-regular">
                      {{ $t('general.delete') }}
                    </span>
                  </v-btn>
                </div>
              </template>

              <span>{{ $t('docs.bulk_action.delete.not_supported') }}</span>
            </v-tooltip>

            <v-tooltip :disabled="isBulkDeletePdfSupported" bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn class="px-3" :disabled="!isBulkDeletePdfSupported" text @click="showBulkDocumentsPdfsDeleteDialog = true">
                    <v-icon color="#B4C1D6" left>mdi-trash-can-outline</v-icon>
                    <span class="subtitle-1 filters__button--primary font-weight-regular">
                      {{ $t('general.delete_pdf') }}
                    </span>
                  </v-btn>
                </div>
              </template>

              <span>{{ $t('docs.bulk_action.delete_pdf.not_supported') }}</span>
            </v-tooltip>

            <v-btn class="px-3" text @click="openBulkDocumentsSignDialog">
              <v-icon color="#B4C1D6" left>mdi-fountain-pen-tip</v-icon>
              <span class="subtitle-1 filters__button--primary font-weight-regular">
                {{ $t('contract.sign') }}
              </span>
            </v-btn>

            <v-btn v-if="isEnabledMovingBetweenWorkspaces" class="px-3" text @click="showAvailableWorkspaces = true">
              <v-icon color="#B4C1D6" left>mdi-file-send-outline</v-icon>
              <span class="subtitle-1 filters__button--primary font-weight-regular">
                {{ $t('docs.move') }}
              </span>
            </v-btn>
          </div>
        </div>

        <div :class="{
          'document-table': true,
          'document-table-disabled': isLoadingDocuments
        }">
          <div>
          <v-toolbar class="document-table-header" flat>
              <v-row align="center" class="flex-nowrap overflow-hidden">
                <v-col cols="12" md="1" class="document-table-header__select">
                  <v-checkbox
                    v-model="areCheckedAll"
                    style="outline: #CED4DA" class="pl-0"
                    :disabled="!documents.length"
                    @click="checkOrUncheckAll()"
                    hide-details
                  >
                    <template v-slot:label>
                      <span class="d-md-none">
                        {{ areCheckedAll ? $t('table.uncheckAll') : $t('table.checkAll') }}
                      </span>
                    </template>
                  </v-checkbox>
                </v-col>

                <v-col cols="2" md="3" class="d-none d-md-flex document-table-header__width--235">
                  <div @click="onSortChange('title')" class="document-table-header__header d-none d-md-flex">
                    <span>{{ $t('general.title') }}</span>
                      <v-icon v-if="sortSettings.column == 'title'" small>
                        {{ `mdi-chevron-${sortSettings.isDescending ? 'down' : 'up'}` }}
                      </v-icon>
                      <v-icon v-else small>mdi-unfold-more-horizontal</v-icon>
                    </div>
                </v-col>

                <v-col
                  cols="2"
                  class="d-none d-md-flex document-table-header__header document-table-header__width--155"
                >
                  <div class="d-none d-md-block">
                    {{ $t('contract.state') }}
                  </div>
                </v-col>

                <v-col cols="1" md="2" class="d-none d-md-flex document-table-header__width--155">
                  <div class="document-table-header__header d-none d-md-block">
                    {{ $t('tags') }}
                  </div>
                </v-col>

                <v-col cols="1" md="2" class="d-none d-md-flex document-table-header__width--155">
                  <div class="document-table-header__header d-none d-md-block">
                    {{ $t('folders.title') }}
                  </div>
                </v-col>

                <v-col cols="1" class="d-none d-md-flex mr-lg-5">
                  <div
                    class="document-table-header__header d-none d-sm-flex align-center"
                    @click="onSortChange('created_at')"
                  >
                    <span>{{ $t('general.created_at') }}</span>
                    <v-icon v-if="sortSettings.column == 'created_at'" small>
                      {{ `mdi-chevron-${sortSettings.isDescending ? 'down' : 'up'}` }}
                    </v-icon>
                    <v-icon v-else small>mdi-unfold-more-horizontal</v-icon>
                  </div>
                </v-col>

                <v-col cols="1" class="d-none d-lg-flex mr-lg-6">
                  <div
                    class="document-table-header__header d-none d-sm-flex align-center"
                    @click="onSortChange('expire_at')"
                  >
                    <span>{{ $t('general.expire_at') }}</span>
                    <v-icon v-if="sortSettings.column == 'expire_at'" small>
                      {{ `mdi-chevron-${sortSettings.isDescending ? 'down' : 'up'}` }}
                    </v-icon>
                    <v-icon v-else small>mdi-unfold-more-horizontal</v-icon>
                  </div>
                </v-col>

                <v-col cols="1" class="d-none d-lg-flex justify-end">
                  <div class="document-table-header__header document-table-item__action d-none d-sm-block">
                    {{ $t('general.actions') }}
                  </div>
                </v-col>
              </v-row>

            <v-btn plain class="d-md-none" @click="openModalWithCheckedDocuments('sort')">
              {{ $t('table.order') }}
              <v-icon class="ml-1">mdi-menu-swap</v-icon>
            </v-btn>
          </v-toolbar>
          </div>

          <div v-if="documents.length">
            <div v-for="item in documents" :key="item.id">

              <DocumentTableRow :item="item" @reload="fetchData()" />

            </div>
          </div>
          <div class="document-table-empty" v-else>
            {{ $t('table.noResults') }}
          </div>
        </div>

        <div
          v-if="dashboardCheckedDocuments.length > 0"
          class="dashboard-documents__panel dashboard-documents__mobile-panel"
        >
          <div>
            {{ $tc('table.checkedItems', dashboardCheckedDocuments.length, { count: dashboardCheckedDocuments.length })
            }}:
          </div>

          <div class="dashboard-documents__panel-link">
            <v-btn class="px-3" text @click="openModalWithCheckedDocuments('move')">
              <v-icon color="#B4C1D6" left>mdi-folder-move-outline</v-icon>
              <span class="subtitle-1 filters__button--primary font-weight-regular">
                {{ $t('folders.moveToFolder') }}
              </span>
            </v-btn>

            <v-tooltip :disabled="isBulkExtendSupported" bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn
                    class="px-3"
                    :disabled="!isBulkExtendSupported"
                    text
                    @click="openModalWithCheckedDocuments('extendExpiration')"
                  >
                    <v-icon color="#B4C1D6" left>mdi-lock-clock</v-icon>
                    <span class="subtitle-1 filters__button--primary font-weight-regular">
                      {{ $t('docs.pending_expiration.extend') }}
                    </span>
                  </v-btn>
                </div>
              </template>

              <span>{{ $t('docs.bulk_action.extend.not_supported') }}</span>
            </v-tooltip>

            <v-tooltip :disabled="isBulkDeleteSupported" bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn
                    class="px-3"
                    :disabled="!isBulkDeleteSupported"
                    text
                    @click="showBulkDocumentsDeleteDialog = true"
                  >
                    <v-icon color="#B4C1D6" left>mdi-trash-can-outline</v-icon>
                    <span class="subtitle-1 filters__button--primary font-weight-regular">
                      {{ $t('general.delete') }}
                    </span>
                  </v-btn>
                </div>
              </template>

              <span>{{ $t('docs.bulk_action.delete.not_supported') }}</span>
            </v-tooltip>

            <v-tooltip :disabled="isBulkDeletePdfSupported" bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn class="px-3" :disabled="!isBulkDeletePdfSupported" text @click="showBulkDocumentsPdfsDeleteDialog = true">
                    <v-icon color="#B4C1D6" left>mdi-trash-can-outline</v-icon>
                    <span class="subtitle-1 filters__button--primary font-weight-regular">
                      {{ $t('general.delete_pdf') }}
                    </span>
                  </v-btn>
                </div>
              </template>

              <span>{{ $t('docs.bulk_action.delete_pdf.not_supported') }}</span>
            </v-tooltip>

            <v-btn class="px-3" text @click="openBulkDocumentsSignDialog">
              <v-icon color="#B4C1D6" left>mdi-fountain-pen-tip</v-icon>
              <span class="subtitle-1 filters__button--primary font-weight-regular">
                {{ $t('contract.sign') }}
              </span>
            </v-btn>
          </div>
        </div>
      </div>

      <BulkDocumentsDeleteDialog
        v-model="showBulkDocumentsDeleteDialog"
        @refresh-table="reloadCurrentData"
      />

      <BulkDocumentsPdfsDeleteDialog
        v-model="showBulkDocumentsPdfsDeleteDialog"
        @refresh-table="reloadCurrentData"
      />

      <DuplicateContract
        v-if="dashboardModal && dashboardModal.type === 'duplicate'"
        v-model="dashboardModal.type"
        :contract="dashboardModal.contracts[0]"
      />

      <ExtendExpiration
        v-else-if="dashboardModal && dashboardModal.type === 'extendExpiration'"
        v-model="dashboardModal.type"
        :contracts="modalEditor.bulkContracts.enabled"
        @reloadAfterExtend="fetchData"
      />

      <Modal v-else-if="dashboardModal && dashboardModal.type" :enableClose="true" @close="closeModal">
        <div v-if="dashboardModal.type === 'move'">
          <form class="modal-move" @submit.prevent="addDocsToFolder()">
            <Select placeholder="Složka" :value="modalEditor.selectedFolder ? modalEditor.selectedFolder.name : ''" @select="selectFolderInModal" :options="workspaceData.folders"></Select>
            <div class="profile-buttons dashboard-save-button">
              <Button buttonType="submit" type="primary" :disabled="!modalEditor.selectedFolder">
                {{ $t('folders.move') }}
              </Button>
            </div>
          </form>
          <div class="separator-hr dashboard-mb-20"></div>
          <div class="dashboard-edit-button">
            <Button @click="openModalWithCheckedDocuments('manageFolders')">
              {{ $t('folders.manage') }}
            </Button>
          </div>
        </div>
        <div v-if="dashboardModal.type === 'manageFolders'">
          <form @submit.prevent="createNewFolder()">
            <Input v-model="modalEditor.newName" type="text" :placeholder="$t('placeholder.name')" :label="$t('folders.new')" />
            <div class="profile-buttons dashboard-save-button">
              <Button buttonType="submit" type="primary" :disabled="modalEditor.newName.length < 2">
                {{ $t('folders.create') }}
              </Button>
            </div>
          </form>
        </div>

        <div v-if="dashboardModal.type === 'editTags'">
          <v-select
            v-model="selectedTags"
            :items="contractTags"
            :placeholder="`${$t('tags')}`"
            append-icon="mdi-chevron-down"
            menu-props="offset-y"
            class="multi-select filters__select--placeholder"
            item-text="name"
            item-value="key"
            dense
            solo
            multiple
            hide-details
            flat
            @change="checkTagsStatus"
          >
            <template v-slot:prepend-item>
              <v-list-item style="height: 38px">
                <v-list-item-content class="multi-select__select-all">
                  <v-checkbox
                    v-model="checkAll"
                    class="mr-6 d-flex multi-select__select-all"
                    @change="selectAll"
                    :disabled="!workspaceData.tags.length"
                  />
                  {{ $t('general.all') }}
                </v-list-item-content>
              </v-list-item>

              <v-divider />
            </template>

            <template v-slot:selection="{ item, index }">
              <div v-if="index === 0" class="text-truncate">{{ item.name }}</div>
              <div v-else class="grey--text text-caption d-md-none pl-1">
                {{ item.name }} (+{{ selectedTags.length - 1 }} {{ $t('general.other') }}) ???
              </div>
            </template>
          </v-select>

          <div>
            <v-btn color="primary" @click="editDocsTags">
              {{ $t('tags.save') }}
            </v-btn>
          </div>

          <div class="separator-hr my-3"></div>
          <div>
            <v-btn color="primary" @click="openModalWithCheckedDocuments('manageTags')">
              {{ $t('tags.manage') }}
            </v-btn>
          </div>
        </div>
        <div v-if="dashboardModal.type === 'manageTags'">
          <form @submit.prevent="createNewTag()">
            <Input v-model="modalEditor.newName" type="text" :placeholder="$t('placeholder.name')" :label="$t('tags.new')" />
            <div class="profile-buttons dashboard-save-button">
              <Button buttonType="submit" type="primary" :disabled="modalEditor.newName.length < 2">
                {{ $t('tags.create') }}
              </Button>
            </div>
          </form>
        </div>
        <div v-if="dashboardModal.type === 'delete'" class="dashboard-documents__modal-bulk">
          <div class="dashboard-documents__modal-bulk-heading">
            {{ $tc('docs.bulkDelete', dashboardModal.contracts.length, { count: dashboardModal.contracts.length }) }}
          </div>
          <div v-if="modalEditor.bulkContracts.disabled.length" class="dashboard-documents__modal-bulk-disabled">
            <strong>{{ $t('docs.bulkError.deleteTitle') }}</strong>
            {{ $t('docs.bulkError.deleteDescription') }}
            <ul class="dashboard-documents__modal-bulk-list">
              <li v-for="contract in modalEditor.bulkContracts.disabled">
                <router-link :to="{ name: 'documentsDetail', params: { workspace_id: filters.workspace_id, contract_id: contract.id } }">
                  <IcoMenuPage /> {{ contract.title }} <span class="dashboard-documents__modal-bulk-list-link-icon"><IcoArrowBack/></span>
                </router-link>
              </li>
            </ul>
          </div>
          <ul class="dashboard-documents__modal-bulk-list">
            <li v-for="contract in modalEditor.bulkContracts.enabled">
              <router-link :to="{ name: 'documentsDetail', params: { workspace_id: filters.workspace_id, contract_id: contract.id } }">
                <IcoMenuPage /> {{ contract.title }} <span class="dashboard-documents__modal-bulk-list-link-icon"><IcoArrowBack/></span>
              </router-link>
            </li>
          </ul>
        </div>

        <div v-if="dashboardModal.type === 'sort'" class="sort-documents-modal">
          <div>
            <div>{{ $t('general.title') }}:</div>

            <div>
              <div @click="onSortChange('title', 'ASC')">
                <v-icon
                  v-if="isSortedBy('title', 'ASC')"
                  color="primary"
                  small
                >mdi-check</v-icon>
                {{ $t('table.order.alphabetDesc') }}
              </div>

              <div @click="onSortChange('title', 'DSC')">
                <v-icon
                  v-if="isSortedBy('title', 'DSC')"
                  color="primary"
                  small
                >mdi-check</v-icon>
                {{ $t('table.order.alphabetAsc') }}
              </div>
            </div>
          </div>

          <div>
            <div>{{ $t('general.created_at') }}:</div>

            <div>
              <div @click="onSortChange('created_at', 'DSC')">
                <v-icon
                  v-if="isSortedBy('created_at', 'DSC')"
                  color="primary"
                  small
                >mdi-check</v-icon>
                {{ $t('table.order.latest') }}
              </div>

              <div @click="onSortChange('created_at', 'ASC')">
                <v-icon
                  v-if="isSortedBy('created_at', 'ASC')"
                  color="primary"
                  small
                >mdi-check</v-icon>
                {{ $t('table.order.oldest') }}
              </div>
            </div>
          </div>

          <div>
            <div>{{ $t('general.updated_at') }}:</div>
            <div>
              <div @click="onSortChange('updated_at', 'DSC')">
                <v-icon
                  v-if="isSortedBy('updated_at', 'DSC')"
                  color="primary"
                  small
                >mdi-check</v-icon>
                {{ $t('table.order.latest') }}
              </div>

              <div @click="onSortChange('updated_at', 'ASC')">
                <v-icon
                  v-if="isSortedBy('updated_at', 'ASC')"
                  color="primary"
                  small
                >mdi-check</v-icon>
                {{ $t('table.order.oldest') }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="dashboardModal.type === 'renameFolder'">
          <form @submit.prevent="renameFolder()">
            <Input v-model="modalEditor.newName" type="text" :placeholder="$t('placeholder.name')" :label="$t('general.title')" />
            <div class="profile-buttons dashboard-save-button">
              <Button buttonType="submit" type="primary" :disabled="modalEditor.newName.length < 2">
                {{ $t('general.rename') }}
              </Button>
            </div>
          </form>
        </div>
        <div v-if="dashboardModal.type === 'deleteFolder'" class="dashboard-documents__modal-bulk">
          <div
            class="dashboard-documents__modal-bulk-heading"
            v-html="$t('folders.delete_confirmHtml', { folderName: modalEditor.selectedFolder.name })"
          ></div>
          <Button buttonType="submit" type="secondary" @click="deleteFolder()">
            {{ $t('general.delete') }}
          </Button>
        </div>
      </Modal>
    </div>

    <LoadMore :isLoadingTableRows="isLoadingDocuments" @onLoadMore="onLoadMore" />

    <v-dialog v-model="showBulkDocumentsSignDialog" max-width="500px">
      <v-card class="pa-5">
        <v-card-title class="py-4">
          <h2 class="title font-weight-medium mx-auto mb-0 text-center">
            {{ `${$t('docs.bulk_sign.text_start')} ${$tc('general.x_documents', bulkSignOrSignWithCertificateDocuments, {count: bulkSignOrSignWithCertificateDocuments})} ${$t('docs.bulk_sign.text_end')} ${$tc('general.x_documents', bulkApproveDocuments.length, {count: bulkApproveDocuments.length})}` }}
          </h2>
        </v-card-title>

        <v-card-actions>
          <v-btn color="primary" min-width="30%" outlined @click="showBulkDocumentsSignDialog = false">
            {{ $t('general.no') }}
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            color="error"
            :loading="isSubmitted"
            min-width="30%"
            @click="signBulkDocuments"
          >
            {{ $t('general.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showAvailableWorkspaces" max-width="500px">
      <v-card class="pb-7" elevation="0">
        <v-toolbar class="pt-1" dense flat>
          <v-spacer></v-spacer>
          <v-btn icon @click="showAvailableWorkspaces = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-title>
          <h2 class="font-weight-medium mx-auto mb-0 pb-3 text-center">
            {{ $t('docs.move') }}
          </h2>
        </v-card-title>

        <v-card-subtitle class="text-center">
          {{ $t('docs.move.info') }}
        </v-card-subtitle>

        <v-card-actions>
          <v-row no-gutters align="center" justify="center">
            <v-col cols="8">
              <v-select
                v-model="selectedWorkspace"
                background-color="white"
                :items="profileWorkspaces"
                item-text="title"
                :label="$t('docs.move.hint')"
                :placeholder="$t('docs.move.hint')"
                hide-details
                return-object
                outlined
              >
              </v-select>
            </v-col>

            <v-col cols="2" class="pl-3">
              <v-btn
                class="primary"
                dark
                x-large
                block
                outlined
                :disabled="isWorkspaceSelected"
                @click="moveDocument"
              >
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

      <v-dialog
        v-model="isLoadingSignData"
        max-width="500"
      >
        <v-card>
          <v-card-title>
            <h2 class="mx-auto mt-1">
              {{ $t('docs.bulk_sign.loader_text') }}
            </h2>
          </v-card-title>

          <v-card-text class="text-center">
            <v-progress-circular class="my-5" indeterminate color="primary" :size="70" :width="6"></v-progress-circular>
          </v-card-text>
        </v-card>
      </v-dialog>
  </Data>
</template>

<script>
import { defineComponent } from "vue";
import {storeToRefs} from "pinia";
import { mapGetters } from "vuex";
import { DataService } from '@/services/DataService.js';
import featureFlagsChecker, { hasFeatureFlag } from '@/common/reusable/featureFlagsChecker';
import { internalApiRequest } from '@/shared/services/apiService';
import moment from 'moment';
import DocumentTableRow from "@dashboard/components/DocumentTableRow.vue";
import DuplicateContract from "./components/DuplicateContract.vue";
import ExtendExpiration from "@/views/dashboard/components/ExtendExpiration.vue";
import Modal from '@/components/Modal.vue';
import IcoCheckPurple from '@/components/svg/IcoCheckPurple.vue';
import IcoMenuPage from '@/components/svg/menu/IcoMenuPage.vue';
import IcoArrowBack from '@/components/svg/IcoArrowBack';
import IcoArrowDownGrey from '@/components/svg/IcoArrowDownGrey.vue';
import LoadMore from "@/views/dashboard/components/LoadMore";
import IcoEdit from "@/views/dashboard/components/icons/IcoEdit";
import IcoTrash from "@/views/dashboard/components/icons/IcoTrash";
import BulkDocumentsDeleteDialog from "@dashboard/components/BulkDocumentsDeleteDialog";
import BulkDocumentsPdfsDeleteDialog from "@dashboard/components/BulkDocumentsPdfsDeleteDialog";
import ContractsFiltersSettings from "@/features/contracts/components/ContractsFiltersSettings.vue";
import { useContractsFiltersStore } from "@/features/contracts/stores/contractsFilter";
import { getUserSignature } from '@account/services/accountServices';
import { authorizedApiRequest } from '@/services/ApiService';
import { environment } from '@/config/environment';
import { goToLinkWithTarget } from '@/common/reusable/routing';

  export default defineComponent({
    name: 'Documents',
    components: {
      ContractsFiltersSettings,
      BulkDocumentsDeleteDialog,
      BulkDocumentsPdfsDeleteDialog,
      DocumentTableRow,
      Modal,
      DuplicateContract,
      ExtendExpiration,
      LoadMore,
      IcoCheckPurple,
      IcoMenuPage,
      IcoArrowBack,
      IcoArrowDownGrey,
      IcoEdit,
      IcoTrash,
    },
    data() {
      return {
        showBulkDocumentsDeleteDialog: false,
        showBulkDocumentsPdfsDeleteDialog: false,
        showBulkDocumentsSignDialog: false,
        isLoadingFirstRequest: true,
        isLoadingDocuments: true,
        debounceTimeout: false,
        documents: [],
        workspaceData: {
          isLoaded: false,
          folders: [],
          tags: [],
          states: this.getStateFilters(),
        },
        sortSettings: {
          column: 'created_at',
          isDescending: true,
        },
        page: 0,
        limit: 20,
        screenWidth: 0,
        modalEditor: {
          newName: '',
          selectedFolder: null,
          bulkContracts: {
            enabled: [],
            disabled: [],
          },
        },
        showAvailableWorkspaces: false,
        selectedWorkspace: null,
        checkAll: false,
        contractTags: [],
        selectedTags: [],
        submitted: false,
        bulkSignDocuments: 0,
        bulkApproveDocuments: 0,
        bulkSignWithCertificateDocuments: 0,
        loadedSignData: true,
      }
    },
    setup() {
      const contractsFiltersStore = useContractsFiltersStore()
      const { filters } = storeToRefs(contractsFiltersStore)

      return {
        filters,
        contractsFiltersStore
      }
    },
    computed: {
      ...mapGetters({
        workspaceById: "workspaceById",
        profile: "profile",
        dashboardCheckedDocuments: "dashboardCheckedDocuments",
        dashboardModal: "dashboardModal",
        isMobileDevice: "window/isMobileDevice",
      }),
      workspaceId() {
        return this.$route?.params?.workspace_id;
      },
      currentWorkspace() {
        return this.workspaceById(this.workspaceId);
      },
      isEnabledMovingBetweenWorkspaces() {
        return featureFlagsChecker.enableMovingContracts(this.currentWorkspace);
      },
      isWorkspaceSelected() {
        return !this.selectedWorkspace;
      },
      profileWorkspaces() {
        if (!this.profile) {
          return [];
        }

        return this.profile?.workspaces?.filter(
          (workspace) =>
            workspace?.is_owner &&
            parseInt(workspace?.id) !== parseInt(this.$route.params.workspace_id),
        );
      },
      areCheckedAll() {
        return this.documents.length && this.dashboardCheckedDocuments.length === this.documents.length;
      },
      oneSelectedFolder() {
        const selectedFolders = this.workspaceData.folders.filter((folder) => {
          return folder.isSelected;
        });
        return selectedFolders.length == 1 ? selectedFolders[0] : null;
      },
      isBulkDeleteSupported() {
        for (let document of this.dashboardCheckedDocuments) {
          if (document.state === 'completed') {
            return false;
          }
        }

        return true;
      },
      isBulkDeletePdfSupported() {
        for (let document of this.dashboardCheckedDocuments) {
          if (document.state === 'completed' && document?.feature_flags?.canDeleteDocuments) {
            return true;
          }
        }

        return false;
      },
      isBulkExtendSupported() {
        for (let document of this.dashboardCheckedDocuments) {
          if (!hasFeatureFlag(document, 'isExpirationExtendable')) {
            return false;
          }
        }

        return true;
      },
      isSubmitted() {
        return this.submitted;
      },
      bulkSignOrSignWithCertificateDocuments() {
        return this.bulkSignDocuments.length + this.bulkSignWithCertificateDocuments.length;
      },
      isLoadingSignData() {
        return !this.loadedSignData;
      },
    },
    async created() {
      this.fetchSavedFilters()
      this.fetchData();
      await this.$store.dispatch('resetDashboard');
    },
    methods: {
      async fetchBackgroundData(retryAttempts = 10, ids, url) {
        this.loadedSignData = false;

        if (0 > retryAttempts) {
          return (() => {
            this.$notification.error(this.$t('general.data_not_loaded'));
            this.loadedSignData = true;
          })();
        }

        return await this.loadBackgroundData(ids)
          .then(() => {
            goToLinkWithTarget(`signi-app://sign-pdf-batch?batchUrl=${environment.getApiUrl()}/api/v1/contracts/batch/sign/list?${encodeURIComponent(url)}`, '_blank');
            this.loadedSignData = true;
          })
          .catch(() => {
            setTimeout(() => {
              this.fetchBackgroundData(retryAttempts - 1, ids, url);
            }, 2500);
          });
      },
      loadBackgroundData(ids) {
        return authorizedApiRequest({
          method: 'GET',
          endpoint: `api/v1/contracts/batch/sign/list?${ids}`,
        })
      },
      openBulkDocumentsSignDialog() {
        this.bulkSignDocuments = this.dashboardCheckedDocuments.filter((doc) => doc?.people?.some((person) => person.email === this.profile.email && person.signatureType === 'sign_type_a'));
        this.bulkApproveDocuments = this.dashboardCheckedDocuments.filter((doc) => doc?.people?.some((person) => person.email === this.profile.email && person.signatureType === 'approve'));
        this.bulkSignWithCertificateDocuments = this.dashboardCheckedDocuments.filter((doc) => doc?.people?.some((person) => person.email === this.profile.email && person.signatureType === 'sign_certificate'));

        if(this.bulkSignDocuments.length || this.bulkApproveDocuments.length) {
          getUserSignature()
          .then(() => {
            this.showBulkDocumentsSignDialog = true;
          })
          .catch((err) => {
            if (err?.startsWith('Signature not found for user') || err?.startsWith('Not Found')) {
              this.$notification.error(this.$t('docs.bulk_sign.error.signature_miss'));

              this.$router.push({
                path: '/account#signature'
              });
            } else {
              this.$notification.error(this.$t('general.error'));
            }
          })
        } else {
          this.showBulkDocumentsSignDialog = true;
        }
      },
      async signBulkDocuments() {
        this.submitted = true;
        const contractIds = this.dashboardCheckedDocuments.map((contract) => contract.id)

        await internalApiRequest({
        method: 'POST',
        path: 'api/v1/contracts/batch/sign',
        data: {
          contractIds: contractIds,
        }
      })
        .then((resp) => {
          const errorsSignedDocuments = resp.errors.filter((errDoc) => this.bulkSignDocuments.filter((doc) => errDoc.contractId === doc.id)).length;
          const errorsApprovedDocuments = resp.errors.filter((errDoc) => this.bulkApproveDocuments.filter((doc) => errDoc.contractId === doc.id)).length;
          const successSignedDocuments = this.bulkSignDocuments.length - errorsSignedDocuments;
          const successApprovedDocuments = this.bulkApproveDocuments.length - errorsApprovedDocuments;

          this.$notification.success(`${this.$tc('docs.bulk_sign.sign_type_a', successSignedDocuments, {count: successSignedDocuments})} ${this.$tc('docs.bulk_sign.approve', successApprovedDocuments, {count: successApprovedDocuments})} ${this.$tc('docs.bulk_sign.sign_with_certificate', this.bulkSignWithCertificateDocuments.length, {count: this.bulkSignWithCertificateDocuments.length})}`);

          if(resp?.signWithCertificateUrl) {
            const ids = resp?.signWithCertificateUrl?.substr(resp?.signWithCertificateUrl?.lastIndexOf('&') + 1);

            internalApiRequest({
              method: 'POST',
              path: `api/v2/signaturesWithCertificate/startProcess/batch?${ids}`,
            }).then(() => {
              this.fetchBackgroundData(25, ids, resp?.signWithCertificateUrl);
            })
          }
        })
        .finally(() => {
          this.submitted = false;
          this.showBulkDocumentsSignDialog = false;
        });
      },
      fetchSavedFilters() {
        if(!!this.filters.workspace_id && this.filters.workspace_id != this.$route.params.workspace_id) {
        this.contractsFiltersStore.resetFilters()
        }

        if(this.$route.query?.state) {
          this.contractsFiltersStore.updateFilter('states', [this.$route.query.state])
        }

        this.contractsFiltersStore.updateFilter('workspace_id', this.$route.params.workspace_id)
      },
      moveDocument() {
        internalApiRequest({
          method: 'POST',
          path: `api/v2/contract/moveTo/workspace/${this.selectedWorkspace?.id}`,
          data: {
            contractIds: this.dashboardCheckedDocuments.map(contract => contract?.id),
          },
        })
          .then(() => {
            this.$notification.success(this.$t('docs.move.completed'));
            this.showAvailableWorkspaces = false;
            this.reloadCurrentData();
          })
          .catch(() => {
            this.$notification.error(this.$t('general.error'));
          });
      },
      checkOrUncheckAll() {
        if (this.areCheckedAll) {
          this.$store.commit('dashboardDeselectAllDocuments');
          return;
        }
        this.$store.commit('dashboardSelectAllDocuments', this.documents);
      },
      checkTagsStatus(){
        this.checkAll = this.selectedTags.length === this.contractTags.length
      },
      reloadCheckedDocuments() {
        const checkedIds = this.dashboardCheckedDocuments.map(c => c.id);
        const checkedRows = this.documents.filter(c => checkedIds.indexOf(c.id) !== -1);
        this.$store.commit('dashboardSelectAllDocuments', checkedRows);
      },
      onSortChange(category, direction = null) {
        if (category !== this.sortSettings.column) {
          this.sortSettings.column = category;
          this.sortSettings.isDescending = direction ? (direction == 'DSC') : this.sortSettings.isDescending;
        } else {
          this.sortSettings.column = category;
          this.sortSettings.isDescending = direction ? (direction == 'DSC') : !this.sortSettings.isDescending;
        }
        this.reloadCurrentData();
      },
      isSortedBy(category, direction) {
        return this.sortSettings.column === category
          && (this.sortSettings.isDescending === (direction === 'DSC'));
      },
      reloadCurrentData() {
        this.page = 0;
        this.fetchData();
      },
      fetchData() {
        this.isLoadingDocuments = true;

        const normalizeDate = jsDate => jsDate ? moment(jsDate).format('YYYY-MM-DD') : null;

        DataService.getContracts({
            workspace_id: this.filters?.workspace_id || this.$route.params.workspace_id,
            states: this.filters?.states,
            tags_ids: this.filters?.tags,
            folder_ids: this.filters?.folders,
            search_query: this.filters?.searchQuery,
            created_at: {
              from: normalizeDate(this.filters.createdAt?.startDate),
              to: normalizeDate(this.filters.createdAt?.endDate),
            },
            sort: {
              column: this.sortSettings.column,
              isDescending: this.sortSettings.isDescending,
            },
            pagination: {
              limit: this.limit,
              offset: this.page * this.limit,
            },
            load_metadata: true,
        })
          .then((response) => {
            this.loadAvailableTagsAndFolders(response.meta);

            const newDocuments = response.data;

            this.documents = this.page
              ? this.documents.concat(newDocuments)
              : newDocuments;

            this.reloadCheckedDocuments();
            this.updateQueryParams(this.filters, this.workspaceData);

            this.isLoadingDocuments = false;
            this.isLoadingFirstRequest = false;
          })
          .catch(() => {
            this.isLoadingDocuments = false;
            this.isLoadingFirstRequest = false;
          });
      },
      createNewFolder() {
        this.createNewTag(true);
      },
      renameFolder() {
        this.manageTagsOrFolders({
          action: 'rename',
          workspace_id: this.filters.workspace_id,
          id: this.modalEditor.selectedFolder.id,
          name: this.modalEditor.newName,
        });
      },
      deleteFolder() {
        this.manageTagsOrFolders({
          action: 'delete',
          workspace_id: this.filters.workspace_id,
          id: this.modalEditor.selectedFolder.id,
        });
      },
      createNewTag(isFolder) {
        this.manageTagsOrFolders({
          action: 'create',
          workspace_id: this.filters.workspace_id,
          name: this.modalEditor.newName,
          is_folder: !!isFolder,
        });
      },
      selectAll() {
        if (this.checkAll) {
          return (this.selectedTags = this.contractTags.map((tag) => tag.key));
        }

        this.selectedTags = [];
      },
      manageTagsOrFolders(request) {
        DataService.manageTagsOrFolders(request).then(
          (response) => {
            this.loadAvailableTagsAndFolders(response);
            if (request.action == 'delete') {
              this.contractsFiltersStore.updateFilter('folders', []);
            }
            this.closeModal();
          },
          () => {
            this.$notification.error(this.$t('folders.createError'));
          }
        );
      },
      editDocsTags() {
        DataService.assignTagsOrFolders({
          workspace_id: this.filters.workspace_id,
          tags_ids: this.selectedTags,
          docs_ids: this.dashboardModal.contracts.map(contract => contract.docs_id),
        }).then(
          () => {
            this.fetchData();
            this.closeModal();
          },
          () => {
            this.$notification.error(this.$t('tags.editError'));
          }
        );
      },
      addDocsToFolder() {
        DataService.assignTagsOrFolders({
          workspace_id: this.filters.workspace_id,
          folder_id: this.modalEditor.selectedFolder.id,
          docs_ids: this.dashboardModal.contracts.map(contract => contract.docs_id),
        }).then(
          (response) => {
            this.contractsFiltersStore.updateFilter('folders', [response.folder_id]);
            this.closeModal();
          },
          () => {
            this.$notification.error(this.$t('folders.moveError'));;
          }
        );
      },
      loadAvailableTagsAndFolders(transformedTags) {
        this.workspaceData.tags = (transformedTags.tags || []).map(tag => ({
          name: tag.name,
          key: tag.id,
          isSelected: this.filters.tags.indexOf(tag.id) != -1,
        }));

        this.workspaceData.tags.push({
          name: this.$t('no_tag'),
          key: 'no_tag',
          root: true,
          isSelected: this.filters.tags.indexOf('no_tag') != -1,
        });

        this.workspaceData.folders = (transformedTags.folders || []).map(tag => ({
          name: tag.name,
          key: tag.id,
          id: tag.id, // fallback for folder (used in move modal + addDocsToFolder, maybe in other places too)
          isSelected: this.filters.folders.indexOf(tag.id) != -1,
        }));

        this.reloadMenuLinks();
      },
      openModalWithCheckedDocuments(action) {
        this.$store.commit('dashboardOpenModal', {
          type: action,
          contracts: this.dashboardCheckedDocuments,
        });
      },
      selectFolderInModal(selected) {
        this.modalEditor.selectedFolder = selected;
      },
      openModalWithSelectedFolder(action) {
        this.modalEditor.newName = this.oneSelectedFolder.name;
        this.modalEditor.selectedFolder = this.oneSelectedFolder;
        this.$store.commit('dashboardOpenModal', {
          type: action,
        });
      },
      closeModal() {
        this.modalEditor = {
          newName: '',
          selectedFolder: null,
          bulkContracts: {
            enabled: [],
            disabled: [],
          },
        };
        this.contractTags = [],
        this.$store.commit('dashboardCloseModal');
      },
      onLoadMore() {
        this.page += 1;
        this.fetchData();
      },
      updateQueryParams(filters, workspaceData) {
        const query = {};
        if (filters.states?.length && workspaceData.states.length != filters.states?.length) {
          query.state = filters.states?.join(',');
        }

        if (filters.folders && filters.folders?.length) {
          // avoids NavigationDuplicated for string/integer conversion...
          query.folder = filters.folders?.length == 1 ? filters.folders[0] : filters.folders.join(',');
        }

        if (JSON.stringify(query) == JSON.stringify(this.$route.query)) {
          return;
        }

        this.$router.replace({ query });
      },
      getActiveFoldersFromUrl() {
        if (!this.$route.query || !this.$route.query.folder) {
          return [];
        }
        return String(this.$route.query.folder).split(',').filter(id => parseInt(id)).map(id => parseInt(id));
      },
      getStateFilters() {
        const activeStates = this.getActiveStatesFromUrl();
        const isReviewDisabled = this.isReviewDisabled(activeStates);
        const allStates = [
          {name: this.$t('docs.drafts'), key: 'draft'},
          {name: this.$t('review.pending_generic'), key: 'review', isDisabled: isReviewDisabled },
          {name: this.$t('review.pending_me'), key: 'review.me', isDisabled: isReviewDisabled },
          {name: this.$t('review.pending_other'), key: 'review.not_me', isDisabled: isReviewDisabled },
          {name: this.$t('docs.pending.generic'), key: 'pending' },
          {name: this.$t('docs.pending.me'), key: 'pending.me' },
          {name: this.$t('docs.pending.not_me'), key: 'pending.not_me' },
          {name: this.$t('docs.pending.expiring'), key: 'pending.expiring' },
          {name: this.$t('docs.rejected'), key: 'rejected' },
          {name: this.$t('docs.expired'), key: 'expired' },
          {name: this.$t('docs.completed'), key: 'completed' },
          {name: this.$t('docs.completed.expiring'), key: 'completed.expiring' },
          {name: this.$t('docs.archive.trash'), key: 'archive.trashed' },
        ];

        return allStates
          .filter(f => !f.isDisabled)
          .map(
            ({ name, key }) => ({
              name,
              key,
              isSelected: activeStates.indexOf(key) !== -1 || this.filters.states.find((state) => state === key),
            })
          );
      },
      getActiveStatesFromUrl() {
        if (!this.$route.query || !this.$route.query.state) {
          return [];
        }

        return this.$route.query.state.split(',').filter(state => state && state.length);
      },
      reloadMenuLinks(dynamicQuery) {
        const changeQueryString = ({ state, folder }) => () => {
          this.$router.replace({
            name: 'documentsTable',
            query: { state, folder },
          });
        };
        // TODO: UI - extract csv query string helpers (copy-pasted code in getActiveGroupsFromUrl, getActiveStatesFromUrl, getActiveFoldersFromUrl)
        const queryString = dynamicQuery || this.$route.query || {};
        const activeStates = this.getActiveStatesFromUrl();
        const activeFoldersFromUrl = String(queryString.folder).split(',').filter(id => parseInt(id)).map(id => parseInt(id))
        const routes = [
          {
            title: this.$t('docs.all'),
            action: changeQueryString({}),
            isActive: !queryString.state && !activeFoldersFromUrl.length,
          },
          {
            title: this.$t('review.pending_generic'),
            action: changeQueryString({ state: 'review' }),
            isActive: queryString.state === 'review' && !activeFoldersFromUrl.length,
            isDisabled: this.isReviewDisabled(activeStates),
          },
          {
            title: this.$t('docs.pending.generic'),
            action: changeQueryString({ state: 'pending' }),
            isActive: queryString.state === 'pending' && !activeFoldersFromUrl.length,
          },
          {
            title: this.$t('docs.drafts'),
            action: changeQueryString({ state: 'draft' }),
            isActive: queryString.state === 'draft' && !activeFoldersFromUrl.length,
          },
          {
            title: this.$t('docs.completed'),
            action: changeQueryString({ state: 'completed' }),
            isActive: queryString.state === 'completed' && !activeFoldersFromUrl.length,
          },
          {
            title: this.$t('docs.expiring'),
            action: changeQueryString({ state: 'pending.expiring,completed.expiring' }),
            isActive: queryString.state === 'pending.expiring,completed.expiring' && !activeFoldersFromUrl.length,
          },
          {
            title: this.$t('docs.expired'),
            action: changeQueryString({ state: 'expired' }),
            isActive: queryString.state === 'expired' && !activeFoldersFromUrl.length,
          },
          {
            title: this.$t('docs.archive.trash'),
            action: changeQueryString({ state: 'archive.trashed' }),
            isActive: queryString.state === 'archive.trashed' && !activeFoldersFromUrl.length,
          },
          {
            title: 'SidebarFolders',
          },
          ...this.workspaceData.folders.map(folder => ({
            title: folder.name,
            action: changeQueryString({ folder: folder.key }),
            isActive: activeFoldersFromUrl.indexOf(folder.key) !== -1,
          })),
          {
            title: `+ ${this.$t('folders.new')}`,
            extraCss: 'sidemenu-workplace__a-new-folder',
            action: () => {
              this.$store.commit('dashboardOpenModal', { type: 'manageFolders' });
            }
          },
        ];
        this.$store.commit('dashboardSetDocumentLinks', routes.filter(r => !r.isDisabled));
      },
      isReviewDisabled(activeStates) {
        const activeWorkspace = this.workspaceById ? this.workspaceById(this.$route.params.workspace_id) : false;
        const isEnabledInWorkspace = activeWorkspace;
        const isFilteredInUrl = this.$route.query.state && String(this.$route.query.state).indexOf('review') !== -1;
        return !(isEnabledInWorkspace || isFilteredInUrl);
      },
    },
    watch: {
      '$route.query.state'() {
        const activeStates = this.getActiveStatesFromUrl();
        _.each(this.workspaceData.states, selectOption => {
          selectOption.isSelected = activeStates.indexOf(selectOption.key) !== -1;
        });

        this.reloadMenuLinks();
        this.contractsFiltersStore.updateFilter('states', activeStates);
      },
      '$route.query.folder'() {
        const activeFolders = this.getActiveFoldersFromUrl();
        _.each(this.workspaceData.folders, selectOption => {
          selectOption.isSelected = activeFolders.indexOf(selectOption.key) !== -1;
        });

        this.reloadMenuLinks();
        this.contractsFiltersStore.updateFilter('folders', activeFolders);
      },
      dashboardModal() {
        // edit tags
        if (!this.dashboardModal || this.dashboardModal.type != 'editTags' || this.dashboardModal.contracts.length != 1) {
          this.contractTags = [];
        } else {
          const activeTags = this.dashboardModal.contracts[0].tags.map(tag => tag.id);
          this.contractTags = this.workspaceData?.tags
            ?.filter((wt) => !wt.root)
            ?.map((workspaceTag) => ({
              name: workspaceTag.name,
              key: workspaceTag.key,
              isSelected: activeTags.indexOf(workspaceTag.key) != -1,
            })) || [];

          this.selectedTags = this.contractTags.filter((tag) => tag.isSelected)
        }
        // bulk actions
        const flags = {
          delete: 'isCompletelyDeletable',
          extendExpiration: 'isExpirationExtendable',
        };
        const flag = this.dashboardModal ? flags[this.dashboardModal.type] : null;
        if (flag) {
          this.modalEditor.bulkContracts.enabled = this.dashboardModal.contracts.filter(c => hasFeatureFlag(c, flag));
          this.modalEditor.bulkContracts.disabled = this.dashboardModal.contracts.filter(c => !hasFeatureFlag(c, flag));
        }
      },
      profile: function () {
        if (this.profile && this.workspaceData) {
          // TODO: workflow - delete watcher and isReviewDisabled when review is enabled for all workspaces
          this.workspaceData.states = this.getStateFilters();
        }
      },
      'contractsFiltersStore.filters': {
        deep: true,
        handler() {
          this.page = 0;
          clearTimeout(this.debounceTimeout);
          this.debounceTimeout = setTimeout(() => {
            this.fetchData()

            clearTimeout(this.debounceTimeout);
            this.debounceTimeout = false;
          }, 400);
        }
      },
    },
  });
</script>

<style lang="scss" scoped>
.document-table-header {
  &__select {
    @media only screen and (min-width: 620px) {
      margin-right: -1rem;
    }

    @media only screen and (min-width: 1200px) {
      margin-right: -3rem;
    }
  }

  &__header {
    font-size: 14px;
    color: #424242;
    line-height: 17.5px;
    white-space: nowrap;
  }

  &__width {
    &--235 {
      max-width: 235px;
    }

    &--155 {
      max-width: 155px;
    }

    &--120 {
      max-width: 120px;
    }
  }
}

.multi-select {
  border: 1px solid #ced4da;
  margin-bottom: 1rem;

  &__select-all,
  &__select-tags {
    font-size: 13px;
    font-weight: 500;
  }
}
</style>

<style lang="scss">
.document-table {
  max-width: 1110px;
}

.filters__button--primary {
  color: var(--v-primary-base);
  text-decoration: underline;
}

  .dashboard-documents__header {
    margin-top: -35px;
    margin-bottom: 20px;
    @include md {
      margin-top: 0;
      margin-bottom: unset;
    }

    &-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .dashboard-documents__header-wrapper-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: unset;
        text-align: left;
      }

      .dashboard-documents__header-wrapper-actions {
        display: flex;
        flex-direction: row;
        a {
          margin: 0 5px;
          display: flex;
          align-items: center;
          text-align: left;
          color: #9fa2a6;
        }
      }
    }
  }

  .dashboard-documents__heading {
    font-weight: bold;
    text-align: left;
  }
  .dashboard-documents__desktop-panel {
    padding: 20px;
    background: white;
    border-radius: 6px;
    font-size: 16px;
    display: flex;
    color: black;
    position: sticky;
    margin-bottom: 20px;
    top: 70px;
    z-index: 1;
    box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.15);
    align-items: center;
    & > div:first-child {
      margin-right: 15px;
    }
    @include md {
      display: none;
    }
  }
  .dashboard-documents__mobile-panel {
    position: sticky;
    bottom: 0;
    z-index: 1;
    background: white;
    width: 100%;
    font-size: 16px;
    display: none;
    padding: 20px 20px 5px 20px;
    border-radius: 6px 6px 0 0;
    box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.15);
    & > div:first-child {
      width: 100px;
      margin-right: 10px;
      color: black;
    }
    & >div:nth-child(2) {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    @include md {
      display: flex;
    }
  }
  .dashboard-documents__panel-link {
    display: flex;
    align-items: center;
    & > span {
      display: flex;
      align-items: center;
      color: #7443AD;
      text-decoration: underline;
      margin-right: 20px;
      cursor: pointer;
      user-select: none;
      .dashboard-documents__panel-link-icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      a {
        color: #7443AD;
        font-weight: normal;
      }
    }
    @include md {
      justify-content: flex-start;
      & > span {
        margin-bottom: 15px;
        display: flex;
        align-self: end;
      }
    }
  }
  .dashboard-documents__panel-link-icon {
    &.icon::before {
      text-decoration: none;
      display: inline-block;
    }
  }
  .dashboard-documents__wrapper {
    .datetime-picker__button {
      color: gray;
    }
    .input__data::placeholder {
      color: gray;
      font-style: normal;
    }
    .modal-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
      .modal-container {
        max-width: 350px;
        max-height: 80vh;
        overflow: scroll;
        height: unset;
        &:before {
          display: none;
        }
      }
    }
  }

  .sort-documents-modal {
    padding-top: 30px;
    & > div {
      font-size: 14px;
      display: flex;
      & > div:first-child {
        width: 50%;
        text-align: left;
        color: black;
      }
      & > div:last-child {
        width: 50%;
        & > div {
          padding-left: 20px;
          text-align: left;
          color: #6831A5;
          margin-bottom: 10px;
          position: relative;
          & > span {
            position: absolute;
            left: 10px;
            svg {
              width: 8px;
            }
          }
        }
      }
    }
  }
  .funnel-ico {
    width: 12px;
    height: 12px;
  }
  .funnel-wrapper {
    display: flex;
    align-items: center;
  }

  .dashboard-documents__modal-bulk {
    text-align: left;
    font-size: 14px;
    .dashboard-documents__modal-bulk-heading {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 18px;
      color: black;
    }
    .dashboard-documents__modal-bulk-list {
      margin: 1ex 0;
      li {
        list-style-type: none;
        line-height: 1.5;
        padding-left: 30px;
        position: relative;
        a {
          color: #772cb3;
          text-decoration: underline;
          .dashboard-documents__modal-bulk-list-link-icon {
            width: 10px;
            transform: rotate(140deg);
          }
          & > svg {
            vertical-align: sub;
            position: absolute;
            left: 0;
          }
        }
      }
    }
    .dashboard-documents__modal-bulk-disabled {
      background: #FFFAD0;
      padding: 20px;
      border-radius: 1ex;
      border: 1px solid #F4EEB7;
      color: #8C5729;
      line-height: 20px;
      font-size: 15px;
    }
  }
  .modal-move {
    .select, .select__data {
      width: 290px;
    }
  }

.dashboard-documents__filters {
  .icon-search {
    position: absolute;
    right: 10px;
    top: 20px;
    height: 20px;
    width: 20px;
    font-size: 20px;
    &:before {
      color: #B9C6D9;
    }
  }
}
.dashboard-documents__filters-desktop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  & > div {
    width: 16%;
  }
  & > div:nth-child(2) {
    width: 18%;
  }
  & > div:nth-child(3) {
    width: 18%;
  }
  & > div:first-child {
    width: 30%;
  }
  .input {
    margin: 0;
    width: 100%;
  }
  .calendar-label {
    display: none;
  }
  .date-picker {
    left: -320px;
    border-radius: 6px 0 0 6px;
  }
  .date-time-picker-table {
    left: 0;
  }
  .select-wrap {
    position: relative;
  }
  .select__data {
    width: 300px;
    left: 0;
    transform: none;
    border-radius: 0 6px 6px 6px;
    top: 50px;
  }
  .daterangepicker {
    right: 0;
  }
}
.dashboard-documents__filters-desktop {
  @include lg {
    display: none;
  }
}

  .dashboard-documents__modal-bulk-list-link-icon {
    svg {
      width: 10px;
      transform: rotate(140deg);
    }
  }

  .dashboard-mb-20 {
    margin-bottom: 20px;
  }

  body #app .dashboard-edit-button {
    button {
      padding: 0;
      width: fit-content;
      height: fit-content;
      background: none;
    }
  }
  body #app .dashboard-save-button {
    width: 100%;
    margin-top: 10px;
    button {
      width: 100%;
      height: 50px;
    }
  }

  .document-table-header {
    position: relative;
    font-size: 14px;

    .document-table-item__checkbox-mobile-label {
      display: none;
    }

    & > div {
      padding: 0 15px 0 15px;
      display: flex;
      align-items: center;
    }
  }
  #app .document-table-item__select-filters-mobile {
    position: absolute;
    right: 0;
  }

  .document-table-empty {
    width: 100%;
    height: 200px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: black;
    font-weight: bold;
    border: 1px solid #E1E4E8;
    border-top: 0;
    border-radius: 0 0 6px 6px;
  }
  .document-table-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .document-table-header > div > span {
    cursor: pointer;
    text-decoration: underline;
  }

  .item-state-text {
    background: #e6e6e6;
    color: black;
  }
  .item-state-red {
    background: #FEE2E2;
    color: #D9403A;
  }
  .item-state-yellow {
    background: #FFF0B5;
    color: #A5803E;
  }
  .item-state-purple {
    background: #F4EAFE;
    color: #7C49B1;

  }
  .item-state-green {
    background: #6DECAF;
    color: #297F53;
  }
  .item-state-hidden {
    display: none;
  }
</style>
