import SignatureText from '@/features/signature/components/SignatureText.vue';
import SignaturePad from '@/features/signature/components/SignaturePad.vue';
import SignatureImage from '@/features/signature/components/SignatureImage.vue';

export const defaultSignTypes = [
  {
    component: SignatureText,
    disabled: false,
    identifier: 'text',
    order: 0,
    ref: 'signatureText',
  },
  {
    component: SignaturePad,
    disabled: false,
    identifier: 'signature',
    order: 1,
    ref: 'signaturePad',
  },
  {
    component: SignatureImage,
    disabled: false,
    identifier: 'image',
    order: 2,
    ref: 'signatureImage',
  },
];
