// TODO - Dashboard module
import Dashboard from '@/views/dashboard/Dashboard.vue';

// TODO - Reports module
import Reports from '@/views/dashboard/Reports.vue';

// TODO - Contract module with components and pages
import Documents from '@/views/dashboard/Documents.vue';
import CreateNew from '@/views/create/CreateNew.vue';
import CreateOwn from '@/views/create/CreateOwn.vue';
import CreateUpload from '@/views/create/CreateUpload.vue';
import CreateContent from '@contract/views/CreateContent';
import CreateSign from '@/views/create/CreateSign.vue';
import CreateRejected from '@/views/create/CreateRejected.vue';

// TODO - Templates module with components and pages
import Templates from '@/views/dashboard/Templates.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Dashboard,
  },
  {
    path: '/dashboard/workspace/:workspace_id',
    name: 'dashboard',
    component: Dashboard,
  },
  {
    path: '/dashboard/workspace/:workspace_id/documents',
    name: 'documentsTable',
    component: Documents,
  },
  {
    path: '/dashboard/workspace/:workspace_id/reports',
    name: 'reports',
    component: Reports,
  },
  {
    path: '/:workspace_id/create/new',
    name: 'createNew',
    component: CreateNew,
  },
  {
    path: '/:workspace_id/contract/:contract_id/add/attachment',
    name: 'createNewAttachment',
    component: CreateNew,
  },
  {
    path: '/:workspace_id/create/:contract_id/own',
    name: 'createOwn',
    component: CreateOwn,
  },
  {
    path: '/:workspace_id/create/:contract_id/upload',
    name: 'createUpload',
    component: CreateUpload,
  },
  {
    path: '/:workspace_id/create/:contract_id/content',
    name: 'createContent',
    component: CreateContent,
  },
  {
    path: '/:workspace_id/create/:contract_id/sign',
    name: 'createSign',
    component: CreateSign,
  },
  {
    path: '/:workspace_id/create/rejected',
    name: 'createRejected',
    component: CreateRejected,
  },
  {
    path: '/overview/workspace/:workspace_id/contracts/active',
    name: 'overviewContractsActive',
    redirect: (to) => ({
      name: 'documentsTable',
      params: {
        workspace_id: to.params.workspace_id,
      },
      query: {
        state: 'draft',
      },
    }),
  },
  {
    path: '/:workspace_id/templates',
    name: 'template',
    component: Templates,
  },
];

export default routes;
