<template>
  <v-alert
    class="mx-auto px-3 py-1 pt-2"
    color="primary"
    :style="`border: 1px solid #ffd6d6`"
    max-width="845px"
    width="100%"
    dense
    dark
  >
    <template v-if="!isSent && !isLoading" v-slot:prepend>
      <v-icon class="pr-2 py-1 align-self-start">mdi-alert-outline</v-icon>
    </template>

    <v-row v-if="isLoading" align="center" justify="center" class="py-4">
      <v-progress-circular indeterminate color="white"></v-progress-circular>
    </v-row>

    <template v-else>
      <v-expand-transition>
        <v-row v-if="isSent" align="center" justify="center" class="py-4">
          <span class="subtitle-2 notification-message font-weight-medium">
            {{ this.$t('archive.ordered') }}
          </span>
        </v-row>
      </v-expand-transition>

      <v-expand-transition>
        <v-row
          v-if="!isSent"
          align="center"
          justify="start"
          :class="{ 'pb-2': !canSendOrder }"
          no-gutters
        >
          <v-col cols="12">
            <div class="subtitle-1 notification-title font-weight-bold">
              {{ $t('workspace.archive.disabled') }}
            </div>
          </v-col>

          <v-col cols="12">
            <span class="subtitle-2 notification-message font-weight-medium">
              <span v-html="archiveMessageCount"></span>
            </span>
          </v-col>

          <v-col cols="12">
            <v-row align="center" justify="end" class="py-4">
              <v-col v-if="canSendOrder" class="col-12 col-md-9">
                <v-row align="center" justify="end">
                  <v-col cols="auto">
                    <span class="d-block font-weight-medium">{{
                      $t('general.number_of_years')
                    }}</span>
                  </v-col>

                  <v-col class="col-6 col-md-auto">
                    <v-select
                      v-model="yearsOfArchiving"
                      :items="yearsOffer"
                      color="primary"
                      light
                      solo
                      dense
                      hide-details
                    ></v-select>
                  </v-col>

                  <v-col cols="auto">
                    <v-btn color="white" :loading="isSubmitted" dense outlined @click="sendRequest">
                      {{ $t('archive.order') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-expand-transition>
    </template>
  </v-alert>
</template>

<script>
import { mapGetters } from 'vuex';
import { internalApiRequest } from '@/shared/services/apiService';

export default {
  name: 'ArchiveNotification',
  props: {
    documentsInTrashCount: {
      type: [Number, String],
      default: 0,
    },
    workspace: {
      type: Object,
    },
  },
  data() {
    return {
      yearsOfArchiving: 5,
      yearsOffer: [5, 10, 20, 30],
      sending: false,
      sent: false,
      loaded: false,
    };
  },
  computed: {
    ...mapGetters({
      workspaceById: 'workspaceById',
    }),
    workspaceId() {
      return this.$route.params.workspace_id;
    },
    archiveMessageCount() {
      const message = this.$t('workspace.archive.disabled_message', {
        count: this.documentsInTrashCount,
      });
      const textToReplace = message?.split(' ')?.slice(0, 2)?.join(' ');

      return message?.replace(
        `${textToReplace}`,
        `<a href="${
          this.$router.resolve({
            name: 'documentsTable',
            query: {
              state: 'archive.trashed',
            },
          })?.href
        }">${textToReplace}</a>`,
      );
    },
    isSubmitted() {
      return this.sending;
    },
    isSent() {
      return this.sent;
    },
    isLoading() {
      return !this.loaded;
    },
    canSendOrder() {
      return this.workspaceById(this.workspaceId)?.is_owner || false;
    },
  },
  mounted() {
    internalApiRequest({
      method: 'GET',
      path: `api/v2/workspace/${this.workspaceId}/archive/order`,
    })
      .then(() => {
        this.sent = true;
      })
      .catch(() => {
        this.sent = false;
      })
      .finally(() => {
        this.loaded = true;
      });
  },
  methods: {
    sendRequest() {
      internalApiRequest({
        method: 'POST',
        path: `api/v2/workspace/${this.workspaceId}/archive/order`,
        data: {
          years: this.yearsOfArchiving,
        },
      })
        .then(() => {
          this.sent = true;
          this.$notification.success(this.$t('general.sent'));
        })
        .catch(() => {
          this.$notification.error(this.$t('general.error'));
        })
        .finally(() => {
          setTimeout(() => {
            this.sending = false;
          }, 800);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-title {
  font-size: 15px !important;
  line-height: 1.2rem !important;
  padding-top: 0.2rem;
}

:deep(.notification-message) {
  font-size: 14px !important;

  a {
    text-decoration: underline;
    font-size: inherit;
    font-weight: inherit;
    color: inherit !important;
  }

  a:hover {
    color: black !important;
  }
}
</style>
