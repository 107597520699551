import { apiAuthorizedRequest } from "@/services/ApiService";
import { authHeader } from '@/config/authHeader';
import { automaticLogout } from '@/middlewares/autoLogout';
import { hostname } from '@/services/helpers/variables.js';
import router from '@/router'

export const AccountService = {
  getSignatureSignIdentity,
  usePromocode,
  saveGD,
  dropboxFileinfo,
  googlediskFileinfo,
  googleUploadFiles,
  checkUploadFileState,
};

function googlediskFileinfo( data ) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify( data ),
  };

  return fetch(`${hostname}/api/v1/googledrive/fileinfo`, requestOptions)
    .then(handleResponse);
}

function dropboxFileinfo( data ) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify( data ),
  };

  return fetch(`${hostname}/api/v1/dropbox/fileinfo`, requestOptions)
    .then(handleResponse);
}

function saveGD( data, workspaceId ) {

  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify(data)
  };

  return fetch(`${hostname}/api/v1/googledrive/credentials/${workspaceId}`, requestOptions)
    .then(handleResponse);
}

function usePromocode(userId, promocodeId) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
  };
  return fetch(`${hostname}/api/v1/users/current/promocodes/${promocodeId}`, requestOptions)
    .then(handleResponse);
}

function getSignatureSignIdentity(id) {
  return apiAuthorizedRequest('GET', `api/v1/users/signature?signId=${id}`);
}

function googleUploadFiles( workspaceId ) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
  };

  return fetch(`${hostname}/api/v1/googledrive/uploadfiles/${workspaceId}`, requestOptions)
    .then(handleResponse);
}

function checkUploadFileState( workspaceId ) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader() },
  };

  return fetch(`${hostname}/api/v1/googledrive/uploadfiles/${workspaceId}`, requestOptions)
    .then(handleResponse);
}

function handleResponse(response) {
  return response.text().then(async (text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 404 && router.currentRoute.name !== 'subscription') {
        return router.push({name: 'notFound'});
      }

      await automaticLogout(response);

      return Promise.reject((data && data.message) || response.statusText);
    }

    return data;
  });
}
