<template>
  <VuePhoneNumberInput
    v-model="phoneNumber"
    class="s-phone-number-input"
    color="#5f23a0"
    valid-color="#4CAF50"
    error-color="#FF5252"
    :size="size"
    :default-country-code="defaultCountry"
    :preferred-countries="preferredCountries"
    :translations="translations"
    :autofocus="autofocus"
    :disabled="disabled"
    :error="hasError || error"
    :required="required"
    show-code-on-list
    @update="updatePhoneNumberData"
  />
</template>

<script>
import { i18n } from '@/plugins/i18n';
import { mapActions, mapGetters } from 'vuex';
import { availableLocales } from '@/plugins/i18n/Locales';
import VuePhoneNumberInput from '@digitalfactorycz/phone-number-input/dist/phone-number-input.common';
import '@digitalfactorycz/phone-number-input/dist/phone-number-input.css';

export default {
  name: 'SPhoneNumberInput',
  components: {
    VuePhoneNumberInput,
  },
  props: {
    value: String,
    autofocus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'lg',
    },
  },
  data() {
    return {
      phoneNumberCountryCode: '',
      phoneNumberData: null,
      preferredCountries: ['CZ', 'SK', 'HU'],
      countriesListBase: [
        {
          iso2: 'AF',
          dialCode: '93',
          areaCodes: null,
        },
        {
          iso2: 'AL',
          dialCode: '355',
          areaCodes: null,
        },
        {
          iso2: 'DZ',
          dialCode: '213',
          areaCodes: null,
        },
        {
          iso2: 'AS',
          dialCode: '1684',
          areaCodes: null,
        },
        {
          iso2: 'AD',
          dialCode: '376',
          areaCodes: null,
        },
        {
          iso2: 'AO',
          dialCode: '244',
          areaCodes: null,
        },
        {
          iso2: 'AI',
          dialCode: '1264',
          areaCodes: null,
        },
        {
          iso2: 'AG',
          dialCode: '1268',
          areaCodes: null,
        },
        {
          iso2: 'AR',
          dialCode: '54',
          areaCodes: null,
        },
        {
          iso2: 'AM',
          dialCode: '374',
          areaCodes: null,
        },
        {
          iso2: 'AW',
          dialCode: '297',
          areaCodes: null,
        },
        {
          iso2: 'AU',
          dialCode: '61',
          areaCodes: null,
        },
        {
          iso2: 'AT',
          dialCode: '43',
          areaCodes: null,
        },
        {
          iso2: 'AZ',
          dialCode: '994',
          areaCodes: null,
        },
        {
          iso2: 'BS',
          dialCode: '1242',
          areaCodes: null,
        },
        {
          iso2: 'BH',
          dialCode: '973',
          areaCodes: null,
        },
        {
          iso2: 'BD',
          dialCode: '880',
          areaCodes: null,
        },
        {
          iso2: 'BB',
          dialCode: '1246',
          areaCodes: null,
        },
        {
          iso2: 'BY',
          dialCode: '375',
          areaCodes: null,
        },
        {
          iso2: 'BE',
          dialCode: '32',
          areaCodes: null,
        },
        {
          iso2: 'BZ',
          dialCode: '501',
          areaCodes: null,
        },
        {
          iso2: 'BJ',
          dialCode: '229',
          areaCodes: null,
        },
        {
          iso2: 'BM',
          dialCode: '1441',
          areaCodes: null,
        },
        {
          iso2: 'BT',
          dialCode: '975',
          areaCodes: null,
        },
        {
          iso2: 'BO',
          dialCode: '591',
          areaCodes: null,
        },
        {
          iso2: 'BA',
          dialCode: '387',
          areaCodes: null,
        },
        {
          iso2: 'BW',
          dialCode: '267',
          areaCodes: null,
        },
        {
          iso2: 'BR',
          dialCode: '55',
          areaCodes: null,
        },
        {
          iso2: 'IO',
          dialCode: '246',
          areaCodes: null,
        },
        {
          iso2: 'VG',
          dialCode: '1284',
          areaCodes: null,
        },
        {
          iso2: 'BN',
          dialCode: '673',
          areaCodes: null,
        },
        {
          iso2: 'BG',
          dialCode: '359',
          areaCodes: null,
        },
        {
          iso2: 'BF',
          dialCode: '226',
          areaCodes: null,
        },
        {
          iso2: 'BI',
          dialCode: '257',
          areaCodes: null,
        },
        {
          iso2: 'KH',
          dialCode: '855',
          areaCodes: null,
        },
        {
          iso2: 'CM',
          dialCode: '237',
          areaCodes: null,
        },
        {
          iso2: 'CA',
          dialCode: '1',
          areaCodes: [
            '204',
            '226',
            '236',
            '249',
            '250',
            '289',
            '306',
            '343',
            '365',
            '387',
            '403',
            '416',
            '418',
            '431',
            '437',
            '438',
            '450',
            '506',
            '514',
            '519',
            '548',
            '579',
            '581',
            '587',
            '604',
            '613',
            '639',
            '647',
            '672',
            '705',
            '709',
            '742',
            '778',
            '780',
            '782',
            '807',
            '819',
            '825',
            '867',
            '873',
            '902',
            '905',
          ],
        },
        {
          iso2: 'CV',
          dialCode: '238',
          areaCodes: null,
        },
        {
          iso2: 'BQ',
          dialCode: '599',
          areaCodes: null,
        },
        {
          iso2: 'KY',
          dialCode: '1345',
          areaCodes: null,
        },
        {
          iso2: 'CF',
          dialCode: '236',
          areaCodes: null,
        },
        {
          iso2: 'TD',
          dialCode: '235',
          areaCodes: null,
        },
        {
          iso2: 'CL',
          dialCode: '56',
          areaCodes: null,
        },
        {
          iso2: 'CN',
          dialCode: '86',
          areaCodes: null,
        },
        {
          iso2: 'CX',
          dialCode: '61',
          areaCodes: null,
        },
        {
          iso2: 'CC',
          dialCode: '61',
          areaCodes: null,
        },
        {
          iso2: 'CO',
          dialCode: '57',
          areaCodes: null,
        },
        {
          iso2: 'KM',
          dialCode: '269',
          areaCodes: null,
        },
        {
          iso2: 'CD',
          dialCode: '243',
          areaCodes: null,
        },
        {
          iso2: 'CG',
          dialCode: '242',
          areaCodes: null,
        },
        {
          iso2: 'CK',
          dialCode: '682',
          areaCodes: null,
        },
        {
          iso2: 'CR',
          dialCode: '506',
          areaCodes: null,
        },
        {
          iso2: 'CI',
          dialCode: '225',
          areaCodes: null,
        },
        {
          iso2: 'HR',
          dialCode: '385',
          areaCodes: null,
        },
        {
          iso2: 'CU',
          dialCode: '53',
          areaCodes: null,
        },
        {
          iso2: 'CW',
          dialCode: '599',
          areaCodes: null,
        },
        {
          iso2: 'CY',
          dialCode: '357',
          areaCodes: null,
        },
        {
          iso2: 'CZ',
          dialCode: '420',
          areaCodes: null,
        },
        {
          iso2: 'DK',
          dialCode: '45',
          areaCodes: null,
        },
        {
          iso2: 'DJ',
          dialCode: '253',
          areaCodes: null,
        },
        {
          iso2: 'DM',
          dialCode: '1767',
          areaCodes: null,
        },
        {
          iso2: 'DO',
          dialCode: '1',
          areaCodes: ['809', '829', '849'],
        },
        {
          iso2: 'EC',
          dialCode: '593',
          areaCodes: null,
        },
        {
          iso2: 'EG',
          dialCode: '20',
          areaCodes: null,
        },
        {
          iso2: 'SV',
          dialCode: '503',
          areaCodes: null,
        },
        {
          iso2: 'GQ',
          dialCode: '240',
          areaCodes: null,
        },
        {
          iso2: 'ER',
          dialCode: '291',
          areaCodes: null,
        },
        {
          iso2: 'EE',
          dialCode: '372',
          areaCodes: null,
        },
        {
          iso2: 'ET',
          dialCode: '251',
          areaCodes: null,
        },
        {
          iso2: 'FK',
          dialCode: '500',
          areaCodes: null,
        },
        {
          iso2: 'FO',
          dialCode: '298',
          areaCodes: null,
        },
        {
          iso2: 'FJ',
          dialCode: '679',
          areaCodes: null,
        },
        {
          iso2: 'FI',
          dialCode: '358',
          areaCodes: null,
        },
        {
          iso2: 'FR',
          dialCode: '33',
          areaCodes: null,
        },
        {
          iso2: 'GF',
          dialCode: '594',
          areaCodes: null,
        },
        {
          iso2: 'PF',
          dialCode: '689',
          areaCodes: null,
        },
        {
          iso2: 'GA',
          dialCode: '241',
          areaCodes: null,
        },
        {
          iso2: 'GM',
          dialCode: '220',
          areaCodes: null,
        },
        {
          iso2: 'GE',
          dialCode: '995',
          areaCodes: null,
        },
        {
          iso2: 'DE',
          dialCode: '49',
          areaCodes: null,
        },
        {
          iso2: 'GH',
          dialCode: '233',
          areaCodes: null,
        },
        {
          iso2: 'GI',
          dialCode: '350',
          areaCodes: null,
        },
        {
          iso2: 'GR',
          dialCode: '30',
          areaCodes: null,
        },
        {
          iso2: 'GL',
          dialCode: '299',
          areaCodes: null,
        },
        {
          iso2: 'GD',
          dialCode: '1473',
          areaCodes: null,
        },
        {
          iso2: 'GP',
          dialCode: '590',
          areaCodes: null,
        },
        {
          iso2: 'GU',
          dialCode: '1671',
          areaCodes: null,
        },
        {
          iso2: 'GT',
          dialCode: '502',
          areaCodes: null,
        },
        {
          iso2: 'GG',
          dialCode: '44',
          areaCodes: null,
        },
        {
          iso2: 'GN',
          dialCode: '224',
          areaCodes: null,
        },
        {
          iso2: 'GW',
          dialCode: '245',
          areaCodes: null,
        },
        {
          iso2: 'GY',
          dialCode: '592',
          areaCodes: null,
        },
        {
          iso2: 'HT',
          dialCode: '509',
          areaCodes: null,
        },
        {
          iso2: 'HN',
          dialCode: '504',
          areaCodes: null,
        },
        {
          iso2: 'HK',
          dialCode: '852',
          areaCodes: null,
        },
        {
          iso2: 'HU',
          dialCode: '36',
          areaCodes: null,
        },
        {
          iso2: 'IS',
          dialCode: '354',
          areaCodes: null,
        },
        {
          iso2: 'IN',
          dialCode: '91',
          areaCodes: null,
        },
        {
          iso2: 'ID',
          dialCode: '62',
          areaCodes: null,
        },
        {
          iso2: 'IR',
          dialCode: '98',
          areaCodes: null,
        },
        {
          iso2: 'IQ',
          dialCode: '964',
          areaCodes: null,
        },
        {
          iso2: 'IE',
          dialCode: '353',
          areaCodes: null,
        },
        {
          iso2: 'IM',
          dialCode: '44',
          areaCodes: null,
        },
        {
          iso2: 'IL',
          dialCode: '972',
          areaCodes: null,
        },
        {
          iso2: 'IT',
          dialCode: '39',
          areaCodes: null,
        },
        {
          iso2: 'JM',
          dialCode: '1876',
          areaCodes: null,
        },
        {
          iso2: 'JP',
          dialCode: '81',
          areaCodes: null,
        },
        {
          iso2: 'JE',
          dialCode: '44',
          areaCodes: null,
        },
        {
          iso2: 'JO',
          dialCode: '962',
          areaCodes: null,
        },
        {
          iso2: 'KZ',
          dialCode: '7',
          areaCodes: null,
        },
        {
          iso2: 'KE',
          dialCode: '254',
          areaCodes: null,
        },
        {
          iso2: 'KI',
          dialCode: '686',
          areaCodes: null,
        },
        {
          iso2: 'XK',
          dialCode: '383',
          areaCodes: null,
        },
        {
          iso2: 'KW',
          dialCode: '965',
          areaCodes: null,
        },
        {
          iso2: 'KG',
          dialCode: '996',
          areaCodes: null,
        },
        {
          iso2: 'LA',
          dialCode: '856',
          areaCodes: null,
        },
        {
          iso2: 'LV',
          dialCode: '371',
          areaCodes: null,
        },
        {
          iso2: 'LB',
          dialCode: '961',
          areaCodes: null,
        },
        {
          iso2: 'LS',
          dialCode: '266',
          areaCodes: null,
        },
        {
          iso2: 'LR',
          dialCode: '231',
          areaCodes: null,
        },
        {
          iso2: 'LY',
          dialCode: '218',
          areaCodes: null,
        },
        {
          iso2: 'LI',
          dialCode: '423',
          areaCodes: null,
        },
        {
          iso2: 'LT',
          dialCode: '370',
          areaCodes: null,
        },
        {
          iso2: 'LU',
          dialCode: '352',
          areaCodes: null,
        },
        {
          iso2: 'MO',
          dialCode: '853',
          areaCodes: null,
        },
        {
          iso2: 'MK',
          dialCode: '389',
          areaCodes: null,
        },
        {
          iso2: 'MG',
          dialCode: '261',
          areaCodes: null,
        },
        {
          iso2: 'MW',
          dialCode: '265',
          areaCodes: null,
        },
        {
          iso2: 'MY',
          dialCode: '60',
          areaCodes: null,
        },
        {
          iso2: 'MV',
          dialCode: '960',
          areaCodes: null,
        },
        {
          iso2: 'ML',
          dialCode: '223',
          areaCodes: null,
        },
        {
          iso2: 'MT',
          dialCode: '356',
          areaCodes: null,
        },
        {
          iso2: 'MH',
          dialCode: '692',
          areaCodes: null,
        },
        {
          iso2: 'MQ',
          dialCode: '596',
          areaCodes: null,
        },
        {
          iso2: 'MR',
          dialCode: '222',
          areaCodes: null,
        },
        {
          iso2: 'MU',
          dialCode: '230',
          areaCodes: null,
        },
        {
          iso2: 'YT',
          dialCode: '262',
          areaCodes: null,
        },
        {
          iso2: 'MX',
          dialCode: '52',
          areaCodes: null,
        },
        {
          iso2: 'FM',
          dialCode: '691',
          areaCodes: null,
        },
        {
          iso2: 'MD',
          dialCode: '373',
          areaCodes: null,
        },
        {
          iso2: 'MC',
          dialCode: '377',
          areaCodes: null,
        },
        {
          iso2: 'MN',
          dialCode: '976',
          areaCodes: null,
        },
        {
          iso2: 'ME',
          dialCode: '382',
          areaCodes: null,
        },
        {
          iso2: 'MS',
          dialCode: '1664',
          areaCodes: null,
        },
        {
          iso2: 'MA',
          dialCode: '212',
          areaCodes: null,
        },
        {
          iso2: 'MZ',
          dialCode: '258',
          areaCodes: null,
        },
        {
          iso2: 'MM',
          dialCode: '95',
          areaCodes: null,
        },
        {
          iso2: 'NA',
          dialCode: '264',
          areaCodes: null,
        },
        {
          iso2: 'NR',
          dialCode: '674',
          areaCodes: null,
        },
        {
          iso2: 'NP',
          dialCode: '977',
          areaCodes: null,
        },
        {
          iso2: 'NL',
          dialCode: '31',
          areaCodes: null,
        },
        {
          iso2: 'NC',
          dialCode: '687',
          areaCodes: null,
        },
        {
          iso2: 'NZ',
          dialCode: '64',
          areaCodes: null,
        },
        {
          iso2: 'NI',
          dialCode: '505',
          areaCodes: null,
        },
        {
          iso2: 'NE',
          dialCode: '227',
          areaCodes: null,
        },
        {
          iso2: 'NG',
          dialCode: '234',
          areaCodes: null,
        },
        {
          iso2: 'NU',
          dialCode: '683',
          areaCodes: null,
        },
        {
          iso2: 'NF',
          dialCode: '672',
          areaCodes: null,
        },
        {
          iso2: 'KP',
          dialCode: '850',
          areaCodes: null,
        },
        {
          iso2: 'MP',
          dialCode: '1670',
          areaCodes: null,
        },
        {
          iso2: 'NO',
          dialCode: '47',
          areaCodes: null,
        },
        {
          iso2: 'OM',
          dialCode: '968',
          areaCodes: null,
        },
        {
          iso2: 'PK',
          dialCode: '92',
          areaCodes: null,
        },
        {
          iso2: 'PW',
          dialCode: '680',
          areaCodes: null,
        },
        {
          iso2: 'PS',
          dialCode: '970',
          areaCodes: null,
        },
        {
          iso2: 'PA',
          dialCode: '507',
          areaCodes: null,
        },
        {
          iso2: 'PG',
          dialCode: '675',
          areaCodes: null,
        },
        {
          iso2: 'PY',
          dialCode: '595',
          areaCodes: null,
        },
        {
          iso2: 'PE',
          dialCode: '51',
          areaCodes: null,
        },
        {
          iso2: 'PH',
          dialCode: '63',
          areaCodes: null,
        },
        {
          iso2: 'PL',
          dialCode: '48',
          areaCodes: null,
        },
        {
          iso2: 'PT',
          dialCode: '351',
          areaCodes: null,
        },
        {
          iso2: 'PR',
          dialCode: '1',
          areaCodes: ['787', '939'],
        },
        {
          iso2: 'QA',
          dialCode: '974',
          areaCodes: null,
        },
        {
          iso2: 'RE',
          dialCode: '262',
          areaCodes: null,
        },
        {
          iso2: 'RO',
          dialCode: '40',
          areaCodes: null,
        },
        {
          iso2: 'RU',
          dialCode: '7',
          areaCodes: null,
        },
        {
          iso2: 'RW',
          dialCode: '250',
          areaCodes: null,
        },
        {
          iso2: 'BL',
          dialCode: '590',
          areaCodes: null,
        },
        {
          iso2: 'SH',
          dialCode: '290',
          areaCodes: null,
        },
        {
          iso2: 'KN',
          dialCode: '1869',
          areaCodes: null,
        },
        {
          iso2: 'LC',
          dialCode: '1758',
          areaCodes: null,
        },
        {
          iso2: 'MF',
          dialCode: '590',
          areaCodes: null,
        },
        {
          iso2: 'PM',
          dialCode: '508',
          areaCodes: null,
        },
        {
          iso2: 'VC',
          dialCode: '1784',
          areaCodes: null,
        },
        {
          iso2: 'WS',
          dialCode: '685',
          areaCodes: null,
        },
        {
          iso2: 'SM',
          dialCode: '378',
          areaCodes: null,
        },
        {
          iso2: 'ST',
          dialCode: '239',
          areaCodes: null,
        },
        {
          iso2: 'SA',
          dialCode: '966',
          areaCodes: null,
        },
        {
          iso2: 'SN',
          dialCode: '221',
          areaCodes: null,
        },
        {
          iso2: 'RS',
          dialCode: '381',
          areaCodes: null,
        },
        {
          iso2: 'SC',
          dialCode: '248',
          areaCodes: null,
        },
        {
          iso2: 'SL',
          dialCode: '232',
          areaCodes: null,
        },
        {
          iso2: 'SG',
          dialCode: '65',
          areaCodes: null,
        },
        {
          iso2: 'SX',
          dialCode: '1721',
          areaCodes: null,
        },
        {
          iso2: 'SK',
          dialCode: '421',
          areaCodes: null,
        },
        {
          iso2: 'SI',
          dialCode: '386',
          areaCodes: null,
        },
        {
          iso2: 'SB',
          dialCode: '677',
          areaCodes: null,
        },
        {
          iso2: 'SO',
          dialCode: '252',
          areaCodes: null,
        },
        {
          iso2: 'ZA',
          dialCode: '27',
          areaCodes: null,
        },
        {
          iso2: 'KR',
          dialCode: '82',
          areaCodes: null,
        },
        {
          iso2: 'SS',
          dialCode: '211',
          areaCodes: null,
        },
        {
          iso2: 'ES',
          dialCode: '34',
          areaCodes: null,
        },
        {
          iso2: 'LK',
          dialCode: '94',
          areaCodes: null,
        },
        {
          iso2: 'SD',
          dialCode: '249',
          areaCodes: null,
        },
        {
          iso2: 'SR',
          dialCode: '597',
          areaCodes: null,
        },
        {
          iso2: 'SJ',
          dialCode: '47',
          areaCodes: null,
        },
        {
          iso2: 'SZ',
          dialCode: '268',
          areaCodes: null,
        },
        {
          iso2: 'SE',
          dialCode: '46',
          areaCodes: null,
        },
        {
          iso2: 'CH',
          dialCode: '41',
          areaCodes: null,
        },
        {
          iso2: 'SY',
          dialCode: '963',
          areaCodes: null,
        },
        {
          iso2: 'TW',
          dialCode: '886',
          areaCodes: null,
        },
        {
          iso2: 'TJ',
          dialCode: '992',
          areaCodes: null,
        },
        {
          iso2: 'TZ',
          dialCode: '255',
          areaCodes: null,
        },
        {
          iso2: 'TH',
          dialCode: '66',
          areaCodes: null,
        },
        {
          iso2: 'TL',
          dialCode: '670',
          areaCodes: null,
        },
        {
          iso2: 'TG',
          dialCode: '228',
          areaCodes: null,
        },
        {
          iso2: 'TK',
          dialCode: '690',
          areaCodes: null,
        },
        {
          iso2: 'TO',
          dialCode: '676',
          areaCodes: null,
        },
        {
          iso2: 'TT',
          dialCode: '1868',
          areaCodes: null,
        },
        {
          iso2: 'TN',
          dialCode: '216',
          areaCodes: null,
        },
        {
          iso2: 'TR',
          dialCode: '90',
          areaCodes: null,
        },
        {
          iso2: 'TM',
          dialCode: '993',
          areaCodes: null,
        },
        {
          iso2: 'TC',
          dialCode: '1649',
          areaCodes: null,
        },
        {
          iso2: 'TV',
          dialCode: '688',
          areaCodes: null,
        },
        {
          iso2: 'VI',
          dialCode: '1340',
          areaCodes: null,
        },
        {
          iso2: 'UG',
          dialCode: '256',
          areaCodes: null,
        },
        {
          iso2: 'UA',
          dialCode: '380',
          areaCodes: null,
        },
        {
          iso2: 'AE',
          dialCode: '971',
          areaCodes: null,
        },
        {
          iso2: 'GB',
          dialCode: '44',
          areaCodes: null,
        },
        {
          iso2: 'US',
          dialCode: '1',
          areaCodes: null,
        },
        {
          iso2: 'UY',
          dialCode: '598',
          areaCodes: null,
        },
        {
          iso2: 'UZ',
          dialCode: '998',
          areaCodes: null,
        },
        {
          iso2: 'VU',
          dialCode: '678',
          areaCodes: null,
        },
        {
          iso2: 'VA',
          dialCode: '39',
          areaCodes: null,
        },
        {
          iso2: 'VE',
          dialCode: '58',
          areaCodes: null,
        },
        {
          iso2: 'VN',
          dialCode: '84',
          areaCodes: null,
        },
        {
          iso2: 'WF',
          dialCode: '681',
          areaCodes: null,
        },
        {
          iso2: 'EH',
          dialCode: '212',
          areaCodes: null,
        },
        {
          iso2: 'YE',
          dialCode: '967',
          areaCodes: null,
        },
        {
          iso2: 'ZM',
          dialCode: '260',
          areaCodes: null,
        },
        {
          iso2: 'ZW',
          dialCode: '263',
          areaCodes: null,
        },
        {
          iso2: 'AX',
          dialCode: '358',
          areaCodes: null,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      countriesList: 'countries/list',
      profile: 'profile',
      workspaceById: 'workspaceById',
      contract: 'contract',
    }),
    phoneNumber: {
      get() {
        return (
          this.phoneNumberData?.formatInternational?.replace(
            `+${this.phoneNumberData?.countryCallingCode}`,
            '',
          ) || this.value
        );
      },
      set(value) {
        this.phoneNumberCountryCode = this.phoneNumberData?.countryCode;
        this.$emit('input', this.phoneNumberData?.formattedNumber || value);
      },
    },
    defaultCountry() {
      const re =
        /^(?:(en-GB-oed|i-ami|i-bnn|i-default|i-enochian|i-hak|i-klingon|i-lux|i-mingo|i-navajo|i-pwn|i-tao|i-tay|i-tsu|sgn-BE-FR|sgn-BE-NL|sgn-CH-DE)|(art-lojban|cel-gaulish|no-bok|no-nyn|zh-guoyu|zh-hakka|zh-min|zh-min-nan|zh-xiang))$|^((?:[a-z]{2,3}(?:(?:-[a-z]{3}){1,3})?)|[a-z]{4}|[a-z]{5,8})(?:-([a-z]{4}))?(?:-([a-z]{2}|\d{3}))?((?:-(?:[\da-z]{5,8}|\d[\da-z]{3}))*)?((?:-[\da-wy-z](?:-[\da-z]{2,8})+)*)?(-x(?:-[\da-z]{1,8})+)?$|^(x(?:-[\da-z]{1,8})+)$/i;
      const browserCountry = re.exec(navigator?.languages[0]);

      return (
        this.phoneNumberCountryCode ||
        this.contractCountry ||
        this.invoiceCountry?.alpha2 ||
        this.workspaceCountry ||
        browserCountry[5]?.toUpperCase() ||
        'CZ'
      ).replace('CS', 'CZ');
    },
    currentWorkspace() {
      return this.workspaceById(this.$route?.params?.workspace_id);
    },
    contractCountry() {
      return availableLocales
        ?.find((locale) => this.contract?.locale === locale.locale)
        ?.code?.split('-')[1];
    },
    workspaceCountry() {
      return availableLocales
        ?.find((locale) => this.currentWorkspace?.locale === locale.locale)
        ?.code?.split('-')[1];
    },
    invoiceCountry() {
      return (
        this.countriesList?.find((country) => {
          return country?.alpha3 === this.profile?.invoice_info?.invoice_country;
        }) || []
      );
    },
    translations() {
      return {
        countrySelectorLabel: i18n.t('localization.country_code'),
        countrySelectorError: i18n.t('localization.select_country'),
        phoneNumberLabel: i18n.t('general.phone_number'),
        example: `${i18n.t('general.example')}: `,
      };
    },
    hasError() {
      return this.phoneNumber && !this.phoneNumberData?.isValid;
    },
  },
  watch: {
    'phoneNumberData.isValid': {
      handler: function (value) {
        this.$emit('on-validity-change', value);
      },
    },
  },
  created() {
    this.fetchCountriesList();
  },
  async mounted() {
    this.phoneNumberCountryCode = await this.findCountryCode();
  },
  methods: {
    ...mapActions({
      fetchCountriesList: 'countries/fetchCountriesList',
    }),
    updatePhoneNumberData(payload) {
      this.phoneNumberData = payload;
      this.$emit('on-change', payload.isValid);
    },
    findCountryCode(value) {
      const self = this;
      return new Promise((resolve) => {
        if (!self.value?.startsWith('+') || '' === self.value) {
          resolve('');
        }

        let result = null;
        let basePhoneNumber = (value || self.phoneNumber).replace('+', '');

        while (null === result && basePhoneNumber !== '') {
          for (let country of self.countriesListBase) {
            if (country.dialCode === basePhoneNumber) {
              result = country.iso2;
              basePhoneNumber = '';
              resolve(result);
            }
          }

          basePhoneNumber = basePhoneNumber.slice(0, -1);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.s-phone-number-input) {
  .country-selector__input {
    font-size: 16px !important;
    height: 56px !important;
    border-color: rgb(143, 143, 143, 1) !important;
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }

  .input-tel__input {
    font-size: 16px !important;
    height: 56px !important;
    border-color: rgb(143, 143, 143, 1) !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
}
</style>
