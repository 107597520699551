import { ContractHelper } from '@/helpers/ContractHelper.js';
import { isRoutePublicPage } from '@/services/helpers';
import router from '@/router';
import FeatureFlags from '@/common/reusable/featureFlagsChecker';

function clickOnSignLink({ contract, fastsign, routes, markApproved }) {
  processClick({
    contract,
    routeParams: routes.params,
    routeQuery: routes.query,
    action: (changeRoute) => {
      if (fastsign && FeatureFlags.isFastsignEnabled(contract)) {
        fastsign(FeatureFlags.preloadedSignature(contract));
      } else {
        changeRoute('startSign');
      }
    },
  });
}

function clickOnApproveLink({ contract, routes, markApproved }) {
  processClick({
    contract,
    routeParams: routes.params,
    action: markApproved,
  });
}

function redirectAfterVerifiedSmsPin({ contract, routeParams, routeQuery, detailQuery, autoapprove, redirectToCompleted }) {
  const redirectToDetail = (contractId) => {
    const changeRoute = builderRouter(
      contract,
      {
        ...routeParams,
        contract_id: contractId,
      },
      {
        ...detailQuery,
        contract_id: contractId,
      }
    );
    changeRoute('detail');
  };
  if (contract.current_user.can_sign_by_image) {
    const changeRoute = builderRouter(contract, routeParams, routeQuery);
    return changeRoute('startSign');
  } else if (autoapprove && (contract.current_user.can_accept || contract.current_user.can_stamp)) {
    return autoapprove().then(
      (approvedContract) => {
        const unsignedAttachment = getNextUnsignedAfterApprove(approvedContract);
        if (unsignedAttachment) {
          redirectToDetail(unsignedAttachment.id)
        } else {
          redirectToCompleted();
        }
      },
      () => {
        redirectToDetail(contract.id);
      },
    );
  }
  return redirectToDetail(contract.id);
}

function processClick({ contract, routeParams, routeQuery, action }) {
  const changeRoute = builderRouter(contract, routeParams, routeQuery);
  if (!contract) {
    return;
  } else if (contract.current_user.is_identification_missing) {
    changeRoute('startVerify');
  } else if (contract.current_user.is_smspin_missing) {
    if (isMobileReadyForPIN(contract)) {
      changeRoute('submitSmsPin', { pin: true });
    } else {
      changeRoute('startSmsPin');
    }
  } else {
    action(changeRoute);
  }
}

function builderRouter(contract, routeParams, defaultQueryString) {
  return changeRoute;

  function changeRoute(route, liveQueryString) {
    const routes = getRoutes();
    const queryString = defaultQueryString || liveQueryString ? { ...defaultQueryString, ...liveQueryString } : null;
    router.push({
      name: routes[route],
      params: routeParams,
      query: queryString,
    });
  }

  function getRoutes() {
    if (isRoutePublicPage(router.currentRoute.name)) {
      return  {
        startSmsPin: 'contract2FAStart',
        submitSmsPin: 'contract2FASubmit',
        startSign: 'contractSign',
        detail: 'contractView',
      };
    } else {
      return  {
        startSmsPin: 'contractDocumentSign2FAStart',
        submitSmsPin: 'contractDocumentSign2FASubmit',
        startSign: 'createSign',
        detail: contract.one_device ? 'documentsDetailCounterparty' : 'dataContractDetail',
      };
    }
  }
}

function clickOnLinkInDraftContract(detailPage) {
  if (detailPage.contract.current_user.must_send_from_wizard) {
    detailPage.$router.push({
      name: 'createContent',
      params: {
        workspace_id: detailPage.$route.params.workspace_id,
        contract_id: detailPage.$route.params.contract_id,
      },
    });
  } else {
    detailPage.$notification.error(detailPage.$t('contract.errors.draft'));
  }
}

function isMobileReadyForPIN(contract) {
  // cannot use getCurrentSignIdentity, because it's called for one-device contract in ContractDocumentSign2FASubmit
  const currentSignIdentity = ContractHelper.getLegacyCurrentSignIdentity(contract);

  return currentSignIdentity?.mobile
}

// TODO: workflow - unify ApiInvite and ApiContract would be nice, because default base contract for unregistered users is error-prone (especially for attachments and different contract roles).
function getSelectedContractForUnregistredUser(contract, selectedContractId) {
  if (selectedContractId !== contract.id) {
    for (let index in contract.attachments) {
      const attachment = contract.attachments[index];
      if (parseInt(attachment.id, 10) === parseInt(selectedContractId, 10)) {
        return attachment;
      }
    }
  }
  return contract;
}

// TODO: workflow - refactoring duplicated code
// - more duplication in components that are calling this method, it should be similar to clickOnSignLink
// - processAcceptedOrFastsignedContract in details and similar checkUnsignedDocuments in 2FA (hotfix for one-device without attachments when getNextUnsigned returns null)
function getNextUnsignedAfterApprove(contract) {
  return ContractHelper.getNextUnsigned(contract);
}

// - setContractViewed is tricky, because contract is still unsigned/unapproved, but contract is temporarily skipped
//   (it is not necessary in CreateContent, because it checks draft state, but that's impossible in contract detail)
function getNextUnviewed(contract) {
  return ContractHelper.getNextUnviewed(contract);
}

function redirectIfContractIsNotEditableInWizard(contract) {
  // TODO: workflow - not sure, if non-draft contract should be available without redirects
  // - redirect origin: https://gitlab.com/digitalfactorycz/ismlouva-frontend/-/commit/dd34e439
  // - redirect causes troubles for contract with attachments
  //   1. create contract with attachment
  //   2. send 1st without signature -> redirected to 2nd
  //   3. go back to 1st -> redirect to detail page, but it complicates flow (detail is one option, he probably wants to go back to next unsigned contract)
  // But no redirect = every button would have to be disabled (or replaced with dummy call), redirect is KISS
  if (contract.state !== 'draft') {
    return router.push({
      name: 'documentsDetail',
      params: {
        workspace_id: contract.workspace_id,
        contract_id: contract.id
      }
    });
  }
  return false;
}

export const WorkflowHelper = {
  clickOnSignLink,
  clickOnApproveLink,
  clickOnLinkInDraftContract,
  redirectAfterVerifiedSmsPin,
  isMobileReadyForPIN,
  getSelectedContractForUnregistredUser,
  getNextUnsignedAfterApprove,
  getNextUnviewed,
  redirectIfContractIsNotEditableInWizard,
};
