import { environment } from '@/config/environment';

export const hasFeatureFlag = (entity, flag = '') => {
  if (entity?.feature_flags === undefined) {
    return false;
  }

  if ('' === flag) {
    return 0 !== Object.keys(entity.feature_flags).length;
  }

  if (Array.isArray(entity.feature_flags)) {
    return entity.feature_flags.includes(flag) || false;
  }

  return entity.feature_flags[flag] || false;
};

export default {
  cantChangeSignatureDate: (contract) => {
    return hasFeatureFlag(contract, 'cantChangeSignatureDate');
  },
  isUnilateralContract: (contract) => {
    return hasFeatureFlag(contract, 'isUnilateralContract');
  },
  hasSequentialSigningOrder: (contract) => {
    return hasFeatureFlag(contract, 'hasSequentialSigningOrder');
  },
  isSigningContractOnlyOnce: (contract) => {
    return hasFeatureFlag(contract, 'isSigningContractOnlyOnce');
  },
  preloadedSignature: (contract) => {
    return hasFeatureFlag(contract, 'preloadedSignature')
      ? contract.feature_flags.preloadedSignature
      : null;
  },
  isSignHeaderIgnored: (workspaceOrSignIdentity) => {
    return hasFeatureFlag(workspaceOrSignIdentity, 'isSignHeaderIgnored');
  },
  showSignDateAndPlaceDisabled: (workspace) => {
    return hasFeatureFlag(workspace, 'showSignDateAndPlaceDisabled');
  },
  isKatastrEnabled: (workspace) => {
    return hasFeatureFlag(workspace, 'isKatastrEnabled');
  },
  isKatastrEnabledForContract: (contract, workspaceById) => {
    // TODO - use store
    return (
      hasFeatureFlag(workspaceById(contract.workspace_id), 'isKatastrEnabled') &&
      'form' === contract.type
    );
  },
  isPrefillFromContactsEnabledForContract: (contract, workspaceById) => {
    // TODO - use store
    return hasFeatureFlag(workspaceById(contract.workspace_id), 'isPrefillableFromContacts');
  },
  isPrefillFromContactsEnabled: (contract) => {
    return hasFeatureFlag(contract, 'isPrefillableFromContacts');
  },
  isFastsignEnabled: (contract) => {
    return hasFeatureFlag(contract, 'isFastsignEnabled');
  },
  isEditingContactsTemplate: (contract) => {
    return hasFeatureFlag(contract, 'isEditingContactsTemplate');
  },
  isDelegationByCounterpartyDisabled: (contract) => {
    return hasFeatureFlag(contract, 'disableDelegationByCounterparty');
  },
  hasDisabledCreateWorkspace: ({ workspaces }) => {
    if ('undefined' === typeof workspaces) {
      return false;
    }

    const apiUrl = environment.getApiUrl();
    const workspaceIdsWithDisabledCreatingNewWorkspacePerResource = {
      'https://api.staging.signi.tech': ['44'],
      'https://api.test.signi.tech': ['2394'],
      'https://api.signi.com': [
        '8431',
        '8439',
        '16024',
        '16411',
        '16517',
        '18371',
        '18373',
        '18378',
        '18399',
        '18408',
        '18409',
        '18410',
        '18412',
        '18413',
        '18414',
        '46771',
      ],
    };
    const workspaceIdsWithDisabledCreatingNewWorkspace =
      workspaceIdsWithDisabledCreatingNewWorkspacePerResource[`${apiUrl}`] || [];

    return (
      workspaces?.filter((workspace) => {
        return (
          workspaceIdsWithDisabledCreatingNewWorkspace.includes(`${workspace.id}`) &&
          !workspace.is_owner
        );
      })?.length > 0 || false
    );
  },
  signiAuthenticationEnabled: (workspace) => {
    return hasFeatureFlag(workspace, 'signiAuthenticationEnabled') || false;
  },
  hasEnabledSigniAuthentication: (contract) => {
    return hasFeatureFlag(contract, 'hasEnabledSigniAuthentication') || false;
  },
  cantChangePhoneNumber: (contract) => {
    return hasFeatureFlag(contract, 'cantChangePhoneNumber');
  },
  oneDeviceEnabled: (contract) => {
    return hasFeatureFlag(contract, 'oneDeviceEnabled');
  },
  oneDeviceChecked: (entity) => {
    return hasFeatureFlag(entity, 'oneDeviceChecked');
  },
  enablePdfWorker: (workspace) => {
    return hasFeatureFlag(workspace, 'enablePdfWorker');
  },
  enableSignWithCertificate: (contract) => {
    return hasFeatureFlag(contract, 'enableSignWithCertificate');
  },
  enableSignWithBankId: (contract) => {
    return hasFeatureFlag(contract, 'enableSignWithBankIdSign');
  },
  enableVariableUsers: (contract) => {
    return hasFeatureFlag(contract, 'enableVariableUsers');
  },
  enableMovingContracts: (entity) => {
    return hasFeatureFlag(entity, 'enableMovingContracts');
  },
  redirectUserAfterSignEnabled: (contract) => {
    return hasFeatureFlag(contract, 'redirectUserAfterSignEnabled');
  },
  isDelegationByProposerDisabled: (contract) => {
    return hasFeatureFlag(contract, 'proposerSignatureDelegationDisabled');
  },
};
